import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import isEmpty from 'lodash/isEmpty';
import intersection from 'lodash/intersection';
import {
  DocumentContentTypeEnum,
  EnumTypeForList,
  IDocumentType,
  IMilestone,
  ITransloaditError,
  QueryNamesEnums,
  VisibilityEnum,
} from '@interfaces';
import {
  checkIsInvestor,
  checkIsLender,
  createTuple,
  getSortedByIndexMilestones,
  getTeamRole,
} from '@utils';
import { useCustomUppy, useSafeSnackbar } from '@hooks';
import { AuthContext, SettingsContext, useGetListData } from '@context';
import { ComponentProps, ControllerInterface } from './interface';

export const useUploaderWithForm = ({
  documentTypes,
  transloaditSignature: { params, signature },
  drawRequestId,
  milestoneId,
  projectId,
  inspectionId,
  restrictions,
  refetchCallback,
  refetch,
  closeUploader,
  showLineItems,
  documentTypeId,
  isProjectImage,
}: ComponentProps): ControllerInterface => {
  const { user } = useContext(AuthContext);
  const teamRole = getTeamRole(user);
  const { isPHBProject } = useContext(SettingsContext);
  const { enqueueSnackbar } = useSafeSnackbar();
  const queryClient = useQueryClient();
  const [currentFiles, setCurrentFiles] = useState([]);
  const [initialValues, setInitialValues] = useState({});
  const [showDragDrop, setShowDragDrop] = useState(true);
  const [validationSchema, setValidationSchema] = useState<Yup.AnyObjectSchema | undefined>(null);
  const [showDashboard, setShowDashboard] = useState(true);
  const [generalScope, setGeneralScope] = useState(true);
  const [generalLineItems, setGeneralLineItems] = useState([]);
  const [generalDocumentType, setGeneralDocumentType] = useState<string>();

  const drawRequestMilestones = useGetListData({
    type: QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES,
    keys: ['id', 'index', 'name', 'project_milestone'],
    args: { projectId, drawRequestId },
    options: {
      skip: !showLineItems || !projectId || !drawRequestId || isPHBProject,
    },
  });

  const projectMilestones = useGetListData({
    type: QueryNamesEnums.GET_PROJECT_MILESTONES,
    keys: ['id', 'index', 'name'],
    args: { projectId },
    options: {
      skip: !showLineItems || !projectId || Boolean(drawRequestId) || isPHBProject,
    },
  });

  const milestones = useMemo(
    () => (drawRequestId ? drawRequestMilestones.data?.results : projectMilestones.data?.results),
    [drawRequestId, drawRequestMilestones.data, projectMilestones.data],
  );

  const mapLineItems = (items: IMilestone[], type: DocumentContentTypeEnum, key = 'id') =>
    items?.map((o) => createTuple(type, o[key])) || [];

  const getLinkedObjects = (lineItems: IMilestone[]) => {
    const projectTuple = createTuple(DocumentContentTypeEnum.PROJECT, projectId);
    const drawRequestTuple = createTuple(DocumentContentTypeEnum.DRAW_REQUEST, drawRequestId);
    const milestoneTuple = mapLineItems(lineItems, DocumentContentTypeEnum.MILESTONE);

    const linkedObjects = {
      [DocumentContentTypeEnum.MILESTONE]: [
        ...(showLineItems
          ? drawRequestId
            ? [drawRequestTuple, ...milestoneTuple]
            : [projectTuple, ...milestoneTuple]
          : [createTuple(DocumentContentTypeEnum.MILESTONE, params?.fields?.object_id)]),
      ],
      [DocumentContentTypeEnum.MILESTONE_GROUP]: [
        ...(drawRequestId ? [drawRequestTuple] : []),
        createTuple(DocumentContentTypeEnum.MILESTONE_GROUP, params?.fields?.object_id),
      ],
      [DocumentContentTypeEnum.DRAW_REQUEST]: [drawRequestTuple, ...milestoneTuple],
      [DocumentContentTypeEnum.PROJECT]: [projectTuple, ...milestoneTuple],
      [DocumentContentTypeEnum.CHECKLIST_ITEM]: [
        drawRequestTuple,
        createTuple(DocumentContentTypeEnum.CHECKLIST_ITEM, params?.fields?.object_id),
        ...milestoneTuple,
      ],
      [DocumentContentTypeEnum.INSPECTION_LINE_ITEM]: [
        createTuple(DocumentContentTypeEnum.INSPECTION_LINE_ITEM, params?.fields?.object_id),
      ],
      [DocumentContentTypeEnum.INSPECTION]: [
        createTuple(DocumentContentTypeEnum.INSPECTION, inspectionId),
        ...milestoneTuple,
        ...(milestoneId ? [createTuple(DocumentContentTypeEnum.MILESTONE, milestoneId)] : []),
      ],
    };
    return linkedObjects[params?.fields?.content_type] || [];
  };

  const documentTypesObj = documentTypes?.reduce((acc, obj) => {
    if (obj) acc[obj.name] = obj.id;
    return acc;
  }, {});

  const milestoneOptions = useMemo(
    () =>
      getSortedByIndexMilestones(milestones)?.map((milestone) => ({
        ...milestone,
        name_display: `${milestone.project_milestone?.index || milestone.index}. ${milestone.name}`,
      })),
    [milestones],
  );

  const isDocumentUploader = useMemo(() => Boolean(documentTypes?.length), [documentTypes]);

  const [uppy] = useState(() =>
    useCustomUppy({
      params,
      signature,
      restrictions,
      enqueueSnackbar,
    }),
  );

  const formik = useFormik({
    validationSchema,
    initialValues,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        const files = await uppy.getFiles();
        files.map((file) => {
          const fileId = file.id.replaceAll('.', '-');
          const meta = {
            name: values[`${fileId}-name`] || file.name,
            linked_objects: getLinkedObjects(values[`${fileId}-milestone`]),
            ...(isDocumentUploader
              ? {
                  document_type_id: documentTypesObj[values[`${fileId}-type`]],
                  comment: values[`${fileId}-comment`] || '',
                  thumb_disabled: true,
                  scope: values[`${fileId}-scope`]
                    ? VisibilityEnum.COMPANY
                    : VisibilityEnum.EVERYONE,
                }
              : {
                  content_type: params?.fields?.content_type,
                  ...(documentTypeId ? { document_type_id: documentTypeId } : {}),
                }),
          };
          uppy.setFileMeta(file.id, meta);
        });

        setShowDashboard(true);
        await uppy.upload();
      } catch (error) {
        formik.setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    uppy.on('complete', () => {
      if (refetch?.length) {
        refetch.forEach((query) => refetchCallback(query));
      }
      if (isDocumentUploader) {
        queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_DOCUMENTS, { projectId }]);
        if (drawRequestId) {
          queryClient.invalidateQueries([
            QueryNamesEnums.GET_DRAW_REQUEST_DOCUMENTS,
            { projectId, drawRequestId },
          ]);
          queryClient.invalidateQueries([
            QueryNamesEnums.GET_DRAW_REQUEST_MILESTONE_DOCS,
            { projectId, drawRequestId },
          ]);
        }
      } else {
        if (drawRequestId)
          queryClient.invalidateQueries([
            QueryNamesEnums.GET_DRAW_REQUEST_PHOTOS,
            { projectId, drawRequestId },
          ]);
        queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_PHOTOS, { projectId }]);
      }
      resetFormAndClose();
    });

    uppy.on('files-added', (files) => {
      if (!files?.length) return;
      setShowDragDrop(false);
      setShowDashboard(false);
      setCurrentFiles(
        files.map((file) => ({ ...file, internal_id: file?.id.replaceAll('.', '-') })),
      );
    });

    uppy.on('error', (error: ITransloaditError) => {
      error.details && enqueueSnackbar(error.details, { variant: 'error' });
      resetFormAndClose();
    });

    uppy.on('restriction-failed', (file) => {
      enqueueSnackbar(
        `${file.name} can't be uploaded as .${file.extension} extension is not allowed.`,
        { variant: 'error' },
      );
    });
  }, []);

  const initMilestones = useMemo(() => {
    if (milestoneId) {
      return milestoneOptions.filter((milestone) => milestone.id === milestoneId);
    }
    return [];
  }, [milestoneOptions]);

  useEffect(() => {
    if (currentFiles?.length && !isEmpty(milestones)) {
      const validationObject = {};
      const initialData = {};
      currentFiles.forEach((file) => {
        const name = `${file.internal_id}-name`;
        const type = `${file.internal_id}-type`;
        const scope = `${file.internal_id}-scope`;
        const milestone = `${file.internal_id}-milestone`;
        if (isEmpty(initialValues)) {
          //trim file extension from file name
          initialData[name] = file.data?.name?.split('.').slice(0, -1).join('.');
          if (initMilestones) {
            initialData[milestone] = initMilestones;
            setGeneralLineItems(initMilestones);
          }
          if (isDocumentUploader) initialData[type] = '';
          if (isDocumentUploader) {
            initialData[scope] = checkIsLender(teamRole) || checkIsInvestor(teamRole);
          }
        }
        validationObject[name] = Yup.string().required('Required');
        if (isDocumentUploader) validationObject[type] = Yup.string().required('Required');
      });
      setValidationSchema(Yup.object().shape(validationObject));
      if (isEmpty(initialValues)) setInitialValues(initialData);
    } else {
      setShowDragDrop(true);
      setShowDashboard(true);
    }
  }, [currentFiles, initMilestones, milestones]);

  const resetFormAndClose = useCallback(() => {
    setCurrentFiles([]);
    formik.resetForm();
    closeUploader();
  }, []);

  const valuesArray = ({
    value,
    id,
    name,
  }: {
    value: string | EnumTypeForList[];
    id: string;
    name: string;
  }) => {
    return currentFiles.map((file) =>
      file.internal_id === id
        ? value
        : formik.getFieldProps(`${file.internal_id}-${name}`)?.value || [],
    );
  };

  const setDocumentType = (value: IDocumentType, id: string) => {
    formik.setFieldValue(`${id}-type`, value?.name);
    if (!value?.name) setGeneralDocumentType(null);

    const docTypeArrays = valuesArray({ value: value?.name, id, name: 'type' });
    if (docTypeArrays.every((type) => type === value?.name)) {
      setGeneralDocumentType(value?.name);
    } else {
      setGeneralDocumentType(null);
    }
  };

  const setMilestone = (value: EnumTypeForList[], id: string) => {
    formik.setFieldValue(`${id}-milestone`, value);
    const lineItemArrays = valuesArray({ value, id, name: 'milestone' });
    const commonObjectIds = intersection(...lineItemArrays.map((arr) => arr.map((obj) => obj.id)));
    const commonLineItems = milestoneOptions.filter((milestone) =>
      commonObjectIds.includes(milestone.id),
    );

    setGeneralLineItems(commonLineItems);
  };

  const handleGeneralDocumentType = (value) => {
    setGeneralDocumentType(value);
    currentFiles.map((file) => formik.setFieldValue(`${file.internal_id}-type`, value?.name));
  };

  const handleGeneralLineItems = (value) => {
    setGeneralLineItems(value);
    currentFiles.map((file) => formik.setFieldValue(`${file.internal_id}-milestone`, value));
  };

  const updateGeneralScope = (value) => {
    setGeneralScope(value);
    currentFiles.map((file) => formik.setFieldValue(`${file.internal_id}-scope`, value));
  };

  const deleteFile = (id: string) => {
    uppy.removeFile(id);
    setCurrentFiles((previous) => previous.filter((file) => file.internal_id !== id));
  };

  const handleChangeSwitch = (fileId: string) => {
    if (formik.getFieldProps(`${fileId}-scope`)?.value) {
      setGeneralScope(false);
    } else {
      const allScopes = currentFiles.every(
        (file) =>
          formik.getFieldProps(`${file.internal_id}-scope`)?.value || file.internal_id === fileId,
      );
      if (allScopes) setGeneralScope(true);
    }
    formik.setFieldValue(`${fileId}-scope`, !formik.getFieldProps(`${fileId}-scope`)?.value);
  };

  const cancelUpload = () => {
    uppy.cancelAll();
    resetFormAndClose();
  };

  const titleText = useMemo(() => {
    if (isProjectImage) {
      return 'Edit project image';
    }
    return `Upload multiple ${isDocumentUploader ? 'documents' : 'progress photos'}`;
  }, [isDocumentUploader, isProjectImage]);

  return {
    milestoneOptions,
    isDocumentUploader,
    currentFiles,
    formik,
    uppy,
    showDashboard,
    showDragDrop,
    setDocumentType,
    setMilestone,
    handleGeneralDocumentType,
    generalDocumentType,
    generalLineItems,
    handleGeneralLineItems,
    generalScope,
    updateGeneralScope,
    handleChangeSwitch,
    deleteFile,
    cancelUpload,
    titleText,
  };
};
