import { useEffect, useMemo } from 'react';
import { useQuery, UseQueryResult } from 'react-query';
import { defaultRowsPerPageOptions, onlyProgressPhotos } from '@constants';
import { useTablePagination } from '@hooks';
import { HookState, IDocument, IProjectDocument, QueryNamesEnums } from '@interfaces';
import {
  getDrawRequestDocumentsList,
  getInspectionDocuments,
  getInspectionMilestoneDocuments,
  getMilestoneDocumentsV2,
  getProjectDocuments,
  getProjectMilestoneDocumentsV2,
} from '@globalService';
import { getFilteredByRepresentationPhotos, getHookState } from '@utils';
import { PhotoListProps } from '../../interface';

export const usePhotoList = ({
  context,
  projectId,
  drawRequestId,
  milestoneId,
  inspectionId,
  setTotalDocCount,
}: PhotoListProps) => {
  const isProjectLevel = useMemo(
    () => !drawRequestId && !inspectionId,
    [drawRequestId, inspectionId],
  );

  const tablePagination = useTablePagination({
    initialRowsPerPage: defaultRowsPerPageOptions.photos[0],
    rowsPerPageOptions: defaultRowsPerPageOptions.photos,
  });

  const stringQueryParams = useMemo(() => {
    return `&offset=${
      tablePagination.page * tablePagination.rowsPerPage
    }&limit=${tablePagination.rowsPerPage}&context=${context}`;
  }, [context, tablePagination.page, tablePagination.rowsPerPage]);

  const drawRequestMilestonePhotosQuery = useQuery<{ results: IDocument[]; count: number }, Error>(
    [
      QueryNamesEnums.GET_DRAW_REQUEST_MILESTONE_DOCS,
      {
        projectId,
        drawRequestId,
        milestoneId,
        query: onlyProgressPhotos,
        stringQueryParams,
      },
    ],
    () =>
      getMilestoneDocumentsV2({
        projectId,
        drawRequestId,
        milestoneId,
        query: onlyProgressPhotos,
        stringQueryParams,
      }),
    { enabled: Boolean(projectId && drawRequestId && milestoneId) },
  );

  const inspectionMilestonePhotosQuery = useQuery<{ results: IDocument[]; count: number }, Error>(
    [
      QueryNamesEnums.GET_INSPECTION_MILESTONE_DOCS,
      {
        projectId,
        inspectionId,
        milestoneId,
        query: onlyProgressPhotos,
        stringQueryParams,
      },
    ],
    () =>
      getInspectionMilestoneDocuments({
        projectId,
        inspectionId,
        milestoneId,
        restQlParams: onlyProgressPhotos,
        stringQueryParams,
      }),
    { enabled: Boolean(projectId && inspectionId && milestoneId) },
  );

  const inspectionPhotosQuery = useQuery<{ results: IProjectDocument[]; count: number }, Error>(
    [
      QueryNamesEnums.GET_INSPECTION_DOCUMENTS,
      { projectId, inspectionId, query: onlyProgressPhotos, stringQueryParams },
    ],
    () =>
      getInspectionDocuments({
        projectId,
        inspectionId,
        query: onlyProgressPhotos,
        stringQueryParams,
      }),
    { enabled: Boolean(projectId && inspectionId && !milestoneId) },
  );

  const projectMilestonePhotosQuery = useQuery<{ results: IDocument[]; count: number }, Error>(
    [
      QueryNamesEnums.GET_PROJECT_MILESTONE_DOCS,
      {
        projectId,
        milestoneId,
        query: onlyProgressPhotos,
        stringQueryParams,
      },
    ],
    getProjectMilestoneDocumentsV2.bind(this, {
      projectId,
      milestoneId,
      query: onlyProgressPhotos,
      stringQueryParams,
    }),
    { enabled: Boolean(!drawRequestId && !inspectionId && projectId && milestoneId) },
  );

  const drawRequestPhotosQuery = useQuery<{ results: IDocument[]; count: number }, Error>(
    [
      QueryNamesEnums.GET_DRAW_REQUEST_DOCUMENTS,
      {
        projectId,
        drawRequestId,
        stringQueryParams: stringQueryParams + onlyProgressPhotos,
      },
    ],
    getDrawRequestDocumentsList.bind(this, {
      projectId,
      drawRequestId,
      stringQueryParams: stringQueryParams + onlyProgressPhotos,
    }),
    {
      enabled: Boolean(projectId && drawRequestId && !milestoneId && !inspectionId),
    },
  );

  const projectDocumentsQuery = useQuery<{ results: IProjectDocument[]; count: number }, Error>(
    [
      QueryNamesEnums.GET_PROJECT_DOCUMENTS,
      { projectId, stringQueryParams: stringQueryParams + onlyProgressPhotos },
    ],
    getProjectDocuments.bind(this, {
      projectId,
      stringQueryParams: stringQueryParams + onlyProgressPhotos,
    }),
    {
      enabled: Boolean(projectId && !drawRequestId && !milestoneId && !inspectionId),
    },
  );

  const getDocumentDetails = (
    query:
      | UseQueryResult<{ results: IDocument[]; count: number }, Error>
      | UseQueryResult<{ results: IProjectDocument[]; count: number }, Error>,
  ) => ({
    state: getHookState(query),
    photos: getFilteredByRepresentationPhotos(query.data?.results as IDocument[]),
    documentsCount: query.data?.count,
  });

  const values = useMemo(() => {
    if (isProjectLevel && milestoneId) {
      return {
        ...getDocumentDetails(projectMilestonePhotosQuery),
      };
    }

    if (inspectionId) {
      if (milestoneId) {
        return {
          ...getDocumentDetails(inspectionMilestonePhotosQuery),
        };
      }

      return {
        ...getDocumentDetails(inspectionPhotosQuery),
      };
    }

    if (drawRequestId) {
      if (milestoneId) {
        return {
          ...getDocumentDetails(drawRequestMilestonePhotosQuery),
        };
      }
      return {
        ...getDocumentDetails(drawRequestPhotosQuery),
      };
    }

    return {
      ...getDocumentDetails(projectDocumentsQuery),
    };
  }, [
    isProjectLevel,
    projectMilestonePhotosQuery,
    drawRequestMilestonePhotosQuery,
    inspectionMilestonePhotosQuery,
    inspectionPhotosQuery,
    drawRequestPhotosQuery,
    projectDocumentsQuery,
    inspectionId,
    drawRequestId,
    milestoneId,
  ]);

  useEffect(() => {
    if (values.state === HookState.SUCCESS) {
      setTotalDocCount((prev) => ({ ...prev, [context]: values.documentsCount }));
    }
  }, [values.state]);

  return {
    ...values,
    tablePagination,
  };
};
