import { MilestoneListColumnTypeV2 } from './common';
import { isRestricted } from '@utils';
import { PermissionNamesEnums } from '@interfaces';

const nameV2: MilestoneListColumnTypeV2 = {
  field: 'name',
  headerName: 'Line Item',
  description: 'Line Item',
  headerClassName: 'text',
  headerAlign: 'left',
  align: 'left',
  cellClassName: 'text',
  flex: 1,
  resizable: false,
  editable: true,
  sortable: true,
  filterable: true,
  groupable: false,
  aggregable: true,
  hideable: false,
  minWidth: 200,
  editableByMilestone: (row) => !row.project_milestone && row.isEditableV2 && row.localNew,
  editableByPermissions: (permissions) =>
    !isRestricted(PermissionNamesEnums.DRAWREQUESTS_LINE_ITEM_CREATE, permissions),
};

export default nameV2;
