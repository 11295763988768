import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useMutation, useQueries, useQuery, useQueryClient } from 'react-query';
import {
  CSVPHBBudgetSectionsKeysEnum,
  DeleteAllListItemsParam,
  ErrorDual,
  IFilterOption,
  IMenuItem,
  IMilestone,
  IMilestoneTotal,
  IPHBTableItem,
  IProjectMilestone,
  MutationKeyEnum,
  PatchProjectMSGroupParam,
  QueryNamesEnums,
  TableKeyEnum,
} from '@interfaces';
import {
  deleteProjectBuildingModels,
  deleteProjectMilestones,
  getProject,
  getProjectDrawRequestsList,
  getProjectMilestonesList,
  patchProjectMSGroup,
} from '@globalService';
import { useParams } from 'react-router-dom';
import {
  checkHasAmountChanges,
  getItemLocalHighlight,
  getPHBViewTypes,
  getTypeFilterValue,
  isCreatedProject,
  parsePathErrorDual,
  replaceMilestoneData,
  useExpandCollapseTable,
  useLoadingSkeleton,
  usePHBFilters,
  usePHBGrouping,
  usePHBNaming,
} from '@utils';
import { useSafeSnackbar, useUpdateUiSettings, useUrlParams } from '@hooks';
import { DeleteIcon } from '@svgAsComponents';
import { Typography } from '@mui/material';
import { colors } from '@theme';
import { SettingsContext, useLaunchDarklyFlags } from '@context';
import cloneDeep from 'lodash/cloneDeep';

export type ControllerInterface = {
  initColumns: string[];
  tableItems: IPHBTableItem[];
  onExpandClick: (id: string, isExpanded: boolean) => void;
  filterOptions: IFilterOption[];
  filterValue: string;
  handleFilterClick: (value: string) => void;
  isLoading: boolean;
  menuItems: IMenuItem[];
  isDeleteBudgetPopupOpen: boolean;
  setIsDeleteBudgetPopupOpen: React.Dispatch<React.SetStateAction<boolean>>;
  deleteBudget: (selectedKeys: CSVPHBBudgetSectionsKeysEnum[]) => void;
  isDeleting: boolean;
  totals: IMilestoneTotal;
  patchMsGroup: (params) => void;
  activeView: string;
  setActiveView: (state: string) => void;
  isLineItemsView: boolean;
  viewTypes: { label: string; value: string }[];
  typeFilterValues: string[];
  setTypeFilterValues: Dispatch<SetStateAction<string[]>>;
};

export const useBudgetTable = (): ControllerInterface => {
  const { projectId } = useParams();
  const queryClient = useQueryClient();
  const [unitsTableItems, setUnitsTableItems] = useState([]);
  const [lineItemsTableItems, setLineItemsTableItems] = useState<IPHBTableItem[]>([]);
  const [isDeleteBudgetPopupOpen, setIsDeleteBudgetPopupOpen] = useState(false);
  const { showLoadingSkeleton, showTemporaryLoadingSkeleton } = useLoadingSkeleton();
  const { enqueueSnackbar } = useSafeSnackbar();
  const { isCurrentProjectActive, settings } = useContext(SettingsContext);
  const { updateSettings } = useUpdateUiSettings();
  const { unitLineItemGrouping, lineItemUnitGrouping } = usePHBGrouping();
  const { unitName } = usePHBNaming();
  const flags = useLaunchDarklyFlags();
  const [typeFilterValues, setTypeFilterValues] = React.useState<string[]>([
    'is_horizontal=true',
    'is_vertical=true',
  ]);
  const typeFilterValue = useMemo(() => getTypeFilterValue(typeFilterValues), [typeFilterValues]);

  const viewTypes = useMemo(() => getPHBViewTypes(unitName), [unitName]);
  const [activeView, setActiveView] = useUrlParams(
    settings.personal_setting?.PHB_TABLE_VIEW?.view_type || viewTypes[0].value,
    'view',
    (s) => s.toString(),
    (s) => s.toString(),
  );
  useEffect(() => {
    const savedViewType = settings.personal_setting?.PHB_TABLE_VIEW?.view_type;
    if (savedViewType && savedViewType !== activeView) setActiveView(savedViewType);
  }, [settings.personal_setting?.PHB_TABLE_VIEW?.view_type]);
  const isLineItemsView = useMemo(() => activeView === viewTypes[1].value, [activeView]);

  const query = '{*}';
  const totalsQuery = '{totals}';
  const requestedDataQueries = useQueries([
    {
      queryKey: [QueryNamesEnums.GET_PROJECT, { projectId }],
      queryFn: getProject.bind(this, projectId),
    },
    {
      queryKey: [QueryNamesEnums.GET_PROJECT_DRAW_REQUEST_LIST, { projectId }],
      queryFn: getProjectDrawRequestsList.bind(this, projectId),
    },
  ]);

  const project = requestedDataQueries[0].data;
  const drawRequests = requestedDataQueries[1].data?.results;

  const { filterValue, handleFilterClick, filterOptions, filterKey } = usePHBFilters({
    tableKey: TableKeyEnum.PHB_LINE_ITEMS,
    isNewView: true,
  });

  const projectMilestonesTotalsQuery = useQuery<
    { results: IProjectMilestone[]; totals: IMilestoneTotal },
    Error
  >(
    [QueryNamesEnums.GET_PROJECT_MILESTONES, { projectId, query: totalsQuery }],
    getProjectMilestonesList.bind(this, { projectId, query: totalsQuery }),
    { enabled: Boolean(projectId) },
  );

  const lineItemsQuery = useQuery<{ results: IProjectMilestone[]; count: number }, Error>(
    [
      QueryNamesEnums.GET_PROJECT_MILESTONES,
      {
        projectId,
        query,
        groupBy: lineItemUnitGrouping,
        typeKeys: typeFilterValue,
      },
    ],
    getProjectMilestonesList.bind(this, {
      projectId,
      query,
      groupBy: lineItemUnitGrouping,
      typeKeys: typeFilterValue,
    }),
    {
      enabled: Boolean(projectId && lineItemUnitGrouping),
    },
  );

  const projectMilestonesQuery = useQuery<{ results: IProjectMilestone[]; count: number }, Error>(
    [
      QueryNamesEnums.GET_PROJECT_MILESTONES,
      {
        projectId,
        query,
        groupBy: unitLineItemGrouping,
        filterKey,
      },
    ],
    getProjectMilestonesList.bind(this, {
      projectId,
      query,
      groupBy: unitLineItemGrouping,
      filterKey,
    }),
    { enabled: Boolean(projectId && unitLineItemGrouping) },
  );
  const projectMilestones = projectMilestonesQuery.data;

  const deleteBudgetMutation = useMutation<Response, ErrorDual, DeleteAllListItemsParam>(
    deleteProjectMilestones,
    {
      mutationKey: MutationKeyEnum.MILESTONE_DELETE,
      onSuccess: () => {
        queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_MILESTONES, { projectId }]);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
      onSettled: () => {
        setIsDeleteBudgetPopupOpen(false);
      },
    },
  );

  const deleteAllModelsMutation = useMutation<Response, ErrorDual, { project: string }>(
    deleteProjectBuildingModels,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_BUILDING_MODELS, { projectId }]);
        queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_BUILDING, { projectId }]);
        queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_MILESTONE_TAGS, { projectId }]);
      },
      onError: (error) => {
        enqueueSnackbar(parsePathErrorDual(error), { variant: 'error' });
      },
    },
  );

  useEffect(() => {
    setUnitsTableItems(null);
  }, [filterValue, projectMilestones?.count]);

  useEffect(() => {
    setLineItemsTableItems(null);
  }, [typeFilterValues, projectMilestones?.count]);

  useEffect(() => {
    if (!projectMilestones?.results?.length || unitsTableItems?.length) return;
    const clonedMilestones = cloneDeep(projectMilestones.results);

    setUnitsTableItems(clonedMilestones.map((item, index) => createTableObject({ item, index })));
  }, [projectMilestones?.results, unitsTableItems]);

  useEffect(() => {
    if (!lineItemsQuery.data?.results || lineItemsTableItems?.length) return;
    const clonedMilestones = cloneDeep(lineItemsQuery.data?.results);
    setLineItemsTableItems(
      clonedMilestones.map((item, index) => createTableObject({ item, index })),
    );
  }, [lineItemsQuery.data?.results, lineItemsTableItems]);

  const createTableObject = ({
    item,
    isExpanded = false,
    isNested = false,
    index,
  }): IPHBTableItem => ({
    ...item,
    activeToEdit: false,
    localNew: false,
    localHighlight: getItemLocalHighlight(item),
    canBeExpanded: item?.milestone_groups?.length > 0,
    isExpanded,
    isNested,
    index,
    project_milestone: { ...item.project_milestone, index },
  });

  const unitsNestedTableProps = useExpandCollapseTable({
    setListItems: setUnitsTableItems,
    createTableObject,
  });

  const lineItemsNestedTableProps = useExpandCollapseTable({
    setListItems: setLineItemsTableItems,
    createTableObject,
  });

  const retainageRate = useMemo(() => project?.retainage_rate, [project]);

  const hasAmountChanges = useMemo(
    () => checkHasAmountChanges(projectMilestones?.results),
    [projectMilestones?.results],
  );

  const initColumns = useMemo(
    () => [
      'productionBuildExpand',
      ...(flags?.[`ENG_7895_table_v3__${TableKeyEnum.PHB_LINE_ITEMS}`] ? ['externalId'] : []),
      'nameV2',
      'costType',
      'originalConstructionBudget',
      'prefundingCost',
      'originalEstimate',
      ...(retainageRate ? ['retainageRateBudget'] : []),
      'revisedConstructionBudget',
      ...(hasAmountChanges ? ['adjustmentsTotalApprovedBudget'] : []),
      'revisedMilestoneAmount',
      'balanceToFinish',
      'balanceToFinishRate',
      'inspectorAllowance',
      'inspectorAllowanceRate',
      'approvedAmountCumulative',
      'lenderAllowanceRate',
      'varianceToLenderAllowanceRate',
      'documentsPhotosUploaderMenu',
      ...(flags?.['ENG_8397_docs_photos_circle_indication']
        ? ['documentsPhotosGalleryMenuV2']
        : ['documentsPhotosGalleryMenu']),
      ...(isCurrentProjectActive ? [] : ['deleteLineItem']),
      'comments',
    ],
    [retainageRate, hasAmountChanges, isCurrentProjectActive, flags],
  );

  const menuItems = useMemo(
    () =>
      isCreatedProject(project?.status) && !drawRequests?.length
        ? [
            {
              text: (
                <Typography variant="body3SemiBold" sx={{ color: colors.status.error.medium }}>
                  Delete budget section
                </Typography>
              ),
              icon: <DeleteIcon size={16} color={colors.status.error.medium} />,
              action: () => setIsDeleteBudgetPopupOpen(true),
              dataTestName: 'project__budget__delete__button',
            },
          ]
        : [],
    [project, drawRequests],
  );

  const removeAllModels = async () =>
    deleteAllModelsMutation.mutateAsync({
      project: projectId,
    });

  const removeAllMilestones = async () =>
    deleteBudgetMutation.mutateAsync({
      projectId,
    });

  const removeBudgetSection = ({ isVerticalSelected, isHorizontalSelected }) => {
    deleteBudgetMutation.mutateAsync({
      projectId,
      isVertical: isVerticalSelected,
      isHorizontal: isHorizontalSelected,
    });
  };

  const deleteBudget = (selectedKeys: CSVPHBBudgetSectionsKeysEnum[]) => {
    if (!selectedKeys?.length) {
      setIsDeleteBudgetPopupOpen(false);
      return;
    }

    const isVerticalSelected = selectedKeys.includes(CSVPHBBudgetSectionsKeysEnum.IS_VERTICAL);
    const isHorizontalSelected = selectedKeys.includes(CSVPHBBudgetSectionsKeysEnum.IS_HORIZONTAL);
    const isAllSelected = selectedKeys.includes(CSVPHBBudgetSectionsKeysEnum.ALL);

    if (isAllSelected) {
      removeAllMilestones();
      removeAllModels();
    } else if (isVerticalSelected && isHorizontalSelected) {
      removeAllMilestones();
    } else {
      removeBudgetSection({ isVerticalSelected, isHorizontalSelected });
    }
  };

  const totals = useMemo(
    () => ({ ...projectMilestonesTotalsQuery.data?.totals, displayAll: true }),
    [projectMilestonesTotalsQuery.data?.totals],
  );

  const invalidateAfterLineItemPatch = async (data) => {
    queryClient.setQueriesData<{ results: IMilestone[] }>(
      {
        queryKey: [
          QueryNamesEnums.GET_PROJECT_MILESTONES,
          {
            projectId,
            query,
            groupBy: lineItemUnitGrouping,
            typeKeys: typeFilterValue,
          },
        ],
      },
      (old) =>
        replaceMilestoneData({
          milestones: old,
          milestoneId: data.id,
          json: data,
        }),
    );
    await queryClient.invalidateQueries([
      QueryNamesEnums.GET_PROJECT_MILESTONES,
      { projectId, query, groupBy: unitLineItemGrouping, filterKey },
    ]);
  };

  const invalidateAfterUnitPatch = async (data) => {
    queryClient.setQueriesData<{ results: IMilestone[] }>(
      {
        queryKey: [
          QueryNamesEnums.GET_PROJECT_MILESTONES,
          {
            projectId,
            query,
            groupBy: unitLineItemGrouping,
            filterKey,
          },
        ],
      },
      (old) =>
        replaceMilestoneData({
          milestones: old,
          milestoneId: data.id,
          json: data,
        }),
    );
    await queryClient.invalidateQueries([
      QueryNamesEnums.GET_PROJECT_MILESTONES,
      { projectId, query, groupBy: lineItemUnitGrouping, typeKeys: typeFilterValue },
    ]);
  };

  const patchProjectProdBuildGroupMutation = useMutation<
    IMilestone,
    ErrorDual,
    PatchProjectMSGroupParam
  >(patchProjectMSGroup, {
    mutationKey: MutationKeyEnum.MILESTONE_PATCH,
    onSuccess: async (data) => {
      if (isLineItemsView) {
        await invalidateAfterLineItemPatch(data);
        lineItemsNestedTableProps.updateListItemsWithParentGroup(data);
        setUnitsTableItems(null);
      } else {
        await invalidateAfterUnitPatch(data);
        unitsNestedTableProps.updateListItemsWithParentGroup(data);
        setLineItemsTableItems(null);
      }
    },
  });

  const patchMsGroup = useCallback(
    (params) =>
      patchProjectProdBuildGroupMutation.mutate({
        project: projectId,
        group_by: isLineItemsView ? lineItemUnitGrouping : unitLineItemGrouping,
        milestoneId: params.milestoneId,
        json: params.json,
      }),
    [
      projectId,
      unitLineItemGrouping,
      lineItemUnitGrouping,
      isLineItemsView,
      patchProjectProdBuildGroupMutation,
    ],
  );

  return {
    initColumns,
    tableItems: isLineItemsView ? lineItemsTableItems : unitsTableItems,
    onExpandClick: isLineItemsView
      ? lineItemsNestedTableProps.onExpandClick
      : unitsNestedTableProps.onExpandClick,
    filterOptions,
    filterValue,
    handleFilterClick: (value: string) => {
      showTemporaryLoadingSkeleton();
      handleFilterClick(value);
    },
    menuItems,
    isDeleteBudgetPopupOpen,
    setIsDeleteBudgetPopupOpen,
    deleteBudget,
    isDeleting: deleteBudgetMutation.isLoading,
    isLoading:
      showLoadingSkeleton ||
      projectMilestonesQuery.isLoading ||
      projectMilestonesQuery.isIdle ||
      projectMilestonesTotalsQuery.isLoading,
    totals: isLineItemsView ? undefined : totals,
    patchMsGroup,
    activeView,
    setActiveView: (view) => {
      setActiveView(view);
      updateSettings({
        personal_setting: {
          PHB_TABLE_VIEW: { view_type: view },
        },
      });
    },
    isLineItemsView,
    viewTypes,
    typeFilterValues,
    setTypeFilterValues,
  };
};
