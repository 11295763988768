import { useQueryClient } from 'react-query';
import { DocumentTypeEnum, EventType, QueryNamesEnums } from '@interfaces';
import { useLogout } from '@hooks';
import { useLaunchDarklyFlags } from '@context';

export const useUpdateProjectStatusInvalidation = () => {
  const queryClient = useQueryClient();

  return (params: { projectId: string }) => {
    const { projectId } = params;
    if (!projectId) return;

    queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT, { projectId }]);
    queryClient.invalidateQueries(QueryNamesEnums.GET_DRAW_REQUEST_LIST);
    queryClient.invalidateQueries([QueryNamesEnums.GET_INSPECTIONS_LIST, { projectId }]);
    queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_DOCUMENTS, { projectId }]);
    queryClient.invalidateQueries(QueryNamesEnums.GET_PROJECTS_LIST);
    queryClient.invalidateQueries(QueryNamesEnums.GET_PROJECTS_STATS);
    queryClient.invalidateQueries({
      predicate: (query) =>
        (query.queryKey[0] === QueryNamesEnums.GET_DRAW_REQUEST &&
          (query.queryKey[1] as { projectId?: string })?.projectId) == projectId,
    });
  };
};

export const useReviewRequestInvalidation = () => {
  const queryClient = useQueryClient();

  return (params: { projectId: string; drawRequestId: string; event_type?: string }) => {
    const { projectId, drawRequestId, event_type } = params;

    if (!projectId || !drawRequestId) return;

    if (event_type !== EventType.DRAW_REQUEST_DELETED) {
      queryClient.invalidateQueries([
        QueryNamesEnums.GET_DRAW_REQUEST,
        { projectId, drawRequestId },
      ]);
      queryClient.invalidateQueries([
        QueryNamesEnums.GET_DRAW_REQUEST_REVIEWS,
        { projectId, drawRequestId },
      ]);
      queryClient.invalidateQueries([
        QueryNamesEnums.GET_DRAW_REQUEST_AUDIT_LOG,
        { drawRequestId },
      ]);
      queryClient.invalidateQueries([
        QueryNamesEnums.GET_DRAW_REQUEST_ITEM_CHECKLIST,
        { projectId, drawRequestId },
      ]);
    } else {
      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey[0] === QueryNamesEnums.GET_DRAW_REQUEST &&
          (query.queryKey[1] as { projectId?: string })?.projectId == projectId &&
          (query.queryKey[1] as { drawRequestId?: string })?.drawRequestId != drawRequestId,
      });
    }

    queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_DRAW_REQUEST_LIST, { projectId }]);
    queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_PROGRESS, { projectId }]);
    queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_FUNDS, { projectId }]);
    queryClient.invalidateQueries(QueryNamesEnums.GET_PROJECTS_LIST);
    queryClient.invalidateQueries([QueryNamesEnums.GET_DRAW_REQUEST_FOR_APPROVAL, { projectId }]);
    queryClient.invalidateQueries(QueryNamesEnums.GET_DRAW_REQUEST_LIST);
    queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_COMMENTS, { projectId }]);
    queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_MILESTONES_COLUMNS, { projectId }]);
    queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_AUDIT_LOG, { projectId }]);
    queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_MILESTONES, { projectId }]);
  };
};

export const useAssignCoordinatorInvalidation = () => {
  const queryClient = useQueryClient();

  return (params: { projectId: string; drawRequestId: string }) => {
    const { projectId, drawRequestId } = params;
    if (!projectId || !drawRequestId) return;

    queryClient.invalidateQueries([QueryNamesEnums.GET_DRAW_REQUEST, { projectId, drawRequestId }]);
    queryClient.invalidateQueries(QueryNamesEnums.GET_DRAW_REQUEST_LIST);
  };
};

export const useSwitchReviewerInvalidation = () => {
  const queryClient = useQueryClient();

  return (params: { projectId: string; drawRequestId: string }) => {
    const { projectId, drawRequestId } = params;
    if (!projectId || !drawRequestId) return;

    queryClient.invalidateQueries([QueryNamesEnums.GET_DRAW_REQUEST, { projectId, drawRequestId }]);
    queryClient.invalidateQueries(QueryNamesEnums.GET_DRAW_REQUEST_LIST);
    queryClient.invalidateQueries([QueryNamesEnums.GET_DRAW_REQUEST_FOR_APPROVAL, { projectId }]);
    queryClient.invalidateQueries([
      QueryNamesEnums.GET_DRAW_REQUEST_ITEM_CHECKLIST,
      { projectId, drawRequestId },
    ]);
    queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_DRAW_REQUEST_LIST, { projectId }]);
  };
};

export const useInspectionStatusInvalidation = () => {
  const queryClient = useQueryClient();

  return (params: { projectId: string; inspectionId?: string; drawRequestId?: string }) => {
    const { projectId, inspectionId, drawRequestId } = params;
    if (!projectId) return;

    queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_INSPECTIONS, { projectId }]);
    queryClient.invalidateQueries(QueryNamesEnums.GET_INSPECTIONS_LIST);
    if (drawRequestId) {
      queryClient.invalidateQueries([
        QueryNamesEnums.GET_DRAW_REQUEST_INSPECTIONS,
        { projectId, drawRequestId },
      ]);
    }
    if (inspectionId) {
      queryClient.invalidateQueries([
        QueryNamesEnums.GET_PROJECT_INSPECTION_BY_ID,
        { projectId, inspectionId },
      ]);
    }
  };
};

export const useServiceOrderStatusInvalidation = () => {
  const queryClient = useQueryClient();

  return (params: { projectId: string; serviceOrderId?: string; drawRequestId?: string }) => {
    const { projectId, serviceOrderId, drawRequestId } = params;
    if (!projectId) return;

    // to invalidate all service orders lists with different query params
    queryClient.invalidateQueries({
      predicate: (query) =>
        (query.queryKey[0] === QueryNamesEnums.GET_PROJECT_SERVICE_ORDERS &&
          (query.queryKey[1] as { projectId?: string })?.projectId) == projectId,
    });
    queryClient.invalidateQueries(QueryNamesEnums.GET_SERVICE_ORDERS_LIST);
    if (drawRequestId) {
      queryClient.invalidateQueries([
        QueryNamesEnums.GET_DRAW_REQUEST_SERVICE_ORDERS,
        { projectId, drawRequestId },
      ]);
    }
    if (serviceOrderId) {
      queryClient.invalidateQueries([
        QueryNamesEnums.GET_PROJECT_SERVICE_ORDER_BY_ID,
        { projectId, serviceOrderId },
      ]);
    }
  };
};

const DOCUMENT_ADDED_TIMEOUT_MS = 5000;
let documentAddedCount = 0;
let documentAddedTimeout = null;
let isInvalidated = false;

export const useDocumentUploadedInvalidation = () => {
  const queryClient = useQueryClient();

  const invalidateQueries = (params: {
    projectId: string;
    document_type: DocumentTypeEnum;
    is_progress_photo?: boolean;
    drawRequestId?: string;
    milestoneId?: string;
    inspectionId?: string;
    serviceOrderId?: string;
    milestoneGroupId?: string;
  }) => {
    const {
      projectId,
      document_type,
      is_progress_photo,
      drawRequestId,
      milestoneId,
      inspectionId,
      serviceOrderId,
      milestoneGroupId,
    } = params;

    const queriesToInvalidate = [];

    const addQuery = (queryKey: string, options: Record<string, unknown>) => {
      queriesToInvalidate.push([queryKey, options]);
    };

    switch (document_type) {
      case DocumentTypeEnum.PROJECT_IMPORT_TEMPLATE:
        addQuery(QueryNamesEnums.GET_PROJECT_DOCUMENTS, { projectId });
        break;
      case DocumentTypeEnum.THUMB:
        addQuery(QueryNamesEnums.GET_PROJECT, { projectId });
        break;
      default:
        addQuery(QueryNamesEnums.GET_PROJECT_DOCUMENTS, { projectId });
        addQuery(QueryNamesEnums.GET_PROJECT_MILESTONES, { projectId });
        addQuery(QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES, { projectId });
        if (is_progress_photo) addQuery(QueryNamesEnums.GET_PROJECT_PHOTOS, { projectId });
        if (drawRequestId) {
          if (is_progress_photo)
            addQuery(QueryNamesEnums.GET_DRAW_REQUEST_PHOTOS, { projectId, drawRequestId });
          addQuery(QueryNamesEnums.GET_DRAW_REQUEST_DOCUMENTS, { projectId, drawRequestId });
          addQuery(QueryNamesEnums.GET_DRAW_REQUEST_ITEM_CHECKLIST, { projectId, drawRequestId });
        }
        if (milestoneGroupId) {
          addQuery(QueryNamesEnums.GET_PROJECT_MILESTONE_GROUP_DOCS, {
            projectId,
            milestoneId: milestoneGroupId,
          });
        }
        if (milestoneId) {
          addQuery(QueryNamesEnums.GET_PROJECT_MILESTONE_DOCS, { projectId, milestoneId });
          if (drawRequestId)
            addQuery(QueryNamesEnums.GET_DRAW_REQUEST_MILESTONE_DOCS, {
              projectId,
              milestoneId,
              drawRequestId,
            });
        }
        if (inspectionId) {
          addQuery(QueryNamesEnums.GET_INSPECTION_DOCUMENTS, { projectId, inspectionId });
          addQuery(QueryNamesEnums.GET_INSPECTION_MILESTONE_DOCS, { projectId, inspectionId });
        }
        if (serviceOrderId) {
          addQuery(QueryNamesEnums.GET_SERVICE_ORDER_DOCUMENTS, { projectId, serviceOrderId });
        }
        break;
    }

    queriesToInvalidate.forEach(([queryKey, options]) => {
      queryClient.invalidateQueries(queryKey, {}, { ...options, cancelRefetch: true });
    });
  };

  return async (params: {
    projectId: string;
    document_type: DocumentTypeEnum;
    is_progress_photo?: boolean;
    drawRequestId?: string;
    milestoneId?: string;
    inspectionId?: string;
    serviceOrderId?: string;
    milestoneGroupId?: string;
  }) => {
    documentAddedCount += 1;
    if (documentAddedCount === 1 && !isInvalidated) {
      isInvalidated = true;
      invalidateQueries(params);
    } else if (documentAddedCount > 0) {
      if (documentAddedTimeout) {
        clearTimeout(documentAddedTimeout);
      }
      documentAddedTimeout = setTimeout(() => {
        invalidateQueries(params);
        documentAddedCount = 0;
        documentAddedTimeout = null;
        isInvalidated = false;
      }, DOCUMENT_ADDED_TIMEOUT_MS);
    }
  };
};

export const useAllQueriesInvalidation = () => {
  const { logout } = useLogout();
  const flags = useLaunchDarklyFlags();

  return () => {
    if (flags?.['ENG_8608_force_logout_upon_time_out_session']) {
      logout();
    }
  };
};
