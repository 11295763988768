import * as React from 'react';
import {
  DataGridPremium,
  GridColumnsPanel,
  GridEventListener,
  GridSlots,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid-premium';
import useDataTable from './useDataTable';
import { DrawRequestTypeEnum, TableKeyEnum } from '@interfaces';
import { TableStyle, TRIGGERHEIGHT } from './styled';
import Box from '@mui/material/Box';
import { colors, typography } from '@theme';
import { IconButton, Stack } from '@mui/material';
import CustomGridColumnsPanel from './components/GridColumnsPanel';
import Refresh from '@mui/icons-material/Refresh';

interface HeaderIcons {
  Component: React.ReactElement;
  handle?: () => void;
}

interface DataTableV3Interface {
  columns: any[];
  rows: any[];
  totals?: any;
  tableKey?: TableKeyEnum | DrawRequestTypeEnum;
  rowErrors?: any;
  columnErrors?: any;
  rowUpdateApi?: ({ id, json }) => void;
  headerLeft?: HeaderIcons[];
  headerRigh?: HeaderIcons[];
  withCustomColumnPicker?: boolean;
  onRowClick?: GridEventListener<'rowClick'>;
}

const CustomToolbar = ({
  headerLeft,
  headerRigh,
  clearSettings,
}: {
  headerLeft?: HeaderIcons[];
  headerRigh?: HeaderIcons[];
  clearSettings?: () => void;
}) => {
  return (
    <GridToolbarContainer
      sx={{
        pl: 2,
        pr: 2,
        pb: 1,
        pt: 1,
        display: 'flex',
        height: TRIGGERHEIGHT,
        justifyContent: 'space-between',
      }}
    >
      <Stack spacing={1} direction="row" alignItems="stretch">
        <GridToolbarQuickFilter
          sx={{
            pt: '4px',
            borderRadius: '2px',
            backgroundColor: colors.neutral.lightest,
          }}
          inputProps={{
            sx: {
              padding: 0,
              color: colors.text.medium,
              ...typography.body3,
            },
          }}
          variant="outlined"
          quickFilterParser={(searchInput: string) =>
            searchInput
              .split(',')
              .map((value) => value.trim())
              .filter((value) => value !== '')
          }
        />
        {Boolean(headerLeft?.length) && (
          <Stack direction="row" alignItems="center">
            {headerLeft?.map(({ Component }, index) => (
              <Box key={index.toString()}>{Component}</Box>
            ))}
          </Stack>
        )}
      </Stack>
      <Stack spacing={1} direction="row" flex={1} justifyContent="flex-end" alignItems="center">
        {Boolean(headerRigh?.length) && (
          <Stack direction="row" alignItems="center" sx={{ ml: 'auto' }}>
            {headerRigh?.map(({ Component }, index) => (
              <Box key={index.toString()}>{Component}</Box>
            ))}
          </Stack>
        )}
        <GridToolbarColumnsButton
          slotProps={{
            button: { color: 'secondary', variant: 'new' },
          }}
        />
        <GridToolbarDensitySelector
          slotProps={{
            button: { color: 'secondary', variant: 'new' },
          }}
        />
        <IconButton onClick={clearSettings}>
          <Refresh />
        </IconButton>
      </Stack>
    </GridToolbarContainer>
  );
};

const DataTableV3 = ({
  columns,
  rows,
  tableKey,
  rowUpdateApi,
  totals,
  rowErrors,
  columnErrors,
  headerLeft,
  headerRigh,
  withCustomColumnPicker,
  onRowClick,
}: DataTableV3Interface) => {
  const {
    isTablePinned,
    requiredToPin,
    tabelRef,
    apiRef,
    isCellEditable,
    processRowUpdate,
    saveCurrentSet,
    initialState,
    clearSettings,
    memoColumns,
    totalHeight,
  } = useDataTable({ tableKey, rows, rowErrors, columnErrors, rowUpdateApi, columns });

  return (
    <>
      <TableStyle
        ref={tabelRef}
        dynamicHeight={`${totalHeight}px`}
        requiredToPin={requiredToPin}
        isTablePinned={isTablePinned}
      >
        <DataGridPremium
          onRowClick={onRowClick}
          initialState={{ ...initialState }}
          autoHeight={!isTablePinned}
          isCellEditable={isCellEditable}
          processRowUpdate={processRowUpdate}
          onColumnWidthChange={() => saveCurrentSet()}
          onPinnedColumnsChange={() => saveCurrentSet()}
          onColumnOrderChange={() => saveCurrentSet(true)}
          onColumnVisibilityModelChange={() => saveCurrentSet()}
          onDensityChange={() => saveCurrentSet()}
          getRowClassName={(params) => `${params.row.id}`}
          cellSelection
          hideFooter
          apiRef={apiRef}
          columns={memoColumns}
          rows={[...rows, ...(isTablePinned ? [] : totals ? [totals] : [])]}
          slotProps={{
            toolbar: {
              headerLeft,
              headerRigh,
              clearSettings,
              anchorEl: tabelRef.current,
              placement: 'auto',
            },
            basePopper: {
              sx: {
                [' .MuiPaper-root']: {
                  maxHeight: '90vh',
                  flexDirection: 'column',
                },
              },
            },
          }}
          slots={{
            toolbar: CustomToolbar as GridSlots['toolbar'],
            columnsPanel: withCustomColumnPicker ? CustomGridColumnsPanel : GridColumnsPanel,
          }}
          pinnedRows={{
            bottom: totals ? [totals] : [],
          }}
        />
      </TableStyle>
    </>
  );
};

export default DataTableV3;
