import { PermissionsContext } from '@context';
import { DrawRequestTypeEnum, ExtendGridStatePremium, TableKeyEnum } from '@interfaces';
import { GridCellParams, GridState, GridTreeNode, useGridApiRef } from '@mui/x-data-grid-premium';
import { createColumnVisibleModel, diffObjects, mergeColumnVisibilityModel } from '@utils';
import { useState, useEffect, useCallback, useRef, useContext, useMemo } from 'react';
import { TRIGGERHEIGHT } from './styled';
import { useUpdateUiSettings } from '@hooks';
import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium';
import { defaultPersonalSetting } from '@constants';

const useDataTable = ({
  tableKey,
  rowErrors,
  columnErrors,
  rowUpdateApi,
  rows,
  columns,
}: {
  tableKey: TableKeyEnum | DrawRequestTypeEnum;
  rows: any[];
  rowErrors: any;
  columnErrors: any;
  columns: any[];
  rowUpdateApi?: ({ id, json }) => void;
}) => {
  const memoColumns = useMemo(() => columns, []);
  const { userSettings, updateTableSettings } = useUpdateUiSettings();
  const { permissions } = useContext(PermissionsContext);
  const apiRef = useGridApiRef();
  const tabelRef = useRef<HTMLElement>();
  const columnsBtnRef = useRef(null);
  const filtersBtnRef = useRef(null);
  const [isTablePinned, setIsTablePinned] = useState(false);
  const [initialState, setInitState] = useState<GridInitialStatePremium>(
    mergeColumnVisibilityModel(
      defaultPersonalSetting.tables[tableKey].hidden_columns || [],
      userSettings?.tableV3?.[tableKey],
      false,
    ),
  );
  const mainElement = useRef(document.querySelector('body'));

  const totalHeight = useMemo(() => {
    if (!apiRef.current.state) return 0;
    const { dimensions } = apiRef.current.state;
    return TRIGGERHEIGHT + dimensions.headersTotalHeight + dimensions.rowHeight * (rows.length + 1);
  }, [rows, apiRef.current.state]);

  const requiredToPin = useMemo(() => {
    if (!apiRef.current.state) return true;
    const maxHeight = mainElement.current.getBoundingClientRect().height;
    return totalHeight > maxHeight;
  }, [totalHeight, mainElement]);

  const checkPosition = useCallback(() => {
    if (tabelRef.current) {
      const rect = tabelRef.current.getBoundingClientRect();
      const isPinned = rect.top <= TRIGGERHEIGHT;
      setIsTablePinned(isPinned);
    }
  }, [tabelRef]);

  const isCellEditable = useCallback(
    (params: GridCellParams<any, any, any, GridTreeNode>) => {
      if (params.row.id === 'totals') return false;
      const state = apiRef.current.state as ExtendGridStatePremium;
      const errorForCurrentCell = state.additionalData.rowErrors?.[params.field]?.[params.row.id];
      const byMilestone = params.colDef['editableByMilestone']?.(params.row);
      return !!errorForCurrentCell || !!byMilestone;
    },
    [permissions, apiRef.current],
  );

  const processRowUpdate = useCallback(
    (updatedRow, originalRow) => {
      if (!rowUpdateApi) return updatedRow;
      const updateDelta = diffObjects(updatedRow, originalRow);
      if (Object.keys(updateDelta).length) {
        rowUpdateApi({
          id: updatedRow.id,
          json: { ...updateDelta },
        });
      }
      return { ...updatedRow, ...updateDelta };
    },
    [rowUpdateApi],
  );

  const saveCurrentSet = useCallback(
    (keepOrder?: boolean) => {
      apiRef.current.resetRowHeights();
      const { preferencePanel, ...currentState } = apiRef.current.exportState();
      if (!keepOrder) currentState.columns.orderedFields = [];
      updateTableSettings(tableKey as TableKeyEnum, currentState);
    },
    [updateTableSettings, apiRef.current],
  );

  useEffect(() => {
    apiRef.current.setState((state: GridState) => ({
      ...state,
      additionalData: {
        rowErrors,
        columnErrors,
      },
    }));
    apiRef.current.forceUpdate();
  }, [rowErrors, columnErrors]);

  const clearSettings = useCallback(() => {
    const currentState = apiRef.current.exportState();

    const dimensions = Object.keys(currentState.columns.dimensions).reduce((sum, key) => {
      const { width, ...value } = currentState.columns.dimensions[key];
      sum[key] = { ...value, ...(key !== 'name' ? { width: value.minWidth } : { flex: 1 }) };
      return sum;
    }, {});

    const clearState: GridInitialStatePremium = {
      columns: {
        columnVisibilityModel: createColumnVisibleModel(
          defaultPersonalSetting.tables[tableKey].hidden_columns || [],
        ),
        dimensions,
        orderedFields: [],
      },
      filter: {
        filterModel: {
          items: [],
        },
      },
      pinnedColumns: {
        left: [],
        right: [],
      },
      density: 'standard',
      rowGrouping: {
        model: [],
      },
    };
    apiRef.current.restoreState(clearState);
    setInitState(clearState);
    updateTableSettings(tableKey as TableKeyEnum, clearState);
  }, [apiRef.current, memoColumns, tableKey]);

  useEffect(() => {
    window.addEventListener('scroll', checkPosition);
    return () => {
      window.removeEventListener('scroll', checkPosition);
    };
  }, []);

  return {
    isTablePinned,
    tabelRef,
    setIsTablePinned,
    apiRef,
    isCellEditable,
    processRowUpdate,
    requiredToPin,
    initialState,
    settingsLoaded: userSettings,
    saveCurrentSet,
    columnsBtnRef,
    filtersBtnRef,
    clearSettings,
    memoColumns,
    totalHeight,
  };
};

export default useDataTable;
