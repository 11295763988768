import { createContext, useCallback, useContext, useMemo } from 'react';
import MilestoneColumns from './columnsV2';
import { PermissionsContext } from '@context';
import { HookInterface, TableContextInterface } from './interface';
import { getSortedByIndexMilestones, isCostTypeContingency, isCreditInRequest, isDrawRequest, isRestricted } from '@utils';
import {
  ContingencyModeEnum,
  IDrawRequest,
  IMilestoneColumn,
  IProject,
  PermissionNamesEnums,
  ProductionBuildCommonRowType,
  QueryNamesEnums,
} from '@interfaces';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import {
  getDrawRequest,
  getDrawRequestMilestoneListColumns,
  getProject,
  getProjectMilestoneListColumns,
} from '@globalService';
import { CommonRowType } from './columns/common';
import { MilestoneListColumnTypeV2 } from './columnsV2/common';

export const TableContext = createContext<TableContextInterface>({ getLineItemError: () => '' });

export type ApiUpdatePayloadV2 = {
  milestoneId: string;
  isModelBuilding?: boolean;
};

export const useMilestoneList = ({
  milestones,
  initColumns,
  patchMilestone,
  withColumnIndication,
  contingencyMode,
  withCredit,
  groupByFilter,
  totals,
}: HookInterface) => {
  const { projectId, requestId } = useParams();

  const projectQuery = useQuery<IProject, Error>(
    [QueryNamesEnums.GET_PROJECT, { projectId }],
    getProject.bind(this, projectId),
  );

  const drawRequestErrorsQuery = useQuery<IDrawRequest, Error>(
    [
      QueryNamesEnums.GET_DRAW_REQUEST,
      {
        projectId,
        drawRequestId: requestId,
        ...(groupByFilter ? { groupByKeys: groupByFilter } : {}),
        query: '{errors}',
      },
    ],
    getDrawRequest.bind(this, {
      projectId,
      drawRequestId: requestId,
      ...(groupByFilter ? { groupByKeys: groupByFilter } : {}),
      query: '{errors,type,credit_amount}',
    }),
    {
      enabled: Boolean(projectId && requestId),
    },
  );

  const milestoneColumnsQuery = requestId
    ? useQuery<IMilestoneColumn[], Error>(
        [QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES_COLUMNS, { projectId, requestId }],
        getDrawRequestMilestoneListColumns.bind(this, projectId, requestId),
        { enabled: withColumnIndication },
      )
    : useQuery<IMilestoneColumn[], Error>(
        [QueryNamesEnums.GET_PROJECT_MILESTONES_COLUMNS, { projectId }],
        getProjectMilestoneListColumns.bind(this, projectId),
        { enabled: withColumnIndication },
      );

  const { permissions } = useContext(PermissionsContext);

  const permittedColumns = useMemo(
    () =>
      initColumns.filter((item: string) => {
        const column = MilestoneColumns[item];
        if (!column) return false;
        if (!column.permissionKey) return true;
        return !isRestricted(column.permissionKey, permissions);
      }),
    [permissions, initColumns],
  );

  const columns = useMemo<Array<MilestoneListColumnTypeV2>>(
    () => permittedColumns.map((item: string) => MilestoneColumns[item]),
    [permittedColumns],
  );

  const apiUpdateV2 = useCallback(async ({ id, json }) => {
    await patchMilestone({
      milestone: id,
      json,
    });
  }, []);

  const sortedMilestones: CommonRowType[] | ProductionBuildCommonRowType[] = useMemo(
    () =>
      getSortedByIndexMilestones(
        milestones.map((ml) => ({
          ...ml,
          isEditableV2:
            ml.activeToEdit &&
            (!isCostTypeContingency(ml.cost_type) ||
              contingencyMode === ContingencyModeEnum.NORMAL ||
              ml.isRequestHistorical),
        })),
      ),
    [milestones, contingencyMode],
  );

  const drawRequestErrors = useMemo(() => {
    const transformedObject = {};
    if (!drawRequestErrorsQuery.data?.errors) return transformedObject;

    Object.keys(drawRequestErrorsQuery.data.errors).forEach((key) => {
      transformedObject[key] = {};
      drawRequestErrorsQuery.data.errors[key]?.forEach((item) => {
        transformedObject[key][item.id] = item.error;
      });
    });

    return transformedObject;
  }, [drawRequestErrorsQuery.data]);

  const showCreditButton = useMemo(
    () =>
      !isRestricted(PermissionNamesEnums.DRAWREQUESTS_CREDIT_APPROVE, permissions) &&
      withCredit &&
      isDrawRequest(drawRequestErrorsQuery.data) &&
      isCreditInRequest(sortedMilestones),
    [withCredit, drawRequestErrorsQuery, sortedMilestones, permissions],
  );

  const totalCalculation = useMemo(() => {
    return {
      id: 'totals',
      name: 'Totals',
      ...totals,
    };
  }, [totals]);

  return {
    columns,
    sortedMilestones,
    milestoneColumns: milestoneColumnsQuery.data || [],
    project: projectQuery.data,
    showCreditButton,
    apiUpdateV2,
    totalCalculation,
    drawRequestErrors,
  };
};
