import React, { FC } from 'react';
import { Typography, Stack, Tooltip } from '@mui/material';
import truncate from 'lodash/truncate';

import { dateFormatter } from '@utils';
import { useLaunchDarklyFlags } from '@context';
import { IProjectComment } from '@interfaces';
import { colors } from '@theme';
import { ArrowRightIcon } from '@svgAsComponents';
import { DotsVerticalMenu } from '@components';
import * as Controller from './controller';

const MAX_SENDER_TEXT_LENGTH = 25;
const MAX_RECIPIENT_TEXT_LENGTH = 15;

export const CommentHeader: FC<{
  comment: IProjectComment;
  source: string;
  showRecipient?: boolean;
  projectId?: string;
  showOptions?: boolean;
  handleEditCommentClick?: (comment: IProjectComment) => () => void;
}> = ({
  comment,
  source,
  showRecipient = false,
  projectId,
  showOptions = true,
  handleEditCommentClick,
}) => {
  const flags = useLaunchDarklyFlags();
  const { items } = Controller.useCommentHeader({
    comment,
    projectId,
    handleEditCommentClick,
  });

  const { message_updated_at, received_by_company, commenter, is_external_comment, created_at } =
    comment || {};
  const getRecipient = () => {
    if (!showRecipient || !received_by_company) return null;
    const receivedBy = received_by_company?.name;
    return (
      <Tooltip title={receivedBy?.length > MAX_RECIPIENT_TEXT_LENGTH ? receivedBy : ''}>
        <Stack direction="row" spacing={1} alignItems="center">
          <ArrowRightIcon size={12} />
          <Typography variant="labelSemiBold">
            {truncate(receivedBy, { length: MAX_RECIPIENT_TEXT_LENGTH }) || ''}
          </Typography>
        </Stack>
      </Tooltip>
    );
  };

  const headerText = () => {
    if (!comment?.commenter) return '';
    return (
      <Stack direction="row" spacing={1} alignItems="center">
        <Tooltip title={commenter?.length > MAX_SENDER_TEXT_LENGTH ? commenter : ''}>
          <Typography variant="labelSemiBold">
            {truncate(commenter, { length: MAX_SENDER_TEXT_LENGTH })}
          </Typography>
        </Tooltip>
        {!is_external_comment && getRecipient()}
      </Stack>
    );
  };

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Typography variant="label" data-cy={`${source}__comment__author`}>
        {headerText()}
      </Typography>
      <Stack direction="row" alignItems="center" spacing={0.5}>
        {message_updated_at && (
          <Typography
            variant="label"
            color={colors.text.medium}
            data-cy={`${source}__comment__date`}
          >
            (Edited)
          </Typography>
        )}
        <Typography variant="label" color={colors.text.medium} data-cy={`${source}__comment__date`}>
          {dateFormatter({ date: created_at, withTime: true })}
        </Typography>
        {flags?.['ENG_7278_edit_delete_comment'] && showOptions && !!items?.length && (
          <DotsVerticalMenu
            menuItems={items}
            anchorOriginHorizontal="right"
            transformOriginHorizontal="right"
            dataTestName="milestone_list__options__menu"
            size="small"
          />
        )}
      </Stack>
    </Stack>
  );
};

export default CommentHeader;
