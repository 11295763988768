import React, { FC } from 'react';
import { Stack, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { IProject, PermissionNamesEnums, PolicyListReasonsTypeEnum } from '@interfaces';
import {
  ButtonWithTooltip,
  ChecklistApproveModal,
  ConfirmationModal,
  LoanErrorsPopup,
} from '@components';
import * as Controller from './controller';
import { WithPermission } from '@utils';

const SubmitProjectButtons: FC<{ project: Partial<IProject> }> = ({ project }) => {
  const {
    submitProject,
    loading,
    checklistModalShow,
    setChecklistModalShow,
    checklistId,
    policyItems,
    handleActivateProject,
    handleApproveClose,
    confirmModal,
    confirmText,
    confirmCallback,
    buttonsDisabled,
    errors,
    setErrors,
    getButtonTooltipText,
    isLender,
  } = Controller.useSubmitProjectButtons({ project });

  return (
    <>
      <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
        {project.is_underwriting_enabled &&
          (project.is_budget_locked ? (
            <WithPermission permissionKey={PermissionNamesEnums.PROJECT_ONBOARDING}>
              <LoadingButton
                variant="text"
                color="error"
                onClick={() => {
                  submitProject(false);
                }}
                data-cy="project_checklist__return__button"
                loading={loading}
              >
                Return project
              </LoadingButton>
            </WithPermission>
          ) : (
            <WithPermission
              permissionKey={[
                PermissionNamesEnums.PROJECT_ONBOARDING,
                PermissionNamesEnums.PROJECT_BUDGET_EDIT,
              ]}
            >
              <ButtonWithTooltip
                variant="contained"
                color="primary"
                onClick={() => {
                  submitProject(true);
                }}
                tooltipText={getButtonTooltipText('submit')}
                dataTestName="project_checklist__submit__button"
                loading={loading}
                disabled={buttonsDisabled}
              >
                Submit project
              </ButtonWithTooltip>
            </WithPermission>
          ))}
        {isLender && (
          <WithPermission permissionKey={PermissionNamesEnums.PROJECT_ONBOARDING}>
            <ButtonWithTooltip
              variant="contained"
              color="primary"
              onClick={handleActivateProject}
              tooltipText={getButtonTooltipText('activate')}
              dataTestName="project_checklist__activate__button"
              loading={loading}
              disabled={buttonsDisabled}
            >
              Activate project
            </ButtonWithTooltip>
          </WithPermission>
        )}
      </Stack>

      {checklistModalShow && (
        <ChecklistApproveModal
          open={checklistModalShow}
          onClose={() => setChecklistModalShow(false)}
          onApproveClose={handleApproveClose}
          checklistItems={policyItems}
          checklistId={checklistId}
          projectId={project?.id}
          isRequiredReasons
          source="project_checklist__exception_reasons_modal"
          checklistType={PolicyListReasonsTypeEnum.PROJECT}
        />
      )}

      <ConfirmationModal
        open={confirmModal.isConfirmModalOpened}
        title="Confirmation"
        text=""
        maxWidth="xs"
        onClose={confirmModal.closeConfirmModal}
        confirmCallback={() =>
          confirmModal.confirmCallback({
            action: ({ value }) => confirmCallback({ value }),
          })
        }
        source="project_checklist_modal"
      >
        <Typography variant="body2" sx={{ mt: 2 }} align="center">
          {confirmText}
        </Typography>
      </ConfirmationModal>

      <LoanErrorsPopup projectId={project?.id} errors={errors} setErrors={setErrors} />
    </>
  );
};

export default SubmitProjectButtons;
