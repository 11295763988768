import { styled } from '@mui/system';
import { Box } from '@mui/material';
import { gridClasses } from '@mui/x-data-grid-premium';
import { colors } from '@theme';

interface TableStyleProps {
  isTablePinned: boolean;
  requiredToPin: boolean;
  dynamicHeight?: string;
}

export const TRIGGERHEIGHT = 60;

export const TableStyle = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== 'isTablePinned' && prop !== 'requiredToPin' && prop !== 'dynamicHeight',
})<TableStyleProps>(({ isTablePinned, dynamicHeight }) => ({
  width: '100%',
  height: isTablePinned ? `calc(100vh - 16px)` : dynamicHeight || 'auto',
  background: 'white',
  [`.${gridClasses.columnHeaderTitle}`]: {
    display: '-webkit-box',
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical',
  },
  [`.${gridClasses.menuIcon}`]: {
    position: 'absolute',
    right: 5,
    top: 2,
    bottom: 2,
    marginLeft: 0,
    alignItems: 'center',
    backgroundColor: 'rgba(247, 247, 247, 0.6)',
    [' .MuiSvgIcon-root ']: {
      fill: colors.neutral.darkest,
    },
  },
  [`.${gridClasses.iconButtonContainer}`]: {
    position: 'absolute',
    right: 20,
    top: 2,
    bottom: 2,
    marginLeft: 0,
    alignItems: 'center',
    backgroundColor: 'rgba(247, 247, 247, 0.6)',
    [' .MuiSvgIcon-root ']: {
      fill: colors.neutral.darkest,
    },
  },
  [`.${gridClasses['columnHeader--alignRight']}`]: {
    [`.${gridClasses.menuIcon}`]: {
      left: 5,
      right: 'auto',
    },
    [`.${gridClasses.iconButtonContainer}`]: {
      left: 20,
      right: 'auto',
    },
  },
  [`.${gridClasses['scrollbar--horizontal']}`]: {
    position: 'fixed',
    zIndex: 10000000,
  },
  [`.${gridClasses.cell}.icon`]: {
    borderRight: 0,
    backgroundColor: 'rgba(247, 247, 247, 0.6)',
  },
  [`.${gridClasses.row}.totals`]: {
    fontWeight: 'bold',
    [`.${gridClasses.cell}.icon button`]: {
      display: 'none',
    },
  },
  [`.${gridClasses.columnHeader}`]: {
    position: 'relative',
  },
  [`.${gridClasses.columnHeader}.number`]: {
    textAlign: 'right',
  },
  [`.${gridClasses.columnHeader}.icon`]: {
    borderRight: 0,
  },
  [`.${gridClasses.cell}.text`]: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    flexDirection: 'row',
    lineHeight: '1em',
    justifyContent: 'flex-end',
    [`&:not(.${gridClasses['cell--editable']})`]: {
      backgroundColor: 'rgba(247, 247, 247, 0.6)',
      color: colors.neutral.dark,
    },
  },
  [`.${gridClasses.cell}.number`]: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    flexDirection: 'row',
    lineHeight: '1em',
    justifyContent: 'flex-end',
    [`&:not(.${gridClasses['cell--editable']})`]: {
      backgroundColor: 'rgba(247, 247, 247, 0.6)',
      color: colors.neutral.dark,
    },
  },
  [`.${gridClasses.root}`]: {
    [`.${gridClasses.row}:hover`]: {
      backgroundColor: 'transparent',
    },
    [`.${gridClasses.row}.Mui-selected`]: {
      backgroundColor: 'transparent',
    },
    [`.${gridClasses.row}.Mui-selected:hover`]: {
      backgroundColor: 'transparent',
    },
  },
  [`.${gridClasses['root--densityCompact']}`]: {
    [`.${gridClasses.cell}`]: {
      fontSize: '0.8rem',
      ['.MuiTypography-root']: {
        fontSize: '0.8rem',
      },
    },
    [`.${gridClasses.columnHeaderTitle}`]: {
      fontSize: '0.75rem',
    },
  },
}));
