import React from 'react';
import { currencyFormatter } from '@utils';
import { Stack } from '@mui/material';
import { isFooterRow, MilestoneListColumnTypeV2, ProgressionSum } from './common';

const balanceToFinishInDraft: MilestoneListColumnTypeV2 = {
  field: 'balance_to_finish',
  renderCell: ({ row }) => {
    const progress = isFooterRow(row)
      ? -1 * +row.requested_amount + +row.requested_budget_change
      : -1 * +(row.requested_amount || 0) + +(row.requested_adjustments || 0);
    const sum = isFooterRow(row) ? row.balance_to_finish : (row.balance_to_finish || 0) - progress;

    return (
      <Stack lineHeight="14px" alignItems="flex-end">
        {currencyFormatter(sum, '')}
        <ProgressionSum sum={sum} progress={progress} />
      </Stack>
    );
  },
  headerAlign: 'right',
  headerClassName: 'number',
  cellClassName: 'number',
  headerName: 'Balance to finish ($)',
  description: 'Amount remaining of the revised scheduled value',
  editable: false,
  minWidth: 120,
};

export default balanceToFinishInDraft;
