import React, { FC } from 'react';
import { Stack, Theme, Typography, useMediaQuery } from '@mui/material';

import { HookState } from '@interfaces';
import {
  DocumentEditModal,
  DocumentSummaryList,
  Gallery,
  MessagePanel,
  PDFViewerNew,
  ReactTable,
  RightDrawer,
  ServiceMessage,
  TablePaginationNew,
} from '@components';
import * as Controller from './controller';
import { NoDataImage } from '@svgAsComponents';
import { colors } from '@theme';
import { IDocumentsWrapperProps } from '../../interface';
import { DownloadAllButton, SmartSummaryButton, UploadButton } from '../../components';
import { useDocumentsData } from '@hooks';

const DocumentsTable: FC<
  IDocumentsWrapperProps & {
    handleOpenDocumentUploader: () => void;
  }
> = ({
  source,
  isAllDocumentsTab,
  prId,
  drawRequestId,
  deleteDocument,
  requestDocuments,
  milestoneId,
  inspectionId,
  milestoneSubmitId,
  serviceOrderId,
  restoreDocument,
  handleOpenDocumentUploader,
}) => {
  const {
    state,
    isLoading,
    documents,
    projectName,
    tablePagination,
    tablePagination: { page, rowsPerPage, rowsPerPageOptions, onPageChange, onRowsPerPageChange },
    documentsCount,
  } = useDocumentsData({
    projectId: prId,
    drawRequestId,
    milestoneId,
    serviceOrderId,
    inspectionId,
  });

  const controller = Controller.useDocumentsTable({
    prId,
    requestId: drawRequestId,
    requestDocuments,
    milestoneId,
    milestoneSubmitId,
    inspectionId,
    serviceOrderId,
    source,
    isAllDocumentsTab,
    restoreDocument,
    isLoading,
    documents,
  });
  const {
    pdf,
    gallery,
    close,
    columns,
    rightMenu,
    isRightDrawerDocs,
    rightDrawerParams,
    docSummaryMenu,
    docSummaryParams,
    openAllDocsSummary,
    openPdfViewer,
    activeDocument,
    closeDocumentEditModal,
  } = controller;
  const smallMediaQuery = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  switch (state) {
    case HookState.ERROR: {
      return <ServiceMessage text="documents" />;
    }

    case HookState.FETCHING:
    case HookState.SUCCESS: {
      return (
        <Stack sx={{ flex: 1, marginBottom: isRightDrawerDocs ? 9 : 0 }}>
          {!isRightDrawerDocs && (
            <Stack direction="row" justifyContent="flex-end" sx={{ pb: 2, pr: 2 }} spacing={1.5}>
              {Boolean(state === HookState.SUCCESS && documents?.length) && (
                <SmartSummaryButton onClick={openAllDocsSummary} source={source} />
              )}
              {!isAllDocumentsTab && (
                <UploadButton onClick={handleOpenDocumentUploader} source={source} />
              )}
              <DownloadAllButton
                documents={documents}
                source={source}
                drawRequestId={drawRequestId}
                buttonLabel="Download all doc(s)"
              />
              <Stack alignItems="flex-end">
                <TablePaginationNew
                  page={page}
                  rowsPerPage={rowsPerPage}
                  rowsPerPageOptions={rowsPerPageOptions}
                  itemsCount={documentsCount}
                  onPageChange={onPageChange}
                  onRowsPerPageChange={onRowsPerPageChange}
                  source={source}
                />
              </Stack>
            </Stack>
          )}

          {isRightDrawerDocs && (
            <Stack direction="row" justifyContent="flex-end" alignItems="center">
              <TablePaginationNew
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={rowsPerPageOptions}
                itemsCount={documentsCount}
                onPageChange={onPageChange}
                onRowsPerPageChange={onRowsPerPageChange}
                source={source}
              />
            </Stack>
          )}

          {Boolean(state === HookState.FETCHING || documents?.length) && (
            <ReactTable
              data={documents}
              hideHeader={isRightDrawerDocs}
              columns={columns}
              footer={false}
              source={`${source}__list`}
            />
          )}
          {Boolean(
            state === HookState.SUCCESS &&
              !documents?.length &&
              ((!milestoneId && !milestoneSubmitId) || !isRightDrawerDocs),
          ) && (
            <Stack justifyContent="center" alignItems="center" sx={{ flexGrow: 1 }}>
              <NoDataImage size={200} />
              <Typography sx={{ mt: 4, textAlign: 'center' }} variant="body2SemiBold">
                No documents yet
              </Typography>
            </Stack>
          )}
          {isRightDrawerDocs && (
            <Stack
              direction="row"
              justifyContent="flex-end"
              sx={{
                mt: 'auto',
                position: 'fixed',
                bottom: 0,
                backgroundColor: colors.background.white,
                py: 4.5,
                px: 2,
                width: smallMediaQuery ? '100%' : '564px',
              }}
            >
              <DownloadAllButton
                documents={documents}
                source={source}
                drawRequestId={drawRequestId}
              />
              <UploadButton onClick={handleOpenDocumentUploader} source={source} />
              {Boolean(state === HookState.SUCCESS && documents?.length) && (
                <Stack ml={1}>
                  <SmartSummaryButton onClick={openAllDocsSummary} source={source} />
                </Stack>
              )}
            </Stack>
          )}
          {activeDocument && (
            <DocumentEditModal
              document={activeDocument}
              closeDocumentEditModal={closeDocumentEditModal}
              deleteDocument={deleteDocument}
              prId={prId}
            />
          )}
          {pdf && <PDFViewerNew pdfFile={pdf[0]} close={close} />}
          {gallery && <Gallery startIndex={0} close={close} files={gallery} />}
          <RightDrawer {...rightMenu}>
            <MessagePanel {...rightDrawerParams} source={`${source}__list__comments`} />
          </RightDrawer>
          <RightDrawer
            {...docSummaryMenu}
            drawerWidth="720px"
            right={isRightDrawerDocs ? '600px' : '0'}
          >
            <DocumentSummaryList
              {...docSummaryParams}
              projectName={projectName}
              rows={documents}
              source={`${source}__list__summary`}
              openPdfViewer={openPdfViewer}
              tablePagination={tablePagination}
              documentsCount={documentsCount}
            />
          </RightDrawer>
        </Stack>
      );
    }

    default:
      return null;
  }
};

export default DocumentsTable;
