import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Stack, Typography } from '@mui/material';

import {
  ButtonWithTooltip,
  CenteredStyledBox,
  ConfirmationModal,
  DocumentButton,
  DocumentUploaderWithForm,
  FilesUploader,
  LoadingSkeleton,
  TablePaginationNew,
} from '@components';
import { HookState, PopupTypeEnum, DocumentContentTypeEnum, VisibilityEnum } from '@interfaces';
import { getLinkedObjects, isInspectionService } from '@utils';
import * as Controller from './controller';
import { ServiceRelatedDocumentsProps } from './interface';
import { useLaunchDarklyFlags } from '@context';

const ServiceRelatedDocuments = ({
  drawRequestId,
  serviceOrderId,
  isServiceReports = false,
  uploadRefetchCallback,
  source,
  onDocumentClick,
  isEditable = true,
  activeDocumentId = '',
  serviceType,
  sx = {},
}: ServiceRelatedDocumentsProps) => {
  const flags = useLaunchDarklyFlags();
  const { projectId } = useParams();
  const controller = Controller.useServiceOrderDocuments({
    projectId,
    drawRequestId,
    serviceOrderId,
    isServiceReports,
  });

  const {
    state,
    handleOpenDocumentUploader,
    isFilesUploaderOpened,
    transloaditSignature,
    filesUploaderClose,
    documentTypes,
    refetchDocuments,
    documents,
    isLoading,
    restrictions,
    handleDelete,
    deleteModal,
    handleDeleteIconClick,
    selectedFileName,
    resultDocumentTypeId,
    tablePagination: { page, rowsPerPage, rowsPerPageOptions, onPageChange, onRowsPerPageChange },
    documentsCount,
  } = controller;

  return (
    <CenteredStyledBox
      sx={{
        overflow: 'unset',
        ...sx,
      }}
    >
      <Stack direction="row" justifyContent="space-between" sx={{ mb: 2 }}>
        <Typography variant="h3">
          {isServiceReports ? 'Result documents' : 'Related documents'}
        </Typography>
        <Stack direction="row" justifyContent="flex-end" spacing={1} alignItems="center">
          {documentsCount > 10 && (
            <Stack alignItems="flex-end">
              <TablePaginationNew
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={rowsPerPageOptions}
                itemsCount={documentsCount}
                onPageChange={onPageChange}
                onRowsPerPageChange={onRowsPerPageChange}
                source={source}
              />
            </Stack>
          )}
          <ButtonWithTooltip
            size="small"
            variant={isServiceReports && isInspectionService(serviceType) ? undefined : 'new'}
            color={isServiceReports && isInspectionService(serviceType) ? undefined : 'secondary'}
            onClick={() => {
              handleOpenDocumentUploader({ isProjectLevel: false });
            }}
            dataTestName={`${source}__add_other_documents__button`}
            disabled={!isEditable}
            tooltipText={!isEditable && 'Service is not editable'}
          >
            {isServiceReports || !isInspectionService(serviceType)
              ? 'Add document(s)'
              : 'Add other doc(s)'}
          </ButtonWithTooltip>
        </Stack>
      </Stack>
      {state === HookState.FETCHING && (
        <Stack spacing={2} direction="row">
          <LoadingSkeleton type="overviewBlock" />
        </Stack>
      )}
      {state === HookState.SUCCESS && (
        <>
          {!isServiceReports && isInspectionService(serviceType) && (
            <Typography variant="body1" sx={{ mb: 3 }}>
              Upload documents such as previous service reports. Documents will only be included
              with the current service.
            </Typography>
          )}
          <Box
            sx={{ display: 'flex', flexWrap: 'wrap' }}
            onClick={(e) => (isLoading ? e.stopPropagation() : null)}
          >
            {documents?.map((document) => (
              <Box sx={{ mb: 2, mr: 2 }} key={document.id}>
                <DocumentButton
                  document={document}
                  onButtonClick={() => onDocumentClick?.(document)}
                  dataTestName={`${source}__docs_button__button`}
                  handleDelete={isEditable ? handleDeleteIconClick : undefined}
                  handleDeleteProps={{
                    fileName: document.name,
                  }}
                  isActive={activeDocumentId === document.id}
                  width="175px"
                />
              </Box>
            ))}
          </Box>
        </>
      )}
      {transloaditSignature?.signature &&
        (!isServiceReports || flags?.['eng_8699_consistent_document_uploader']) && (
          <DocumentUploaderWithForm
            isOpen={isFilesUploaderOpened}
            documentTypes={documentTypes}
            closeUploader={() => {
              uploadRefetchCallback?.();
              filesUploaderClose();
            }}
            transloaditSignature={transloaditSignature}
            restrictions={restrictions}
            source={`${source}__documents`}
            hideDocumentTypeSelector={isServiceReports}
            documentTypeId={isServiceReports ? resultDocumentTypeId : null}
          />
        )}
      {transloaditSignature?.signature &&
        isServiceReports &&
        !flags?.['eng_8699_consistent_document_uploader'] && (
          <FilesUploader
            open={isFilesUploaderOpened}
            onClose={() => {
              uploadRefetchCallback();
              filesUploaderClose();
            }}
            transloaditSignature={transloaditSignature}
            restrictions={restrictions}
            metaDataDefault={{
              linked_objects: getLinkedObjects({ projectId, serviceOrderId }),
              content_type: DocumentContentTypeEnum.SERVICE_ORDER,
              document_type_id: resultDocumentTypeId,
              scope: VisibilityEnum.COMPANY,
            }}
            source={source}
          />
        )}
      <ConfirmationModal
        open={deleteModal.isConfirmModalOpened}
        title="Delete file"
        text={`You are about to delete the file "${selectedFileName}".`}
        onClose={deleteModal.closeConfirmModal}
        confirmCallback={() =>
          deleteModal.confirmCallback({
            action: async (args) => {
              await handleDelete(args);
              refetchDocuments();
            },
          })
        }
        type={PopupTypeEnum.ERROR}
        source={`${source}__documents__delete`}
        confirmButtonLabel="Delete"
      />
    </CenteredStyledBox>
  );
};

export default ServiceRelatedDocuments;
