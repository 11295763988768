import React, { ReactElement, useContext, useMemo } from 'react';
import { useQuery } from 'react-query';
import { Stack, Typography } from '@mui/material';
import { checkIsGeneralContractor, checkIsOwner, getTeamRole, isRequestDraft } from '@utils';
import { AuthContext } from '@context';
import { IProject, QueryNamesEnums, ICommentsPreview, TTeamRole, IDrawRequest } from '@interfaces';
import { getProject, getProjectDrawRequestsList } from '@globalService';
import { colors } from '@theme';
import { StyledLink } from '@components';

const requiredKeys = ['status', 'name', 'comments_preview'] as const;
type ProjectData = Pick<IProject, (typeof requiredKeys)[number]>;

interface ControllerInterface {
  showMessagePanel: boolean;
  projectCommentsPreview: ICommentsPreview;
  teamRole: TTeamRole;
  returnedRequestWarning: ReactElement;
}

export const useProjectMainInfo = (projectId: string): ControllerInterface => {
  const { user } = useContext(AuthContext);
  const teamRole = getTeamRole(user);

  const projectQuery = useQuery<ProjectData, Error>(
    [QueryNamesEnums.GET_PROJECT, { projectId }],
    getProject.bind(this, projectId),
  );

  const drawRequestsQuery = useQuery<{ results: IDrawRequest[] }, Error>(
    [QueryNamesEnums.GET_PROJECT_DRAW_REQUEST_LIST, { projectId }],
    getProjectDrawRequestsList.bind(this, projectId),
    { enabled: checkIsOwner(teamRole) },
  );

  const returnedRequest = useMemo(
    () =>
      drawRequestsQuery.data?.results?.find(
        (request) =>
          request?.is_resubmit &&
          isRequestDraft(request?.status) &&
          request?.waits_current_user_approval,
      ),
    [drawRequestsQuery.data],
  );

  const showMessagePanel = useMemo(() => !checkIsGeneralContractor(teamRole), [teamRole]);

  const returnedRequestWarning = useMemo(() => {
    if (!returnedRequest) {
      return null;
    }

    return (
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: '56px', color: colors.status.orange.medium }}
        spacing={1}
      >
        <Typography variant="body2" sx={{ color: colors.status.orange.medium }}>
          This request was returned for editing by the lender and now is pending your response.
        </Typography>
        <StyledLink
          data-cy="returned_request_banner__link_to_edit_request"
          to={`/projects/${projectId}/requests/${returnedRequest?.id}/submission`}
        >
          Edit request
        </StyledLink>
      </Stack>
    );
  }, [projectId, returnedRequest?.id]);

  return {
    showMessagePanel,
    teamRole,
    projectCommentsPreview: projectQuery.data?.comments_preview,
    returnedRequestWarning,
  };
};
