import React, { FC, ReactElement } from 'react';
import { Stack, Typography } from '@mui/material';

import { CSVBoxSheetsEnum, HookState, PermissionNamesEnums } from '@interfaces';
import { colors } from '@theme';
import {
  CheckboxIconRoundedChecked,
  CheckboxIconRoundedDefault,
  ChevronRightIcon,
  FillTableImage,
  UnitImage,
} from '@svgAsComponents';
import {
  CSVUploader,
  IsProductionBuildCheckbox,
  LoadingSkeleton,
  ServiceMessage,
  StyledBox,
} from '@components';
import { useBudgetCreation } from './controller';
import { CSVSampleLinks, CSVSampleLinksNames } from '@constants';
import BudgetTable from '../BudgetTable';
import { WithPermission } from '@utils';
import { ChooseNamingModal } from '../Modals/ChooseNamingModal';
import { ChooseBudgetViewModal } from '../Modals/ChooseBudgetViewModal/';
import { useLaunchDarklyFlags } from '@context';
import BudgetTableV2 from '../BudgetTableV2';

const ProductionBuildBudget: FC = () => {
  const {
    isTableFilled,
    isChooseNamingModalShown,
    setIsChooseNamingModalShown,
    modelUnitSectionClick,
    verticalCostCSVUrl,
    horizontalMilestonesCount,
    verticalMilestonesCount,
    showTable,
    isBudgetEditable,
    isChooseBudgetViewModalShown,
    setIsChooseBudgetViewModalShown,
    dynamicColumns,
    isCreatingByModelsFilled,
    state,
    hasUnfilledName,
    isUploadingVerticalDisabled,
    isUploadingHorizontalDisabled,
    showBudgetUploadButtons,
  } = useBudgetCreation();
  const flags = useLaunchDarklyFlags();

  switch (state) {
    case HookState.ERROR: {
      return <ServiceMessage text="Budget creation" />;
    }

    case HookState.SUCCESS:
    case HookState.FETCHING: {
      return (
        <>
          {state === HookState.FETCHING ? (
            <StyledBox>
              <LoadingSkeleton type="twoBlocks" />
            </StyledBox>
          ) : (
            <WithPermission permissionKey={PermissionNamesEnums.PROJECT_ONBOARDING}>
              {isBudgetEditable && !isTableFilled && (
                <Stack mb={2}>
                  <IsProductionBuildCheckbox isDefaultProdBuildProject />
                </Stack>
              )}
              {(showBudgetUploadButtons || !flags?.['ENG-8622-new-phb-view']) && (
                <Stack
                  direction="row"
                  spacing={1}
                  sx={{
                    width: '100%',
                    ' > div': { width: '100%', height: '80px' },
                  }}
                  mb={2}
                >
                  <FillingTableItems
                    name="Budget naming & Structure"
                    isCompleted={isTableFilled && !hasUnfilledName}
                    image={<UnitImage />}
                    onClick={modelUnitSectionClick}
                    dataCy="project__budget__create__budget_structure__button"
                  />
                  <CSVUploader
                    sheetKey={CSVBoxSheetsEnum.IMPORT_PROJECT_BUDGET}
                    templateUrl={CSVSampleLinks.BUDGET_UPLOAD}
                    templateName={CSVSampleLinksNames.PRODUCTION__HORIZONTAL_BUDGET}
                    renderItem={(launch) => (
                      <FillingTableItems
                        name="Horizontal & Soft costs (Optional)"
                        isCompleted={Boolean(horizontalMilestonesCount)}
                        image={<FillTableImage width={55} height={35} />}
                        onClick={launch}
                        isDisabled={isUploadingHorizontalDisabled}
                        dataCy="project__budget__create__horizontal_costs__upload_budget_button"
                      />
                    )}
                  />
                  <CSVUploader
                    key={verticalCostCSVUrl}
                    sheetKey={CSVBoxSheetsEnum.IMPORT_BUDGET_PHB}
                    templateUrl={verticalCostCSVUrl}
                    templateName={CSVSampleLinksNames.PRODUCTION__VERTICAL_BUDGET}
                    dynamicColumns={dynamicColumns}
                    phbTagKey={isCreatingByModelsFilled ? 'model_name' : 'unit_name'}
                    renderItem={(launch) => (
                      <FillingTableItems
                        name="Vertical costs"
                        isCompleted={Boolean(verticalMilestonesCount)}
                        image={<FillTableImage width={55} height={35} />}
                        onClick={launch}
                        isDisabled={isUploadingVerticalDisabled}
                        dataCy="project__budget__create__vertical_costs__upload_budget_button"
                      />
                    )}
                  />
                </Stack>
              )}
              <ChooseNamingModal
                open={isChooseNamingModalShown}
                onClose={() => setIsChooseNamingModalShown(false)}
              />
              <ChooseBudgetViewModal
                open={isChooseBudgetViewModalShown}
                onClose={() => setIsChooseBudgetViewModalShown(false)}
                isCreatedByModels={isCreatingByModelsFilled}
              />
            </WithPermission>
          )}
          {showTable || state === HookState.FETCHING ? (
            <>{flags?.['ENG-8622-new-phb-view'] ? <BudgetTableV2 /> : <BudgetTable />}</>
          ) : (
            <Stack
              justifyContent="center"
              alignItems="center"
              padding={8}
              sx={{ flexGrow: 1, backgroundColor: colors.background.white }}
            >
              <FillTableImage />
              <Typography
                variant="h2"
                mt={8}
                textAlign="center"
                data-cy="project__budget__create__fill_info__label"
              >
                To see the whole budget, please fill all the information <br /> above.
              </Typography>
            </Stack>
          )}
        </>
      );
    }

    default:
      return null;
  }
};

const FillingTableItems: FC<{
  name: string;
  isCompleted: boolean;
  image: ReactElement;
  onClick: () => void;
  isDisabled?: boolean;
  dataCy?: string;
}> = ({ name, isCompleted, image, onClick, isDisabled, dataCy }) => (
  <Stack
    direction="row"
    justifyContent="space-between"
    alignItems="center"
    paddingX={1}
    paddingY={2.5}
    sx={{
      flexGrow: 1,
      backgroundColor: colors.background.white,
      cursor: !isDisabled && 'pointer',
      height: '100%',
    }}
    onClick={!isDisabled ? onClick : null}
    data-cy={dataCy}
  >
    {image}
    <Typography
      variant="h4"
      sx={{ color: isDisabled ? colors.neutral.medium : colors.text.heading }}
    >
      {name}
    </Typography>
    {isCompleted ? (
      <CheckboxIconRoundedChecked
        color={isDisabled ? colors.neutral.medium : colors.status.success.medium}
      />
    ) : (
      <CheckboxIconRoundedDefault
        color={isDisabled ? colors.neutral.medium : colors.neutral.lighter}
      />
    )}
    <ChevronRightIcon color={isDisabled ? colors.neutral.medium : colors.icons.gray} />
  </Stack>
);
export default ProductionBuildBudget;
