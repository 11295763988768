import { useEffect, useMemo } from 'react';
import { ControllerInterface } from './interface';
import { useStringFieldModel } from '@models';
import isNumber from 'lodash/isNumber';

export const useEditFundingSourceProportionModal = ({
  sourceName1,
  sourceName2,
  value1,
  value2,
  fraction1,
  fraction2,
}: {
  sourceName1: string;
  sourceName2: string;
  value1: string;
  value2: string;
  fraction1: string;
  fraction2: string;
}): ControllerInterface => {
  const nameField1 = useStringFieldModel({
    initValue: sourceName1,
    withProgressCheck: true,
    validationRule: (value) => Boolean(value?.trim()),
  });

  const amountField1 = useStringFieldModel({
    initValue: value1 || '',
    withProgressCheck: true,
  });

  const nameField2 = useStringFieldModel({
    initValue: sourceName2,
    withProgressCheck: true,
    validationRule: (value) => Boolean(value?.trim()),
  });

  const amountField2 = useStringFieldModel({
    initValue: value2 || '',
    withProgressCheck: true,
  });

  const fractionField1 = useStringFieldModel({
    initValue: fraction1 || '',
    validateOnChange: true,
    validationRule: (value) => isNumber(+value) && +value >= 0 && +value <= 100,
    initError: 'The value is outside the valid range of 0 to 100%',
  });

  const fractionField2 = useStringFieldModel({
    initValue: fraction2 || '',
    validateOnChange: true,
    validationRule: (value) => isNumber(+value) && +value >= 0 && +value <= 100,
    initError: 'The value is outside the valid range of 0 to 100%',
  });

  const tooltipText = useMemo(
    () =>
      !fractionField1.isValid || !fractionField2.isValid ? 'Please enter the valid values' : '',
    [fractionField1.isValid, fractionField2.isValid],
  );

  useEffect(() => {
    fractionField1.setAsFloat(+fraction1);
    fractionField2.setAsFloat(+fraction2);
  }, [fraction1, fraction2]);

  return {
    nameField1,
    nameField2,
    amountField1,
    amountField2,
    fractionField1,
    fractionField2,
    tooltipText,
  };
};
