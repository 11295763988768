import React, { useContext } from 'react';
import { Grid2, Stack } from '@mui/material';
import { useParams } from 'react-router-dom';
import { isMobileOnly } from 'react-device-detect';

import {
  MessagePanelButton,
  ProjectHeader,
  ProjectSettingsOptionsMenu,
  ProjectWarning,
  WatchProject,
} from '@components';
import * as Controller from './controller';
import { LenderChecklist, ProjectScores, ServicesButton } from '..';
import { PermissionNamesEnums, ToolTipLineVariantEnum } from '@interfaces';
import { checkIsLender, checkIsOwner, WithPermission } from '@utils';
import { SettingsContext } from '@context';

const ProjectMainInfo = ({ handleRightDrawerOpenerClick }) => {
  const { isCurrentProjectArchived } = useContext(SettingsContext);
  const { projectId } = useParams();
  const { showMessagePanel, teamRole, projectCommentsPreview, returnedRequestWarning } =
    Controller.useProjectMainInfo(projectId);

  return (
    <>
      {returnedRequestWarning && (
        <ProjectWarning
          innerElement={returnedRequestWarning}
          variant={ToolTipLineVariantEnum.NOTIFICATION}
        />
      )}
      <Grid2
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{ pt: 3, pb: 1, px: 2 }}
        spacing={2}
      >
        <Grid2 sx={{ width: { md: 'auto', xs: '100%' } }}>
          <ProjectHeader />
        </Grid2>

        <Grid2 sx={{ width: { md: 'auto', xs: '100%' } }}>
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            spacing={1}
            alignItems={{ xs: 'flex-start', md: 'center' }}
            justifyContent="flex-start"
          >
            {!isCurrentProjectArchived && (
              <WithPermission permissionKey={PermissionNamesEnums.SCORES_VIEW}>
                <ProjectScores />
              </WithPermission>
            )}
            {!isMobileOnly && (
              <>
                <LenderChecklist />
                {checkIsLender(teamRole) && <WatchProject />}
                <Stack direction="row" spacing={1} alignItems="center">
                  {showMessagePanel && (
                    <MessagePanelButton
                      commentsPreview={projectCommentsPreview}
                      handleRightDrawerOpenerClick={handleRightDrawerOpenerClick}
                      source="project"
                    />
                  )}
                  <ServicesButton withUserFocus={checkIsOwner(teamRole)} />
                  <ProjectSettingsOptionsMenu />
                </Stack>
              </>
            )}
          </Stack>
        </Grid2>
      </Grid2>
    </>
  );
};

export default ProjectMainInfo;
