import { Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';
import { format } from 'date-fns';
import { SelectChangeEvent } from '@mui/material';
import find from 'lodash/find';

import {
  EnumTypeForList,
  ILoanInfo,
  IProject,
  PaymentConfiguration,
  QueryNamesEnums,
} from '@interfaces';
import { calculateFraction, getReasonText, isCreatedProject, validationShareRule } from '@utils';
import {
  DateFieldModel,
  DropdownFieldModel,
  StringFieldModel,
  useDropdownFieldModel,
  useStringFieldModel,
} from '@models';
import { updateProjectFields } from '@globalService';
import { useEditPaymentConfigurationType, useProjectAndLoanDates } from '@hooks';
import { SettingsContext, useLaunchDarklyFlags } from '@context';

interface ControllerInterface {
  external_id: StringFieldModel;
  loc_commitment: StringFieldModel;
  prefunding_cost: StringFieldModel;
  post_funding_construction_budget: StringFieldModel;
  construction_holdback: StringFieldModel;
  construction_holdback_fraction: StringFieldModel;
  borrower_equity: StringFieldModel;
  borrower_equity_fraction: StringFieldModel;
  completionDateReasonsList: string[];
  legal_entity: StringFieldModel;
  handleSubmitClick: () => Promise<boolean>;
  isSubmitting: boolean;
  isEditable: boolean;
  isUpdated: boolean;
  exitPath: string;
  isDisabled: boolean;
  projectDates: {
    [key: string]: DateFieldModel;
  };
  handleCompletionDateReasonChange: (event: SelectChangeEvent<string[]>) => void;
  isCompletionDateReasonsValid: boolean;
  completionDateReasons: string[];
  loan_status: DropdownFieldModel;
  loanStatusesList: EnumTypeForList[];
  loan_type: DropdownFieldModel;
  loanTypesList: EnumTypeForList[];
  loan: ILoanInfo;
  showBudgetValues: boolean;
  configurationType: PaymentConfiguration;
  setConfigurationType: Dispatch<SetStateAction<PaymentConfiguration>>;
}

export const useLoanDetails = ({
  project,
  isAllProjectDetailsDisabled,
}: {
  project: IProject;
  isAllProjectDetailsDisabled: boolean;
}): ControllerInterface => {
  const flags = useLaunchDarklyFlags();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { settings } = useContext(SettingsContext);
  const { loan_servicing_statuses, loan_types } = settings?.display || {};
  const { configurationType, setConfigurationType } = useEditPaymentConfigurationType();

  useEffect(() => {
    if (project?.payment_configuration_type) {
      setConfigurationType(project?.payment_configuration_type);
    }
  }, [project]);

  const external_id = useStringFieldModel({
    initValue: project.loan?.external_id,
    validationRule: (value) =>
      flags?.['ENG_8524_optional_fields_on_create_project'] || Boolean(value?.trim()),
    validateOnChange: true,
  });

  const loc_commitment = useStringFieldModel({
    initValue: project.loan?.loc_commitment?.toString() || '0',
    validateOnChange: true,
  });

  const loan_status = useDropdownFieldModel({
    initValue: find(loan_servicing_statuses, { name: project.loan?.servicing_status }) || null,
    validateOnChange: true,
  });

  const loan_type = useDropdownFieldModel({
    initValue: find(loan_types, { name: project.loan?.type }) || null,
    validateOnChange: true,
  });

  const prefunding_cost = useStringFieldModel({
    initValue: project.loan?.prefunding_cost?.toString() || '0',
    validateOnChange: true,
  });

  const construction_holdback = useStringFieldModel({
    initValue: project.loan?.construction_holdback?.toString() || '0',
    validateOnChange: true,
  });

  const borrower_equity = useStringFieldModel({
    initValue: project.loan?.borrower_equity?.toString() || '0',
    validateOnChange: true,
  });

  const legal_entity = useStringFieldModel({
    initValue: project.legal_entity || '',
  });

  // dates block
  const {
    projectDates,
    completionDateReasonsList,
    handleCompletionDateReasonChange,
    isCompletionDateReasonsValid,
    completionDateReasons,
    isDatesValid,
  } = useProjectAndLoanDates({
    project,
  });
  const {
    funding_date,
    estimated_start_date,
    original_completion_date,
    estimated_completion_date,
    maturity_date,
    extended_maturity_date,
  } = projectDates;

  const initConstructionHoldbackFraction = useMemo(
    () =>
      project.loan
        ? calculateFraction(
            project.loan.construction_holdback,
            project.loan.post_funding_construction_budget,
          ).toString()
        : '100',
    [project.loan],
  );
  const construction_holdback_fraction = useStringFieldModel({
    initValue: initConstructionHoldbackFraction,
    validationRule: validationShareRule,
    validateOnChange: true,
    initError: 'The value is outside the valid range of 0 to 100%',
  });

  const initBorrowerFraction = useMemo(
    () =>
      project.loan
        ? calculateFraction(
            project.loan.borrower_equity,
            project.loan.post_funding_construction_budget,
          ).toString()
        : '0',
    [project.loan],
  );
  const borrower_equity_fraction = useStringFieldModel({
    initValue: initBorrowerFraction,
    validationRule: validationShareRule,
    validateOnChange: true,
    initError: 'The value is outside the valid range of 0 to 100%',
  });

  const post_funding_construction_budget = useStringFieldModel({
    initValue: project.loan?.post_funding_construction_budget?.toString() || '0',
    validationRule: (value) => Boolean(+value),
    validateOnChange: true,
  });

  const projectLoanMutation = useMutation<
    Response,
    Error,
    { projectId: string; json: Partial<IProject> }
  >(updateProjectFields, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT, { projectId: project.id }]);
      queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_FUNDS, { projectId: project.id }]);
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });

  const isProviderItemsChanged = useMemo(
    () =>
      [
        post_funding_construction_budget,
        construction_holdback,
        construction_holdback_fraction,
        borrower_equity,
        borrower_equity_fraction,
      ].some((field) => field.isChanged),
    [
      post_funding_construction_budget.isChanged,
      construction_holdback.isChanged,
      construction_holdback_fraction.isChanged,
      borrower_equity.isChanged,
      borrower_equity_fraction.isChanged,
    ],
  );

  const handleSubmitClick = useCallback(async () => {
    const isExternalIdValid = external_id.validate();
    const isMaturityDateValid = maturity_date.validate();
    const isFundingDateValid = funding_date.validate();
    const isEstimatedCompletionDateValid = estimated_completion_date.validate();
    const isOriginalCompletionDateValid = original_completion_date.validate();
    const isEstimatedStartDateValid = estimated_start_date.validate();
    const isPostFundingConstructionBudgetValid = post_funding_construction_budget.validate();
    const isConstructionHoldbackFractionValid = construction_holdback_fraction.validate();
    const isBorrowerEquityFractionValid = borrower_equity_fraction.validate();

    const constructionHoldbackFraction = calculateFraction(
      construction_holdback.value,
      post_funding_construction_budget.value,
    );
    const borrowerEquityFraction = (100 - constructionHoldbackFraction).toFixed(12);

    const isFieldsValid = [
      isExternalIdValid,
      isMaturityDateValid,
      isFundingDateValid,
      isEstimatedCompletionDateValid,
      isOriginalCompletionDateValid,
      isEstimatedStartDateValid,
      isPostFundingConstructionBudgetValid,
      isConstructionHoldbackFractionValid,
      isBorrowerEquityFractionValid,
    ].every(Boolean);

    if (!isFieldsValid) return false;

    await projectLoanMutation.mutateAsync({
      projectId: project.id,
      json: {
        ...(!isAllProjectDetailsDisabled
          ? {
              ...(isProviderItemsChanged
                ? {
                    construction_holdback_rate: +constructionHoldbackFraction,
                    borrower_equity_rate: +borrowerEquityFraction,
                  }
                : {}),
              loan: {
                id: project.loan?.id,
                ...(external_id.isChanged ? { external_id: external_id.value } : {}),
                ...(loan_status.isChanged ? { servicing_status: loan_status.value?.name } : {}),
                ...(loan_type.isChanged ? { type: loan_type.value?.name } : {}),
                ...(funding_date.isChanged
                  ? { funding_date: format(funding_date.value, 'yyyy-MM-dd') }
                  : {}),
                ...(maturity_date.isChanged
                  ? { maturity_date: format(maturity_date.value, 'yyyy-MM-dd') }
                  : {}),
                ...(extended_maturity_date.isChanged
                  ? {
                      extended_maturity_date: extended_maturity_date.value
                        ? format(extended_maturity_date.value, 'yyyy-MM-dd')
                        : null,
                    }
                  : {}),
                ...(loc_commitment.isChanged ? { loc_commitment: +loc_commitment.value } : {}),
                ...(prefunding_cost.isChanged ? { prefunding_cost: +prefunding_cost.value } : {}),
                ...(construction_holdback.isChanged
                  ? { construction_holdback: +construction_holdback.value }
                  : {}),
                ...(borrower_equity.isChanged ? { borrower_equity: +borrower_equity.value } : {}),
                ...(post_funding_construction_budget.isChanged
                  ? { post_funding_construction_budget: +post_funding_construction_budget.value }
                  : {}),
              },
            }
          : {}),
        ...(estimated_completion_date.isChanged
          ? { estimated_completion_date: format(estimated_completion_date.value, 'yyyy-MM-dd') }
          : {}),
        ...(completionDateReasons?.length && {
          estimated_completion_date_change_reason: getReasonText(completionDateReasons, ''),
        }),
        ...(original_completion_date.isChanged
          ? {
              original_completion_date: format(original_completion_date.value, 'yyyy-MM-dd'),
            }
          : {}),
        ...(estimated_start_date.isChanged
          ? { estimated_start_date: format(estimated_start_date.value, 'yyyy-MM-dd') }
          : {}),
        ...(legal_entity.isChanged ? { legal_entity: legal_entity.value } : {}),
        payment_configuration_type: configurationType,
      },
    });

    return true;
  }, [
    external_id.value,
    loan_status.value,
    loan_type.value,
    project,
    funding_date.value,
    estimated_start_date,
    maturity_date.value,
    extended_maturity_date.value,
    original_completion_date.value,
    estimated_completion_date.value,
    loc_commitment.value,
    construction_holdback.value,
    borrower_equity.value,
    prefunding_cost.value,
    post_funding_construction_budget.value,
    configurationType,
    completionDateReasons,
  ]);

  const isEditable = useMemo(() => isCreatedProject(project.status), [project.status]);

  const isUpdated = useMemo(
    () =>
      [
        external_id,
        loan_status,
        loan_type,
        estimated_start_date,
        maturity_date,
        extended_maturity_date,
        funding_date,
        loc_commitment,
        prefunding_cost,
        original_completion_date,
        estimated_completion_date,
        legal_entity,
      ].some((field) => field.isChanged) ||
      configurationType !== project.payment_configuration_type ||
      isProviderItemsChanged ||
      completionDateReasons.length > 0,
    [
      external_id.isChanged,
      loan_status.isChanged,
      loan_type.isChanged,
      maturity_date.isChanged,
      extended_maturity_date.isChanged,
      funding_date.isChanged,
      loc_commitment.isChanged,
      prefunding_cost.isChanged,
      estimated_completion_date.isChanged,
      estimated_start_date.isChanged,
      original_completion_date.isChanged,
      legal_entity.isChanged,
      configurationType,
      project.payment_configuration_type,
      completionDateReasons,
      isProviderItemsChanged,
    ],
  );

  const isDisabled = useMemo(
    () =>
      !isDatesValid ||
      [
        external_id,
        loc_commitment,
        prefunding_cost,
        post_funding_construction_budget,
        construction_holdback,
        construction_holdback_fraction,
        borrower_equity,
        borrower_equity_fraction,
      ].some((field) => !field.isValid),
    [
      external_id,
      loc_commitment,
      prefunding_cost,
      post_funding_construction_budget,
      construction_holdback,
      construction_holdback_fraction,
      borrower_equity,
      borrower_equity_fraction,
      isDatesValid,
    ],
  );

  const exitPath = useMemo(() => `/projects/${project?.id}/overview`, [project?.id]);

  const showBudgetValues = useMemo(() => isCreatedProject(project?.status), [project?.status]);

  return {
    external_id,
    loc_commitment,
    prefunding_cost,
    post_funding_construction_budget,
    construction_holdback,
    construction_holdback_fraction,
    borrower_equity,
    borrower_equity_fraction,
    legal_entity,
    handleSubmitClick,
    isSubmitting: projectLoanMutation.isLoading,
    isEditable,
    isUpdated,
    exitPath,
    isDisabled,
    completionDateReasonsList,
    projectDates,
    handleCompletionDateReasonChange,
    isCompletionDateReasonsValid,
    completionDateReasons,
    loan_status,
    loan_type,
    loanStatusesList: loan_servicing_statuses,
    loanTypesList: loan_types,
    loan: project.loan,
    showBudgetValues,
    configurationType,
    setConfigurationType,
  };
};
