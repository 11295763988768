import { dataProvider, dataProviderV2 } from '@api';
import {
  CommonInspectionPayload,
  CreateAgencyPayload,
  CreateInspectionPayload,
  IDocument,
  IInspection,
  IInspectionAgency,
  IMilestone,
  PatchInspectionListItemParam,
  PatchInspectionMSGroupParam,
  UpdateInspectionPayload,
} from '@interfaces';
import {
  cancelProjectInspection,
  drawRequestInspectionsList,
  inspectionAgencies,
  inspectionAgenciesByProject,
  inspectionAgenciesWithQuery,
  inspectionMilestoneDocuments,
  inspectionsList,
  projectInspection,
  projectInspectionGroupById,
  projectInspectionMilestone,
  projectInspectionsList,
  projectInspectionsMilestones,
  scheduleProjectInspection,
} from '../paths';
import pickBy from 'lodash/pickBy';

export const getDrawRequestInspectionsList = async ({
  projectId,
  drawRequestId,
  query,
  stringQueryParams,
}: {
  projectId: string;
  drawRequestId: string;
  query?: string;
  stringQueryParams?: string;
}): Promise<{ results: IInspection[]; count: number }> =>
  dataProvider
    .get(drawRequestInspectionsList({ projectId, drawRequestId, query, stringQueryParams }))
    .json();

export const getProjectInspectionById = async ({
  projectId,
  inspectionId,
  query,
}: {
  projectId: string;
  inspectionId: string;
  query?: string;
}): Promise<IInspection> =>
  dataProvider.get(projectInspection({ projectId, inspectionId, query })).json();

export const getInspectionMilestones = async ({
  projectId,
  inspectionId,
  groupBy: group_by,
  limit = '999',
  filterKey,
  typeKeys,
  milestoneTags,
}: {
  projectId: string;
  inspectionId: string;
  groupBy?: string;
  limit?: string;
  filterKey?: string;
  milestoneTags?: string;
  typeKeys?: string;
}): Promise<{ results: IMilestone[] }> => {
  const notEmptyParams = pickBy({ group_by, limit }, Boolean);
  const queryFiltersParams = new URLSearchParams(notEmptyParams).toString();
  const filterParams = filterKey ? `${filterKey}=true&` : '';
  const milestoneTagsParams = milestoneTags ? `milestone_tags=${milestoneTags}&` : '';
  const typeKeysParams = typeKeys ? `&${typeKeys}&` : '';

  return dataProvider
    .get(
      projectInspectionsMilestones(
        projectId,
        inspectionId,
        queryFiltersParams,
        filterParams,
        milestoneTagsParams,
        typeKeysParams,
      ),
    )
    .json();
};

export const getProjectInspectionsList = async ({
  projectId,
  query,
  stringQueryParams,
}: {
  projectId: string;
  query?: string;
  stringQueryParams?: string;
}): Promise<{ results: IInspection[]; count: number }> =>
  dataProvider.get(projectInspectionsList(projectId, query, stringQueryParams)).json();

export const getInspectionAgenciesWithQuery = async ({
  restQlParams,
}: {
  restQlParams: string;
}): Promise<{ results: IInspectionAgency[] }> =>
  dataProvider.get(inspectionAgenciesWithQuery({ restQlParams })).json();

export const getInspectionAgenciesByProject = async (
  projectId,
): Promise<{ results: IInspectionAgency[] }> =>
  dataProvider.get(inspectionAgenciesByProject(projectId)).json();

// TODO remove this after ENG_5946_services and removing All Inspections tab
export const getInspectionsListNew = async ({
  queryURL,
}: {
  queryURL: string;
}): Promise<{ results: IInspection[]; count: number }> => {
  const stringQueryParams =
    queryURL +
    `&query={-milestones,-comments_preview,-photos,-report,-reports,-totals,-is_reallocation_enabled,-inspection_cadence,-investor_name,project{-teams},-draw_request_totals}`;
  return dataProvider.get(inspectionsList(stringQueryParams)).json();
};

export const getInspectionsListForAllProjects = async ({
  queryURL,
}: {
  queryURL: string;
}): Promise<{ results: IInspection[]; count: number }> => {
  return dataProvider.get(inspectionsList(queryURL)).json();
};

export const postInspectionToProject = async ({
  projectId,
  inspectionData,
}: CreateInspectionPayload): Promise<IInspection> =>
  dataProvider.post(projectInspectionsList(projectId), { json: inspectionData }).json();

export const patchInspectionToProject = async ({
  projectId,
  inspectionData,
}: UpdateInspectionPayload): Promise<IInspection> =>
  dataProvider
    .patch(projectInspection({ projectId, inspectionId: inspectionData.inspectionId }), {
      json: inspectionData,
    })
    .json();

export const patchInspectionMilestoneToProject = async ({
  projectId,
  inspectionId,
  milestoneId,
  json,
}: PatchInspectionListItemParam): Promise<Response> =>
  dataProvider
    .patch(projectInspectionMilestone(projectId, inspectionId, milestoneId), {
      json,
    })
    .json();

export const getInspectionMilestoneDocuments = async ({
  projectId,
  inspectionId,
  milestoneId,
  restQlParams,
  stringQueryParams,
}: {
  projectId: string;
  inspectionId: string;
  milestoneId: string;
  restQlParams?: string;
  stringQueryParams?: string;
}): Promise<{ results: IDocument[]; count: number }> =>
  dataProviderV2
    .get(
      inspectionMilestoneDocuments(
        projectId,
        inspectionId,
        milestoneId,
        restQlParams,
        stringQueryParams,
      ),
    )
    .json();

export const patchInspectionMSroup = ({
  projectId,
  inspectionId,
  group_by,
  json,
  milestoneId,
}: PatchInspectionMSGroupParam): Promise<IMilestone> => {
  return dataProvider
    .patch(projectInspectionGroupById({ projectId, inspectionId, group_by, milestoneId }), {
      json,
    })
    .json();
};

export const scheduleInspectionToProject = async ({
  projectId,
  inspectionId,
}: CommonInspectionPayload): Promise<Response> =>
  dataProvider.post(scheduleProjectInspection({ projectId, inspectionId })).json();

export const cancelInspection = async ({
  projectId,
  inspectionId,
}: CommonInspectionPayload): Promise<Response> =>
  dataProvider.post(cancelProjectInspection({ projectId, inspectionId })).json();

export const deleteInspection = async ({
  projectId,
  inspectionId,
}: CommonInspectionPayload): Promise<Response> =>
  dataProvider.delete(projectInspection({ projectId, inspectionId })).json();

export const createInspectionAgency = async ({
  company,
  display_name,
  service,
}: CreateAgencyPayload): Promise<IInspectionAgency> =>
  dataProvider
    .post(inspectionAgencies(), { json: { display_name, company, ...(service && { service }) } })
    .json();
