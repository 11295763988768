import { FundingSourceStatusEnum, FundingSourceTypeEnum } from '@interfaces';
import { ControllerInterface } from './interface';
import { useStringFieldModel } from '@models';

export const useEditFundingSourceModal = ({
  sourceName,
  value,
}: {
  sourceName: string;
  value: string;
}): ControllerInterface => {
  const sourceTypeOptions = [
    { value: FundingSourceTypeEnum.BORROWER_EQUITY, label: 'Borrower equity' },
    { value: FundingSourceTypeEnum.LOAN, label: 'This loan' },
  ];

  const sourceStatusOptions = [
    { value: FundingSourceStatusEnum.ACTIVE, label: 'Active' },
    { value: FundingSourceStatusEnum.HISTORICAL, label: 'Historical' },
  ];

  const nameField = useStringFieldModel({
    initValue: sourceName,
    withProgressCheck: true,
    validationRule: (value) => Boolean(value?.trim()),
  });

  const amountField = useStringFieldModel({
    initValue: value || '',
    withProgressCheck: true,
  });

  return {
    sourceTypeOptions,
    sourceStatusOptions,
    nameField,
    amountField,
  };
};
