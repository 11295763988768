import React, { FC, useCallback, useContext, useRef } from 'react';
import ReactDOM from 'react-dom';
import { useParams } from 'react-router-dom';
import { ViewerAPI } from 'react-photo-sphere-viewer';
import { Box, Skeleton, Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import { useFilesUploader, useImagePicker } from '@hooks';
import { ButtonWithTooltip, Gallery, PanoramaViewer, PhotoCard, UploaderWithForm } from '../index';
import { HookState, IDocument, IProofpoint, TransloaditTemplateEnum } from '@interfaces';
import { NoDataImage } from '@svgAsComponents';
import { usePhotoPanel } from './controller';
import { colors } from '@theme';
import { getPhotoContentType, getTooltipText, openFullScreenPanorama } from '@utils';
import { SettingsContext } from '@context';
import { TablePaginationNew } from '@components';

const PhotosPanel: FC<{ milestoneId: string; drawRequestId?: string; source: string }> = ({
  milestoneId,
  drawRequestId,
  source,
}) => {
  const { projectId } = useParams();
  const { isCurrentProjectArchived } = useContext(SettingsContext);
  const { gallery, open, close, startIndex } = useImagePicker();
  const smallMediaQuery = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const {
    isFilesUploaderOpened,
    transloaditSignature,
    uploadMedia,
    filesUploaderClose,
    restrictions,
  } = useFilesUploader();
  const {
    photos,
    state,
    canUploadPhoto,
    tablePagination: { page, rowsPerPage, rowsPerPageOptions, onPageChange, onRowsPerPageChange },
    documentsCount,
  } = usePhotoPanel({ milestoneId, drawRequestId });
  const [showPanorama, setShowPanorama] = React.useState(false);
  const ref = useRef<ViewerAPI>();

  const onPhotoClick = useCallback(
    (photo: IProofpoint | IDocument, index: number) => {
      if (photo.is_panorama) {
        openFullScreenPanorama(setShowPanorama, ref, photo);
      } else {
        open(photos, index);
      }
    },
    [photos, open, ref],
  );

  const uploader = () => {
    return (
      <UploaderWithForm
        isOpen={isFilesUploaderOpened}
        closeUploader={filesUploaderClose}
        transloaditSignature={transloaditSignature}
        restrictions={restrictions}
        source={source}
        projectId={projectId}
        drawRequestId={drawRequestId}
        showLineItems
        milestoneId={milestoneId}
      />
    );
  };

  return (
    <Stack flexWrap="wrap" sx={{ flex: 1 }}>
      {state === HookState.SUCCESS && !photos?.length && <NoContent />}
      {state === HookState.FETCHING && <LoadingSkeleton />}
      {showPanorama && <PanoramaViewer ref={ref} />}

      <Stack alignItems="flex-end">
        <TablePaginationNew
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={rowsPerPageOptions}
          itemsCount={documentsCount}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          source={source}
        />
      </Stack>

      <Stack direction="row" flexWrap="wrap" alignItems="flex-start">
        {photos?.map((photo, index) => (
          <MemoizedPhoto
            key={photo.id}
            photo={photo}
            onClick={() => onPhotoClick(photo, index)}
            source={source}
          />
        ))}
      </Stack>
      {canUploadPhoto && (
        <Stack
          direction="row"
          justifyContent="flex-end"
          sx={{
            mt: 'auto',
            position: 'fixed',
            bottom: 0,
            backgroundColor: colors.background.white,
            p: 4.5,
            width: smallMediaQuery ? '100%' : '564px',
          }}
        >
          <ButtonWithTooltip
            variant="new"
            color="secondary"
            onClick={() => {
              uploadMedia({
                fields: {
                  draw_request_id: drawRequestId,
                  project_id: projectId,
                  milestone_id: milestoneId,
                  object_id: milestoneId || drawRequestId || projectId,
                  content_type: getPhotoContentType({ milestoneId, drawRequestId }),
                },
                templateType: TransloaditTemplateEnum.PROOFPOINTS,
              });
            }}
            disabled={isCurrentProjectArchived}
            tooltipText={getTooltipText({ isCurrentProjectArchived })}
            sx={{ ml: 1 }}
            dataTestName={`${source}__upload__button`}
          >
            Upload progress photo
          </ButtonWithTooltip>
        </Stack>
      )}
      {gallery && <Gallery startIndex={startIndex} close={close} files={gallery} />}

      {isFilesUploaderOpened &&
        transloaditSignature &&
        ReactDOM.createPortal(uploader(), document.body)}
    </Stack>
  );
};

const Photo: FC<{
  photo: IProofpoint | IDocument;
  onClick: () => void;
  source: string;
}> = ({ photo, onClick, source }) => (
  <Box sx={{ width: '125px', mr: 1 }} key={photo.id} onClick={onClick} data-cy={`${source}__image`}>
    <PhotoCard photo={photo} backgroundColor={colors.neutral.white} />
  </Box>
);

const MemoizedPhoto = React.memo(
  Photo,
  (prevProps, nextProps) => prevProps?.photo?.id === nextProps?.photo?.id,
);

const NoContent: FC = () => (
  <Stack justifyContent="center" alignItems="center" sx={{ flexGrow: 1 }}>
    <NoDataImage size={200} />
    <Typography sx={{ mt: 4, textAlign: 'center' }} variant="body2SemiBold">
      No progress photos yet
    </Typography>
  </Stack>
);

const LoadingSkeleton = () => (
  <Stack
    alignItems="flex-start"
    justifyContent="center"
    direction="row"
    spacing={1}
    sx={{ width: '100%' }}
  >
    <Skeleton height={140} width={125} />
    <Skeleton height={140} width={125} />
    <Skeleton height={140} width={125} />
    <Skeleton height={140} width={125} />
  </Stack>
);

export default PhotosPanel;
