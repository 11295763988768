import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  ErrorDual,
  IFilterOption,
  IInspection,
  IMilestone,
  IMilestoneTotal,
  IPHBTableItem,
  MutationKeyEnum,
  PatchInspectionMSGroupParam,
  QueryNamesEnums,
  TableKeyEnum,
} from '@interfaces';
import {
  getInspectionMilestones,
  getProjectInspectionById,
  patchInspectionMSroup,
} from '@globalService';
import { useParams } from 'react-router-dom';
import {
  getItemLocalHighlight,
  getPHBViewTypes,
  getTypeFilterValue,
  parsePathErrorDual,
  replaceMilestoneData,
  useExpandCollapseTable,
  useLoadingSkeleton,
  usePHBFilters,
  usePHBGrouping,
  usePHBNaming,
} from '@utils';
import { useSafeSnackbar, useUpdateUiSettings, useUrlParams } from '@hooks';
import cloneDeep from 'lodash/cloneDeep';
import { SettingsContext } from '@context';

export type ControllerInterface = {
  initColumns: string[];
  tableItems: IPHBTableItem[];
  onExpandClick: (id: string, isExpanded: boolean) => void;
  filterOptions: IFilterOption[];
  filterValue: string;
  handleFilterClick: (value: string) => void;
  patchInspectionMSgroup: (params) => void;
  isLoading: boolean;
  totals: IMilestoneTotal;
  activeView: string;
  setActiveView: (state: string) => void;
  viewTypes: { label: string; value: string }[];
  isLineItemsView: boolean;
  typeFilterValues: string[];
  setTypeFilterValues: Dispatch<SetStateAction<string[]>>;
};

export const useInspectionTable = (showRequestedAmount = false): ControllerInterface => {
  const queryClient = useQueryClient();
  const { settings } = useContext(SettingsContext);
  const { updateSettings } = useUpdateUiSettings();
  const [unitsTableItems, setUnitsTableItems] = useState<IPHBTableItem[]>([]);
  const [lineItemsTableItems, setLineItemsTableItems] = useState<IPHBTableItem[]>([]);
  const { showLoadingSkeleton, showTemporaryLoadingSkeleton } = useLoadingSkeleton();
  const { inspectionId, projectId } = useParams();
  const { enqueueSnackbar } = useSafeSnackbar();
  const query = '{*}';
  const { unitName } = usePHBNaming();
  const viewTypes = useMemo(() => getPHBViewTypes(unitName), [unitName]);
  const [typeFilterValues, setTypeFilterValues] = React.useState<string[]>([
    'is_horizontal=true',
    'is_vertical=true',
  ]);
  const typeFilterValue = useMemo(() => getTypeFilterValue(typeFilterValues), [typeFilterValues]);

  const [activeView, setActiveView] = useUrlParams(
    settings.personal_setting?.PHB_TABLE_VIEW?.view_type || viewTypes[0].value,
    'view',
    (s) => s.toString(),
    (s) => s.toString(),
  );
  useEffect(() => {
    const savedViewType = settings.personal_setting?.PHB_TABLE_VIEW?.view_type;
    if (savedViewType && savedViewType !== activeView) setActiveView(savedViewType);
  }, [settings.personal_setting?.PHB_TABLE_VIEW?.view_type]);
  const isLineItemsView = useMemo(() => activeView === viewTypes[1].value, [activeView]);

  const { unitLineItemGrouping, lineItemUnitGrouping } = usePHBGrouping();
  const { filterValue, handleFilterClick, filterOptions, filterKey } = usePHBFilters({
    tableKey: TableKeyEnum.PHB_LINE_ITEMS,
    isNewView: true,
  });

  const createTableObject = ({
    item,
    isExpanded = false,
    isNested = false,
    index,
  }: {
    item: IMilestone;
    isExpanded?: boolean;
    isNested?: boolean;
    index: number | string;
  }): IPHBTableItem => ({
    ...item,
    activeToEdit: item?.revised_estimate > 0,
    localNew: false,
    localHighlight: getItemLocalHighlight(item),
    canBeExpanded: item?.milestone_groups?.length > 0,
    isExpanded,
    isNested,
    index,
  });

  const unitsNestedTableProps = useExpandCollapseTable({
    setListItems: setUnitsTableItems,
    createTableObject,
  });

  const lineItemsNestedTableProps = useExpandCollapseTable({
    setListItems: setLineItemsTableItems,
    createTableObject,
  });

  const inspectionQuery = useQuery<IInspection, Error>(
    [QueryNamesEnums.GET_PROJECT_INSPECTION_BY_ID, { projectId, inspectionId }],
    getProjectInspectionById.bind(this, { projectId, inspectionId }),
    { enabled: Boolean(inspectionId && projectId && unitLineItemGrouping) },
  );

  const unitsQuery = useQuery<{ results: IMilestone[] }, Error>(
    [
      QueryNamesEnums.GET_INSPECTION_MILESTONES,
      {
        projectId,
        inspectionId,
        query,
        groupBy: unitLineItemGrouping,
        filterKey,
      },
    ],
    getInspectionMilestones.bind(this, {
      projectId,
      inspectionId,
      groupBy: unitLineItemGrouping,
      filterKey,
    }),
    { enabled: Boolean(inspectionId && projectId && unitLineItemGrouping) },
  );

  const lineItemsQuery = useQuery<{ results: IMilestone[] }, Error>(
    [
      QueryNamesEnums.GET_INSPECTION_MILESTONES,
      {
        projectId,
        inspectionId,
        groupBy: lineItemUnitGrouping,
        typeKeys: typeFilterValue,
      },
    ],
    getInspectionMilestones.bind(this, {
      projectId,
      inspectionId,
      groupBy: lineItemUnitGrouping,
      typeKeys: typeFilterValue,
    }),
    { enabled: Boolean(inspectionId && projectId && lineItemUnitGrouping) },
  );

  const invalidateAfterLineItemPatch = async (data) => {
    queryClient.setQueriesData<{ results: IMilestone[] }>(
      {
        queryKey: [
          QueryNamesEnums.GET_INSPECTION_MILESTONES,
          {
            projectId,
            inspectionId,
            groupBy: lineItemUnitGrouping,
            typeKeys: typeFilterValue,
          },
        ],
      },
      (old) =>
        replaceMilestoneData({
          milestones: old,
          milestoneId: data.id,
          json: data,
        }),
    );
    await queryClient.invalidateQueries([
      QueryNamesEnums.GET_INSPECTION_MILESTONES,
      {
        projectId,
        inspectionId,
        query,
        filterKey,
        groupBy: unitLineItemGrouping,
      },
    ]);
  };

  const invalidateAfterUnitPatch = async (data) => {
    queryClient.setQueriesData<{ results: IMilestone[] }>(
      {
        queryKey: [
          QueryNamesEnums.GET_INSPECTION_MILESTONES,
          {
            projectId,
            inspectionId,
            query,
            filterKey,
            groupBy: unitLineItemGrouping,
          },
        ],
      },
      (old) =>
        replaceMilestoneData({
          milestones: old,
          milestoneId: data.id,
          json: data,
        }),
    );
    await queryClient.invalidateQueries([
      QueryNamesEnums.GET_INSPECTION_MILESTONES,
      {
        projectId,
        inspectionId,
        groupBy: lineItemUnitGrouping,
        typeKeys: typeFilterValue,
      },
    ]);
  };

  const patchPHBMilestoneInspectionMutation = useMutation<
    IMilestone,
    ErrorDual,
    PatchInspectionMSGroupParam
  >(patchInspectionMSroup, {
    mutationKey: MutationKeyEnum.MILESTONE_PATCH,
    onSuccess: async (data) => {
      queryClient.invalidateQueries([
        QueryNamesEnums.GET_PROJECT_INSPECTION_BY_ID,
        { projectId, inspectionId },
      ]);
      if (isLineItemsView) {
        await invalidateAfterLineItemPatch(data);
        lineItemsNestedTableProps.updateListItemsWithParentGroup(data);
        setUnitsTableItems(null);
      } else {
        await invalidateAfterUnitPatch(data);
        unitsNestedTableProps.updateListItemsWithParentGroup(data);
        setLineItemsTableItems(null);
      }
    },
    onError: (error) => {
      enqueueSnackbar(parsePathErrorDual(error), { variant: 'error' });
    },
  });

  useEffect(() => {
    setUnitsTableItems(null);
  }, [filterValue, inspectionId]);

  useEffect(() => {
    setLineItemsTableItems(null);
  }, [typeFilterValues, inspectionId]);

  useEffect(() => {
    if (!unitsQuery.data?.results || unitsTableItems?.length) return;

    const clonedMilestones = cloneDeep(unitsQuery.data.results);
    setUnitsTableItems(clonedMilestones.map((item, index) => createTableObject({ item, index })));
  }, [unitsQuery.data?.results, unitsTableItems]);

  useEffect(() => {
    if (!lineItemsQuery.data?.results || lineItemsTableItems?.length) return;

    const clonedMilestones = cloneDeep(lineItemsQuery.data.results);
    setLineItemsTableItems(
      clonedMilestones.map((item, index) => createTableObject({ item, index })),
    );
  }, [lineItemsQuery.data?.results, lineItemsTableItems]);

  const initColumns = useMemo(
    () => [
      'productionBuildExpand',
      'name',
      'inspectorAllowanceRate',
      'revisedEstimate',
      ...(showRequestedAmount ? ['requestedAmount'] : []),
      'comments',
    ],
    [showRequestedAmount],
  );

  const patchInspectionMSgroup = useCallback(
    (params) => {
      return patchPHBMilestoneInspectionMutation.mutate({
        projectId,
        inspectionId,
        group_by: isLineItemsView ? lineItemUnitGrouping : unitLineItemGrouping,
        json: params.json,
        milestoneId: params.milestoneId,
      });
    },
    [
      inspectionId,
      projectId,
      unitLineItemGrouping,
      lineItemUnitGrouping,
      isLineItemsView,
      patchPHBMilestoneInspectionMutation,
    ],
  );

  const totals = useMemo(() => {
    const inspection = inspectionQuery.data;
    return {
      previous_inspector_allowance_rate: inspection?.totals?.all?.previous_inspector_allowance_rate,
      inspector_allowance_rate: inspection?.totals?.all?.inspector_allowance_rate,
      requested_amount: inspection?.totals?.all?.requested_amount,
      revised_estimate: inspection?.totals?.all?.revised_estimate,
      displayAll: true,
    };
  }, [inspectionQuery.data]);

  return {
    initColumns,
    tableItems: isLineItemsView ? lineItemsTableItems : unitsTableItems,
    onExpandClick: isLineItemsView
      ? lineItemsNestedTableProps.onExpandClick
      : unitsNestedTableProps.onExpandClick,
    filterOptions,
    filterValue,
    handleFilterClick: (value: string) => {
      handleFilterClick(value);
      showTemporaryLoadingSkeleton();
    },
    isLoading: showLoadingSkeleton || unitsQuery.isLoading || lineItemsQuery.isLoading,
    patchInspectionMSgroup,
    totals: isLineItemsView ? undefined : totals,
    activeView,
    setActiveView: (view) => {
      setActiveView(view);
      updateSettings({
        personal_setting: {
          PHB_TABLE_VIEW: { view_type: view },
        },
      });
    },
    viewTypes,
    isLineItemsView,
    typeFilterValues,
    setTypeFilterValues,
  };
};
