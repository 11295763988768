import React, { FC, memo, useCallback, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { CommonRowType, MilestoneImages, MilestoneListColumnTypeV2 } from './common';
import { FilesUploader, UploaderWithForm } from '@components';
import { useFilesUploader } from '@hooks';
import { DocumentContentTypeEnum, TransloaditTemplateEnum } from '@interfaces';
import { getFilteredByRepresentationPhotos, getLinkedObjects } from '@utils';
import { DrawerContext } from '../drawer';
import { useLaunchDarklyFlags } from '@context';

const InspectionPhotos: FC<{ row: CommonRowType }> = ({ row }) => {
  const { flags } = useLaunchDarklyFlags();
  const {
    isFilesUploaderOpened,
    transloaditSignature,
    uploadMedia,
    filesUploaderClose,
    restrictions,
  } = useFilesUploader();
  const { inspectionId, projectId } = useParams();
  const isMilestone = Boolean(row.id);
  const { source } = useContext(DrawerContext);

  const images = getFilteredByRepresentationPhotos(row.proofpoints);
  const uploadClick = useCallback(
    () =>
      uploadMedia({
        fields: {
          inspection_id: inspectionId,
          project_id: projectId,
          object_id: row.id || inspectionId,
          content_type:
            isMilestone && !flags?.['eng_8699_consistent_document_uploader']
              ? DocumentContentTypeEnum.MILESTONE
              : DocumentContentTypeEnum.INSPECTION,
        },
        templateType: TransloaditTemplateEnum.PROOFPOINTS,
      }),
    [inspectionId, projectId, row.id, isMilestone, flags],
  );

  const uploader = () => {
    if (flags?.['eng_8699_consistent_document_uploader']) {
      return (
        <UploaderWithForm
          isOpen={isFilesUploaderOpened}
          documentTypes={[]}
          closeUploader={filesUploaderClose}
          transloaditSignature={transloaditSignature}
          restrictions={restrictions}
          source={`${source}__body__inspection_photos`}
          projectId={projectId}
          inspectionId={inspectionId}
          milestoneId={row?.id}
        />
      );
    }

    return (
      <FilesUploader
        open={isFilesUploaderOpened}
        onClose={filesUploaderClose}
        transloaditSignature={transloaditSignature}
        metaDataDefault={{
          ...(isMilestone && { milestone_id: row.id }),
          linked_objects: getLinkedObjects({
            projectId,
            inspectionId,
            milestoneId: row?.id,
          }),
          content_type: isMilestone
            ? DocumentContentTypeEnum.MILESTONE
            : DocumentContentTypeEnum.INSPECTION,
        }}
        restrictions={restrictions}
        source={`${source}__body__inspection_photos}`}
      />
    );
  };
  if (row.id === 'totals') return null;
  return (
    <MilestoneImages
      images={images}
      canAddPhotos={row.canAddPhotos}
      disabled={row.disabled}
      uploadClick={uploadClick}
      isFilesUploaderOpened={isFilesUploaderOpened}
      transloaditSignature={transloaditSignature}
      uploader={uploader}
      generalRowDataSource={row.generalRowDataSource}
    />
  );
};

const Memoized = memo(InspectionPhotos);

const inspectionPhotos: MilestoneListColumnTypeV2 = {
  field: 'proofpoints',
  headerAlign: 'right',
  headerClassName: 'icon',
  cellClassName: 'icon',
  renderHeader: () => null,
  editable: false,
  pinnable: false,
  disableColumnMenu: true,
  sortable: false,
  filterable: false,
  groupable: false,
  aggregable: false,
  hideable: false,
  resizable: false,
  renderCell: (params) => <Memoized row={params.row} />,
  maxWidth: 50,
  minWidth: 50,
};

export default inspectionPhotos;
