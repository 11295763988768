import { useMemo } from 'react';
import { MilestoneTag, MilestoneTagsTypesEnums, QueryNamesEnums } from '@interfaces';
import { getMilestoneTagId } from '@utils/index';
import { useQuery } from 'react-query';
import { getProjectMilestoneTags } from '@globalService';
import { useParams } from 'react-router-dom';

interface IPHBFilters {
  unitLineItemGrouping: string;
  lineItemUnitGrouping: string;
}

const usePHBGrouping = (): IPHBFilters => {
  const { projectId } = useParams();

  const msTagsQuery = useQuery<
    {
      results: MilestoneTag[];
    },
    Error
  >(
    [QueryNamesEnums.GET_PROJECT_MILESTONE_TAGS, { projectId }],
    getProjectMilestoneTags.bind(this, projectId),
    { enabled: Boolean(projectId) },
  );

  const msTags = msTagsQuery.data;

  const unitId = useMemo(
    () => getMilestoneTagId(msTags?.results, MilestoneTagsTypesEnums.UNIT),
    [msTags],
  );
  const lineItemId = useMemo(
    () => getMilestoneTagId(msTags?.results, MilestoneTagsTypesEnums.LINE_ITEM),
    [msTags],
  );

  const unitLineItemGrouping = useMemo(() => {
    if (unitId && lineItemId) {
      return `${unitId},${lineItemId}`;
    }
    return '';
  }, [unitId, lineItemId]);

  const lineItemUnitGrouping = useMemo(() => {
    if (unitId && lineItemId) {
      return `${lineItemId},${unitId}`;
    }
    return '';
  }, [unitId, lineItemId]);

  return {
    unitLineItemGrouping,
    lineItemUnitGrouping,
  };
};

export default usePHBGrouping;
