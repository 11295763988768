import React, { FC } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import {
  ButtonWithTooltip,
  CustomCurrencyTextField,
  CustomPercentTextField,
  CustomTextField,
  LabelAndValue,
  Popup,
} from '@components';
import { EditFundingSourceProps } from './interface';
import { useEditFundingSourceProportionModal } from './controller';
import { ProportionIcon } from '@svgAsComponents';
import { PaymentConfiguration } from '@interfaces';
import {
  currencyFormatter,
  isSequentialPaymentConfiguration,
  updateFSFractionValues,
  updateFSValues,
} from '@utils';

const EditFundingSourceProportionModal: FC<EditFundingSourceProps> = ({
  open,
  onClose,
  field1,
  field2,
  sourceName1,
  sourceName2,
  fraction1,
  fraction2,
  setConfigurationType,
  configurationTtype,
  constructionBudget,
  source,
}) => {
  const {
    nameField1,
    amountField1,
    nameField2,
    amountField2,
    fractionField1,
    fractionField2,
    tooltipText,
  } = useEditFundingSourceProportionModal({
    sourceName1,
    sourceName2,
    value1: field1?.value || '',
    value2: field2?.value || '',
    fraction1: fraction1?.value || '',
    fraction2: fraction2?.value || '',
  });

  if (!open || !field1 || !field2) return null;

  return (
    <Popup open={open} title="Add proportion" icon={ProportionIcon} sx={{ minWidth: 800 }}>
      <Stack sx={{ width: '100%' }}>
        <Stack sx={{ width: '100%', paddingX: 6, paddingY: 2, alignContent: 'center' }}>
          <Typography variant="body2" align="center">
            By setting up the proportion you change payment model from project sequential to partial
            per request.
          </Typography>
        </Stack>
        <Stack sx={{ width: '100%', padding: 2 }} direction="row" spacing={2}>
          <Stack sx={{ width: '100%' }} spacing={4}>
            <CustomTextField
              field={nameField1}
              label="Source name"
              required
              disabled
              inputProps={{
                'data-cy': `${source}__funding_source_proportion_modal__source_name1__input`,
              }}
            />
            <CustomCurrencyTextField
              label="Amount ($)"
              field={amountField1}
              onChange={(e) => {
                updateFSValues({
                  value: e.target.value,
                  amountField: amountField1,
                  otherAmountField: amountField2,
                  fieldFraction: fractionField1,
                  otherFieldFraction: fractionField2,
                  constructionBudget,
                });
              }}
              inputProps={{
                'data-cy': `${source}__funding_source_proportion_modal__amount1__input`,
              }}
            />
            <CustomPercentTextField
              label="Percentage (%)"
              field={fractionField1}
              onChange={(e) => {
                updateFSFractionValues({
                  value: e.target.value,
                  fractionField: fractionField1,
                  otherFractionField: fractionField2,
                  amountField: amountField1,
                  otherAmountField: amountField2,
                  constructionBudget,
                });
              }}
              inputProps={{
                'data-cy': `${source}__funding_source_proportion_modal__fraction1__input`,
              }}
            />
          </Stack>
          <Stack sx={{ width: '100%' }} spacing={4}>
            <CustomTextField
              field={nameField2}
              label="Source name"
              required
              disabled
              inputProps={{
                'data-cy': `${source}__funding_source_proportion_modal__source_name2__input`,
              }}
            />
            <CustomCurrencyTextField
              label="Amount ($)"
              field={amountField2}
              onChange={(e) => {
                updateFSValues({
                  value: e.target.value,
                  amountField: amountField2,
                  otherAmountField: amountField1,
                  fieldFraction: fractionField2,
                  otherFieldFraction: fractionField1,
                  constructionBudget,
                });
              }}
              inputProps={{
                'data-cy': `${source}__funding_source_proportion_modal__amount2__input`,
              }}
            />
            <CustomPercentTextField
              label="Percentage (%)"
              field={fractionField2}
              onChange={(e) => {
                updateFSFractionValues({
                  value: e.target.value,
                  fractionField: fractionField2,
                  otherFractionField: fractionField1,
                  amountField: amountField2,
                  otherAmountField: amountField1,
                  constructionBudget,
                });
              }}
              inputProps={{
                'data-cy': `${source}__funding_source_proportion_modal__fraction2__input`,
              }}
            />
          </Stack>
        </Stack>
        <Stack sx={{ padding: 2 }}>
          <LabelAndValue
            label="Total amount:"
            text={currencyFormatter((+amountField1.value || 0) + (+amountField2.value || 0))}
            marginRightAfterLabel={false}
            justifyContent="flex-start"
            textDataTestName={`${source}__funding_source_proportion_modal__total_amount__value`}
          />
        </Stack>
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          sx={{ mt: 7.5 }}
          spacing={1}
        >
          <Button
            variant="text"
            onClick={() => {
              onClose();
            }}
            data-cy={`${source}__funding_source_proportion_modal__cancel__button`}
          >
            Cancel
          </Button>
          {!isSequentialPaymentConfiguration(configurationTtype) && (
            <Button
              variant="new"
              color="error"
              onClick={() => {
                onClose();
                setConfigurationType(PaymentConfiguration.SEQUENTIAL);
              }}
              data-cy={`${source}__funding_source_proportion_modal__delete__button`}
            >
              Delete
            </Button>
          )}
          <ButtonWithTooltip
            tooltipText={tooltipText}
            disabled={Boolean(tooltipText)}
            onClick={() => {
              if (fractionField1.isValid && fractionField2.isValid) {
                field1.setAsFloat(+amountField1.value);
                field2.setAsFloat(+amountField2.value);
                fraction1.setAsFloat(+fractionField1.value);
                fraction2.setAsFloat(+fractionField2.value);
                setConfigurationType(PaymentConfiguration.PER_DRAW_REQUEST);
                onClose();
              }
            }}
            dataTestName={`${source}__funding_source_proportion_modal__save__button`}
          >
            {field1?.value ? 'Save' : 'Add'}
          </ButtonWithTooltip>
        </Stack>
      </Stack>
    </Popup>
  );
};

export default EditFundingSourceProportionModal;
