import { SettingsContext, useGetData } from '@context';
import { patchDrawRequestListItem, patchDrawRequestMilestoneGroup } from '@globalService';
import {
  CreditReasonEnum,
  ErrorDual,
  IMilestone,
  MutationKeyEnum,
  PatchDrawRequestListItemParam,
  PatchDrawRequestMSGroupParam,
  QueryNamesEnums,
} from '@interfaces';
import { useStringFieldModel } from '@models';
import { emptyHTML, usePHBGrouping } from '@utils';
import { useCallback, useContext, useMemo, useState } from 'react';
import { useIsMutating, useMutation } from 'react-query';
import { useParams } from 'react-router';
import { useLocation } from 'react-router-dom';

export const useSubmitCredit = ({ requestId, milestoneId, setOpen, onCreditChangeCompleted }) => {
  const { projectId } = useParams();
  const { isPHBProject } = useContext(SettingsContext);
  const { unitLineItemGrouping, lineItemUnitGrouping } = usePHBGrouping();
  const { settings } = useContext(SettingsContext);
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const isLineItemView = useMemo(
    () =>
      params.get('view') === 'line_items' ||
      settings.personal_setting?.PHB_TABLE_VIEW?.view_type === 'line_items',
    [params.get('view'), settings.personal_setting?.PHB_TABLE_VIEW?.view_type],
  );
  const group_by = useMemo(
    () => (isLineItemView ? lineItemUnitGrouping : unitLineItemGrouping),
    [isLineItemView, lineItemUnitGrouping, unitLineItemGrouping],
  );

  const [reason, setReason] = useState<string[]>([CreditReasonEnum.REBATE_OR_CREDIT]);

  const commentField = useStringFieldModel({
    initValue: '',
    validationRule: (value) => Boolean(value.trim()) && !emptyHTML(value),
  });
  const project = useGetData({
    type: QueryNamesEnums.GET_PROJECT,
    keys: ['name'],
    args: { projectId },
  });

  const milestone = useGetData({
    type: isPHBProject
      ? QueryNamesEnums.GET_DRAW_REQUEST_MILESTONE_GROUP
      : QueryNamesEnums.GET_DRAW_REQUEST_MILESTONE,
    keys: isPHBProject ? ['requested_credit_amount', 'tags', 'id'] : ['requested_credit_amount'],
    args: { projectId, drawRequestId: requestId, milestoneId, group_by },
  });

  const updateQueries = (data: IMilestone) => {
    milestone.manualUpdate({ requested_credit_amount: data.requested_credit_amount });
    onCreditChangeCompleted && onCreditChangeCompleted(data);
    setOpen(false);
  };

  const patchMilestoneRequestMutation = useMutation<
    IMilestone,
    ErrorDual,
    PatchDrawRequestListItemParam
  >(patchDrawRequestListItem, {
    mutationKey: MutationKeyEnum.MILESTONE_PATCH,
    onSuccess: (data) => {
      updateQueries(data);
    },
  });

  const patchDrawRequestProdBuildGroupMutation = useMutation<
    IMilestone,
    ErrorDual,
    PatchDrawRequestMSGroupParam
  >(patchDrawRequestMilestoneGroup, {
    mutationKey: MutationKeyEnum.MILESTONE_PATCH,
    onSuccess: (data) => {
      updateQueries(data);
    },
  });

  const requested_credit_amount = useStringFieldModel({
    initValue: milestone.data?.requested_credit_amount?.toString?.(),
    withProgressCheck: true,
  });

  const resetState = useCallback(() => {
    commentField.setValue('');
    requested_credit_amount.setAsFloat(+milestone.data?.requested_credit_amount);
  }, [milestone]);

  const submit = useCallback(async () => {
    if (isPHBProject) {
      return patchDrawRequestProdBuildGroupMutation.mutateAsync({
        project: projectId,
        drawRequest: requestId,
        group_by,
        milestoneId: milestone.data?.id,
        json: {
          requested_credit_amount: +requested_credit_amount.value,
          credit_reason: reason.includes(CreditReasonEnum.OTHER) ? commentField.value : reason[0],
        },
      });
    } else
      return patchMilestoneRequestMutation.mutateAsync({
        project: projectId,
        drawRequest: requestId,
        milestone: milestoneId,
        json: {
          requested_credit_amount: +requested_credit_amount.value,
          credit_reason: reason.includes(CreditReasonEnum.OTHER) ? commentField.value : reason[0],
        },
      });
  }, [requestId, projectId, milestoneId, requested_credit_amount, reason, commentField, group_by]);

  const isMutating = useIsMutating();
  return {
    name: project.data.name,
    reason,
    commentField,
    setReason,
    requested_credit_amount,
    resetState,
    submit,
    isSubmiting: isMutating,
  };
};
