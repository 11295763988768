import { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  ErrorDual,
  IFilterOption,
  IInspection,
  IMilestone,
  IMilestoneTotal,
  IPHBTableItem,
  MutationKeyEnum,
  PatchInspectionMSGroupParam,
  QueryNamesEnums,
  TableKeyEnum,
} from '@interfaces';
import {
  getInspectionMilestones,
  getProjectInspectionById,
  patchInspectionMSroup,
} from '@globalService';
import { useParams } from 'react-router-dom';
import {
  getItemLocalHighlight,
  parsePathErrorDual,
  replaceMilestoneData,
  useExpandCollapseTable,
  useLoadingSkeleton,
  usePHBFilters,
} from '@utils';
import { useSafeSnackbar } from '@hooks';
import cloneDeep from 'lodash/cloneDeep';

export type ControllerInterface = {
  initColumns: string[];
  milestones: IPHBTableItem[];
  onExpandClick: (id: string, isExpanded: boolean) => void;
  filterOptions: IFilterOption[];
  groupByOptions: { ids: string; filterValue: string }[];
  filterValue: string;
  handleFilterClick: (value: string) => void;
  groupByValue: string;
  handleGroupByClick: (value: string) => void;
  patchInspectionMSgroup: (params) => void;
  isLoading: boolean;
  totals: IMilestoneTotal;
};

export const useInspectionTable = (showRequestedAmount = false): ControllerInterface => {
  const queryClient = useQueryClient();
  const [listItems, setListItems] = useState([]);
  const { showLoadingSkeleton, showTemporaryLoadingSkeleton } = useLoadingSkeleton();
  const { inspectionId, projectId } = useParams();
  const { enqueueSnackbar } = useSafeSnackbar();
  const query = '{*}';

  const {
    groupIds,
    filterValue,
    handleFilterClick,
    groupByValue,
    handleGroupByClick,
    filterOptions,
    groupByOptions,
    filterKey,
  } = usePHBFilters({
    tableKey: TableKeyEnum.PHB_LINE_ITEMS,
  });

  const inspectionQuery = useQuery<IInspection, Error>(
    [QueryNamesEnums.GET_PROJECT_INSPECTION_BY_ID, { projectId, inspectionId }],
    getProjectInspectionById.bind(this, { projectId, inspectionId }),
    { enabled: Boolean(inspectionId && projectId && groupIds) },
  );

  const inspectionMilestonesQuery = useQuery<{ results: IMilestone[] }, Error>(
    [
      QueryNamesEnums.GET_INSPECTION_MILESTONES,
      {
        projectId,
        inspectionId,
        query,
        groupBy: groupIds,
        filterKey,
      },
    ],
    getInspectionMilestones.bind(this, {
      projectId,
      inspectionId,
      groupBy: groupIds,
      filterKey,
    }),
    { enabled: Boolean(inspectionId && projectId && groupIds) },
  );
  const inspectionMilestones = inspectionMilestonesQuery.data;

  const patchPHBMilestoneInspectionMutation = useMutation<
    IMilestone,
    ErrorDual,
    PatchInspectionMSGroupParam
  >(patchInspectionMSroup, {
    mutationKey: MutationKeyEnum.MILESTONE_PATCH,
    onSuccess: async (data) => {
      queryClient.invalidateQueries([
        QueryNamesEnums.GET_PROJECT_INSPECTION_BY_ID,
        { projectId, inspectionId },
      ]);
      queryClient.setQueriesData<{ results: IMilestone[] }>(
        {
          queryKey: [
            QueryNamesEnums.GET_INSPECTION_MILESTONES,
            {
              projectId,
              inspectionId,
              query,
              filterKey,
              groupBy: groupIds,
            },
          ],
        },
        (old) =>
          replaceMilestoneData({
            milestones: old,
            milestoneId: data.id,
            json: data,
          }),
      );
      updateListItemsWithParentGroup(data);
    },
    onError: (error) => {
      enqueueSnackbar(parsePathErrorDual(error), { variant: 'error' });
    },
  });

  useEffect(() => {
    setListItems(null);
  }, [filterValue, groupByValue]);

  useEffect(() => {
    if (!inspectionMilestones?.results || listItems?.length) return;

    const clonedMilestones = cloneDeep(inspectionMilestones.results);
    setListItems(clonedMilestones.map((item, index) => createTableObject({ item, index })));
  }, [inspectionMilestones?.results, listItems]);

  const createTableObject = ({
    item,
    isExpanded = false,
    isNested = false,
    index,
  }: {
    item: IMilestone;
    isExpanded?: boolean;
    isNested?: boolean;
    index: number | string;
  }): IPHBTableItem => ({
    ...item,
    activeToEdit: item?.revised_estimate > 0,
    localNew: false,
    localHighlight: getItemLocalHighlight(item),
    canBeExpanded: item?.milestone_groups?.length > 0,
    isExpanded,
    isNested,
    index,
  });

  const { onExpandClick, updateListItemsWithParentGroup } = useExpandCollapseTable({
    setListItems,
    createTableObject,
  });

  const initColumns = useMemo(
    () => [
      'productionBuildExpand',
      'name',
      'inspectorAllowanceRate',
      'revisedEstimate',
      ...(showRequestedAmount ? ['requestedAmount'] : []),
      'comments',
    ],
    [showRequestedAmount],
  );

  const patchInspectionMSgroup = useCallback(
    (params) => {
      return patchPHBMilestoneInspectionMutation.mutate({
        projectId,
        inspectionId,
        group_by: groupIds,
        milestoneId: params.milestoneId,
        json: params.json,
      });
    },
    [inspectionId, projectId, groupIds, patchPHBMilestoneInspectionMutation],
  );

  const totals = useMemo(() => {
    const inspection = inspectionQuery.data;
    return {
      previous_inspector_allowance_rate: inspection?.totals?.all?.previous_inspector_allowance_rate,
      inspector_allowance_rate: inspection?.totals?.all?.inspector_allowance_rate,
      requested_amount: inspection?.totals?.all?.requested_amount,
      revised_estimate: inspection?.totals?.all?.revised_estimate,
      displayAll: true,
    };
  }, [inspectionQuery.data]);

  return {
    initColumns,
    milestones: listItems,
    onExpandClick,
    groupByOptions,
    filterOptions,
    filterValue,
    handleFilterClick: (value: string) => {
      handleFilterClick(value);
      showTemporaryLoadingSkeleton();
    },
    groupByValue,
    handleGroupByClick: (value: string) => {
      handleGroupByClick(value);
      showTemporaryLoadingSkeleton();
    },
    isLoading: showLoadingSkeleton || inspectionMilestonesQuery.isLoading,
    patchInspectionMSgroup,
    totals,
  };
};
