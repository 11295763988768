import React, { useContext } from 'react';
import { Box, Button, Grid2, IconButton, Stack, TextField, Typography } from '@mui/material';

import { colors } from '@theme';
import { ComponentProps } from './interface';
import ChecklistItem from './ChecklistItem';
import { DeleteIcon } from '@svgAsComponents';
import { SettingsContext } from '@context';
import { TOOLTIP_TEXTS } from '@constants';
import { InfoIconWithTooltip } from '@components';
import { dateFormatter } from '@utils';

function Checklist({
  items,
  onItemClick,
  isEditable = false,
  checklistItemInput,
  addChecklistItem,
  deleteChecklistItem,
  showCheckedBy = true,
}: ComponentProps) {
  const { isCurrentProjectArchived } = useContext(SettingsContext);

  return (
    <Box sx={{ display: 'flex', flexFlow: 'column nowrap' }}>
      {items.map((item) => {
        const showInfoTootip = item.description || (item.checked && item.checkedBy?.name);
        return (
          <Grid2
            container
            justifyContent="space-between"
            alignItems="baseline"
            key={item.id}
            sx={{ mb: '0.75rem' }}
          >
            <Grid2 sx={{ maxWidth: '90%' }}>
              <ChecklistItem
                key={item.id}
                {...item}
                onClick={onItemClick}
                disabled={isCurrentProjectArchived}
                tooltipText={TOOLTIP_TEXTS.isCurrentProjectArchived}
                showCheckedBy={showCheckedBy}
                source={isEditable ? 'request__action_popup__additional' : 'request__action_popup'}
              />
            </Grid2>
            <Grid2>
              <Stack direction="row" alignItems="center">
                <InfoIconWithTooltip
                  data-cy={`request__action_popup__checklist__item__info_tooltip__icon`}
                  color={showInfoTootip ? colors.status.information.medium : colors.neutral.medium}
                  tooltipText={
                    showInfoTootip ? (
                      <>
                        {item.description && (
                          <Stack>
                            <Typography variant="labelSemiBold">Description:</Typography>
                            <Typography
                              variant="label"
                              dangerouslySetInnerHTML={{ __html: item.description }}
                              data-cy={`request__action_popup__checklist__item__description`}
                            />
                          </Stack>
                        )}
                        {item.checked && item.checkedBy?.name && (
                          <Stack>
                            <Typography variant="labelSemiBold">Checked:</Typography>
                            <Typography
                              variant="label"
                              data-cy={`request__action_popup__checklist__item__checked_by`}
                            >
                              {`${item.checkedBy.name}, ${dateFormatter({
                                date: item.checkedBy.updatedAt,
                                withTime: true,
                              })}`}
                            </Typography>
                          </Stack>
                        )}
                      </>
                    ) : (
                      ''
                    )
                  }
                />
                {isEditable && !item.checked && (
                  <IconButton
                    onClick={() => deleteChecklistItem(item.id.toString())}
                    sx={{ p: 0, ml: 1 }}
                    data-cy="request__action_popup__checklist__item__delete_button"
                  >
                    <DeleteIcon size={20} color={colors.icons.gray} />
                  </IconButton>
                )}
              </Stack>
            </Grid2>
          </Grid2>
        );
      })}
      {isEditable && (
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="baseline"
          sx={{ mt: items.length && '0.75rem' }}
        >
          <TextField
            fullWidth
            variant="outlined"
            label="New requirement for current request"
            onChange={checklistItemInput.handleChange}
            error={!checklistItemInput.isValid}
            value={checklistItemInput.value}
            helperText={checklistItemInput.isValid ? '' : 'Requirement could not be blank'}
            multiline
            maxRows={3}
            size="small"
            inputProps={{
              'data-cy': 'request__action_popup__checklist__new_requirement__input',
            }}
          />
          <Button
            type="submit"
            variant="new"
            color="secondary"
            onClick={() => {
              checklistItemInput.validate();
              checklistItemInput.value.trim() && addChecklistItem(checklistItemInput.value);
            }}
            sx={{ ml: 2 }}
            data-cy="request__action_popup__checklist__new_requirement__add_button"
          >
            Add
          </Button>
        </Stack>
      )}
    </Box>
  );
}

export default Checklist;
