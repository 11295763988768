import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, ButtonBase, Divider, IconButton, Stack, Typography } from '@mui/material';
import { Popper } from '@mui/base/Popper';
import isEmpty from 'lodash/isEmpty';

import { dateFormatter, getImageUrl } from '@utils';
import { GoogleMap, LabelAndValue, Tags } from '@components';
import { CloseIcon, PanoramicPhotoIcon, SuccessIcon } from '@svgAsComponents';
import { colors } from '@theme';
import { ImageSizeEnum } from '@interfaces';

import { PhotoCardProps, PhotoDetailsProps, PhotoProps } from './interface';

const IconSizes = {
  small: 20,
  medium: 24,
};

const ImageButton = styled(ButtonBase)(() => ({
  position: 'relative',
  width: '100%',
  '&:hover, &.Mui-focusVisible': {
    zIndex: 1,
    '& .MuiImageBackdrop-root': {
      opacity: 0.15,
    },
    '& .MuiImageMarked-root': {
      opacity: 0,
    },
    '& .MuiTypography-root': {
      border: '4px solid currentColor',
    },
  },
}));

const ImageSrc = styled('span')({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundSize: 'contain',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
});

type SpanProps = {
  size: string;
  color: string;
  right?: number;
};

const IconWrapper = styled('span')<SpanProps>(({ size, color, right = 0 }) => ({
  position: 'absolute',
  right,
  bottom: 0,
  width: IconSizes[size],
  height: IconSizes[size],
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: color,
}));

const StyledPopperDiv = styled('div')(
  () => `
  background-color: ${colors.background.white};
  border-radius: 4px;
  padding: 16px;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
`,
);

const VerifiedLocation = ({ size, color }) => (
  <IconWrapper size={size} color={color}>
    <SuccessIcon color={colors.neutral.white} size={13} />
  </IconWrapper>
);

const PanoramicPhoto = ({ size, color, rightMargin }) => (
  <IconWrapper size={size} color={color} right={rightMargin}>
    <PanoramicPhotoIcon />
  </IconWrapper>
);

export const PhotoCard = ({
  photo,
  size,
  handleHover,
  handleClose,
  dataTestName,
  backgroundColor,
}: PhotoCardProps) => {
  const showVerifiedIcon =
    photo &&
    'location' in photo &&
    !isEmpty(photo['location']) &&
    photo['location']['lat'] &&
    photo['location']['long'];

  return (
    <ImageButton
      focusRipple
      onMouseEnter={handleHover}
      onMouseLeave={handleClose}
      style={{
        height: size === 'small' ? '60px' : '146px',
        backgroundColor: colors.background.gray,
      }}
    >
      <ImageSrc
        data-cy={dataTestName}
        style={{
          backgroundImage: `url(${getImageUrl(ImageSizeEnum.THUMB, photo.file_representations)})`,
          backgroundColor,
        }}
      />
      {photo?.is_panorama && (
        <PanoramicPhoto
          size={size}
          color={colors.status.information.medium}
          rightMargin={showVerifiedIcon ? 30 : 0}
        />
      )}
      {showVerifiedIcon && (
        <VerifiedLocation
          size={size}
          color={
            photo.location?.is_verified ? colors.status.success.medium : colors.status.orange.medium
          }
        />
      )}
    </ImageButton>
  );
};

export const PhotoCardWithPopper = ({ photo, size, dataTestName }: PhotoProps) => {
  const handleHover = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!photo) return null;
  return (
    <>
      <PhotoCard
        photo={photo}
        size={size}
        handleHover={handleHover}
        handleClose={handleClose}
        dataTestName={dataTestName}
      />

      <Popper id={id} open={open} anchorEl={anchorEl} placement="top-end" style={{ zIndex: 1300 }}>
        <StyledPopperDiv sx={{ width: 350 }}>
          <PhotoDetails photo={photo} size="small" />
        </StyledPopperDiv>
      </Popper>
    </>
  );
};

const DetailsSizes = {
  small: {
    title: 'labelSemiBold',
    label: 'label',
  },
  medium: {
    title: 'h2',
    label: 'body2',
  },
};

export const PhotoDetails = ({ photo, size, closeCallback }: PhotoDetailsProps) => {
  if (!photo) return null;

  return (
    <Stack spacing={1}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ pb: 1 }}>
        <Typography variant={DetailsSizes[size]?.title}>Photo details</Typography>
        {closeCallback && (
          <IconButton onClick={closeCallback} data-cy="photos_tab__card__close__button">
            <CloseIcon />
          </IconButton>
        )}
      </Stack>

      <LabelAndValue
        size={DetailsSizes[size]?.label}
        label="Line item"
        text={photo.milestones?.map((milestone) => milestone.name).join(', ') || '-'}
      />
      <LabelAndValue
        size={DetailsSizes[size]?.label}
        label="Approximate location"
        text={photo.location?.address || '-'}
        icon={
          photo.location?.is_verified && (
            <SuccessIcon color={colors.status.success.medium} size={18} />
          )
        }
      />
      {size !== 'small' && (
        <GoogleMap location={{ ...photo.location, lng: photo.location?.long }} />
      )}
      <LabelAndValue size={DetailsSizes[size]?.label} label="Device" text={photo.device || '-'} />
      <LabelAndValue
        size={DetailsSizes[size]?.label}
        label="Taken on"
        text={dateFormatter({ date: photo.created_at }) || '-'}
      />
      <LabelAndValue
        size={DetailsSizes[size]?.label}
        label="Loaded by"
        text={
          !photo.loaded_by?.first_name && !photo.loaded_by?.last_name
            ? photo.created_by?.['full_name'] || '-'
            : `${photo.loaded_by?.first_name || ''} ${photo.loaded_by?.last_name || ''}`
        }
      />
      <LabelAndValue
        size={DetailsSizes[size]?.label}
        label="Loaded when"
        text={dateFormatter({ date: photo.loaded_at }) || '-'}
      />
      <LabelAndValue
        size={DetailsSizes[size]?.label}
        label="Photo name"
        text={photo.original_filename || '-'}
      />
      <Box sx={{ py: 1 }} />
      {photo.comment && (
        <>
          <Typography variant="h4">Comments</Typography>
          <Typography variant="body3">{photo.comment}</Typography>
          <Box sx={{ py: 1 }}>
            <Divider />
          </Box>
        </>
      )}
      <Tags items={[...(photo.source_label?.split('/') || [])]} source="photos_tab__card" />
    </Stack>
  );
};
