import React, { FC, useCallback, useContext, useRef } from 'react';
import ReactDOM from 'react-dom';
import { useParams } from 'react-router-dom';
import { ViewerAPI } from 'react-photo-sphere-viewer';
import { Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import { useFilesUploader, useImagePicker } from '@hooks';
import { ButtonWithTooltip, Gallery, PanoramaViewer, UploaderWithForm } from '../index';
import { DocumentSectionContextEnum, TransloaditTemplateEnum } from '@interfaces';
import { NoDataImage } from '@svgAsComponents';
import { usePhotoPanel } from './controller';
import { colors } from '@theme';
import { getPhotoContentType, getTooltipText, openFullScreenPanorama } from '@utils';
import { SettingsContext } from '@context';
import { PhotoList } from './components';
import { PhotoClickFnProps } from './interface';

const PhotosPanel: FC<{
  milestoneId?: string;
  drawRequestId?: string;
  inspectionId?: string;
  prId?: string;
  source: string;
  hideUploadButton?: boolean;
}> = ({ milestoneId, drawRequestId, inspectionId, prId, source, hideUploadButton }) => {
  const { projectId } = useParams();
  const { isCurrentProjectArchived } = useContext(SettingsContext);
  const { gallery, open, close, startIndex } = useImagePicker();
  const smallMediaQuery = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const {
    isFilesUploaderOpened,
    transloaditSignature,
    uploadMedia,
    filesUploaderClose,
    restrictions,
    refetchCallback,
  } = useFilesUploader();
  const { canUploadPhoto, headerText, setTotalDocCount, noPhotos, updateDocumentsCount } =
    usePhotoPanel({
      prId,
      milestoneId,
      drawRequestId,
      inspectionId,
    });
  const [showPanorama, setShowPanorama] = React.useState(false);
  const ref = useRef<ViewerAPI>();

  const onPhotoClick = useCallback(
    ({ photosArray, photo, index }: PhotoClickFnProps) => {
      if (photo.is_panorama) {
        openFullScreenPanorama(setShowPanorama, ref, photo);
      } else {
        open(photosArray, index);
      }
    },
    [open, ref],
  );

  const uploader = () => {
    return (
      <UploaderWithForm
        isOpen={isFilesUploaderOpened}
        closeUploader={filesUploaderClose}
        transloaditSignature={transloaditSignature}
        restrictions={restrictions}
        source={source}
        projectId={projectId || prId}
        drawRequestId={drawRequestId}
        showLineItems
        milestoneId={milestoneId}
        inspectionId={inspectionId}
        refetchCallback={refetchCallback}
        refetch={[updateDocumentsCount]}
      />
    );
  };

  return (
    <Stack flexWrap="wrap" sx={{ flex: 1 }}>
      {noPhotos && <NoContent />}
      {showPanorama && <PanoramaViewer ref={ref} />}
      <Stack spacing={2} mb={12}>
        <PhotoList
          onPhotoClick={onPhotoClick}
          source={source}
          projectId={projectId || prId}
          milestoneId={milestoneId}
          drawRequestId={drawRequestId}
          inspectionId={inspectionId}
          context={DocumentSectionContextEnum.PRIMARY}
          headerText={headerText}
          setTotalDocCount={setTotalDocCount}
        />
        {milestoneId && (
          <PhotoList
            onPhotoClick={onPhotoClick}
            source={source}
            projectId={projectId || prId}
            milestoneId={milestoneId}
            drawRequestId={drawRequestId}
            inspectionId={inspectionId}
            context={DocumentSectionContextEnum.OTHER}
            headerText="Other"
            setTotalDocCount={setTotalDocCount}
          />
        )}
      </Stack>
      {!hideUploadButton && canUploadPhoto && (
        <Stack
          direction="row"
          justifyContent="flex-end"
          sx={{
            mt: 'auto',
            position: 'fixed',
            bottom: 0,
            backgroundColor: colors.background.white,
            p: 4.5,
            width: smallMediaQuery ? '100%' : '564px',
          }}
        >
          <ButtonWithTooltip
            variant="new"
            color="secondary"
            onClick={() => {
              uploadMedia({
                fields: {
                  draw_request_id: drawRequestId,
                  project_id: projectId || prId,
                  ...(milestoneId ? { milestone_id: milestoneId } : {}),
                  ...(inspectionId ? { inspection_id: inspectionId } : {}),
                  object_id: milestoneId || inspectionId || drawRequestId || projectId || prId,
                  content_type: getPhotoContentType({ milestoneId, drawRequestId, inspectionId }),
                },
                templateType: TransloaditTemplateEnum.PROOFPOINTS,
              });
            }}
            disabled={isCurrentProjectArchived}
            tooltipText={getTooltipText({ isCurrentProjectArchived })}
            sx={{ ml: 1 }}
            dataTestName={`${source}__upload__button`}
          >
            Upload progress photo
          </ButtonWithTooltip>
        </Stack>
      )}
      {gallery && <Gallery startIndex={startIndex} close={close} files={gallery} />}

      {isFilesUploaderOpened &&
        transloaditSignature &&
        ReactDOM.createPortal(uploader(), document.body)}
    </Stack>
  );
};

const NoContent: FC = () => (
  <Stack justifyContent="center" alignItems="center" sx={{ flexGrow: 1 }}>
    <NoDataImage size={200} />
    <Typography sx={{ mt: 4, textAlign: 'center' }} variant="body2SemiBold">
      No progress photos yet
    </Typography>
  </Stack>
);

export default PhotosPanel;
