import React, { FC, useRef } from 'react';
import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import { useIsMutating } from 'react-query';
import {
  ButtonWithTooltip,
  CollapsedCard,
  ConfirmationModal,
  CustomDatePickerInput,
  Gallery,
  LabelAndValue,
  LinkRequestToServicePicker,
  LoadingSkeleton,
  MessagePanel,
  MessagePanelButton,
  PDFViewerNew,
  ProjectNameLabel,
  RightDrawer,
  ServiceCancelButton,
  ServiceMessage,
  ServiceRelatedDocuments,
  StatusChip,
  StyledBox,
  SuccessModal,
} from '@components';
import { HookState, MessagePanelTabsEnum, PopupTypeEnum } from '@interfaces';
import { useServiceOrderEnterResult } from './controller';
import { dateFormatter, isOrderedService } from '@utils';
import { ArrowBackIcon, EditIcon } from '@svgAsComponents';
import { colors } from '@theme';
import UpdateServiceOrderStatus from './UpdateServiceOrderStatus';
import { ControllerInterface } from './interface';

const ServiceResults: FC = () => {
  const controller = useServiceOrderEnterResult();
  const {
    state,
    project,
    serviceOrder,
    updateRightDrawer,
    rightMenu,
    isApprovalPopupOpen,
    setIsApprovalPopupOpen,
    goBack,
    shouldShowDeleteButton,
    deleteServiceOrderModal,
    isCurrentProjectArchived,
    handleDeleteServiceOrder,
    isEditable,
    confirmCallBack,
    serviceCompletedAt,
  } = controller;
  const isMutating = useIsMutating();

  switch (state) {
    case HookState.FETCHING: {
      return (
        <StyledBox>
          <LoadingSkeleton type="overviewBlock" />
        </StyledBox>
      );
    }
    case HookState.ERROR: {
      return (
        <StyledBox>
          <ServiceMessage text="service" />
        </StyledBox>
      );
    }

    case HookState.SUCCESS: {
      return (
        <>
          <Stack direction="column" sx={{ height: '100%' }}>
            <Stack sx={{ p: 2 }} direction="row" alignItems="center" justifyContent="space-between">
              <Stack direction="row" alignItems="center">
                <IconButton onClick={goBack} data-cy="service_results__back__icon">
                  <ArrowBackIcon />
                </IconButton>
                <Typography sx={{ ml: 2 }} variant="h2">
                  {isEditable ? 'Enter results' : 'Service results'}
                </Typography>
              </Stack>

              <Stack direction="row" spacing={2} justifyContent="flex-end">
                <ProjectNameLabel project={project} />
                <MessagePanelButton
                  commentsPreview={serviceOrder?.comments_preview}
                  handleRightDrawerOpenerClick={updateRightDrawer()}
                  source="service_results"
                />
              </Stack>
              <RightDrawer {...rightMenu}>
                <MessagePanel
                  projectId={project?.id}
                  requestId={serviceOrder?.draw_request?.id}
                  serviceOrderId={serviceOrder?.id}
                  source="service_results__comments"
                  tab={MessagePanelTabsEnum.SERVICES}
                  showTabs={false}
                />
              </RightDrawer>
            </Stack>
            <Box
              sx={{
                backgroundColor: colors.background.gray,
                flex: 1,
                p: 3,
              }}
            >
              <ServiceEnterResults controller={controller} />
            </Box>
            <Stack spacing={2} direction="row" padding={3} justifyContent="flex-end">
              <ServiceCancelButton serviceOrder={serviceOrder} source="service_results" />
              {shouldShowDeleteButton && (
                <Button
                  color="error"
                  disabled={isCurrentProjectArchived}
                  onClick={deleteServiceOrderModal.askConfirm}
                  data-cy="service_results__delete__button"
                >
                  Delete
                </Button>
              )}
              {isEditable && isOrderedService(serviceOrder?.status) && (
                <ButtonWithTooltip
                  loading={Boolean(isMutating)}
                  onClick={confirmCallBack}
                  disabled={!serviceCompletedAt.isValid}
                  tooltipText="Completion date is required"
                  dataTestName="service_results__confirm__button"
                >
                  Confirm
                </ButtonWithTooltip>
              )}
            </Stack>
          </Stack>
          {isApprovalPopupOpen && (
            <SuccessModal
              text="Service order has been completed."
              open
              onClose={() => {
                setIsApprovalPopupOpen(false);
                goBack();
              }}
            />
          )}

          <ConfirmationModal
            open={deleteServiceOrderModal.isConfirmModalOpened}
            title="Delete service"
            text="Are you sure you want to delete this service?"
            onClose={deleteServiceOrderModal.closeConfirmModal}
            confirmCallback={() =>
              deleteServiceOrderModal.confirmCallback({
                action: async () => {
                  await handleDeleteServiceOrder();
                },
              })
            }
            type={PopupTypeEnum.CONFIRMATION}
            cancelButtonLabel="Close"
          />
        </>
      );
    }
    default:
      return null;
  }
};

const ServiceEnterResults: FC<{ controller: ControllerInterface }> = ({ controller }) => {
  const {
    serviceOrder,
    updateQueries,
    imageContainer,
    imagePicker,
    openFile,
    activeDocumentId,
    serviceCompletedAt,
    isEditable,
    statusChipProps,
    isUpdatedStatusModalOpen,
    setIsUpdatedStatusModalOpen,
    isStatusEditable,
    handleUpdateStatus,
    serviceTypeDisplayName,
  } = controller;

  const containerRef = useRef();

  return (
    <WrapperWithFixedHeight>
      <Box
        sx={{
          overflow: 'scroll',
          maxWidth: { lg: serviceOrder?.result_documents?.length ? '50%' : '80%', xl: '50%' },
          flex: 1,
        }}
        ref={containerRef}
      >
        <Stack spacing={2}>
          <Stack spacing={1} direction="row" justifyContent="space-between">
            <StyledBox sx={{ flex: 1 }}>
              <Stack spacing={1}>
                <LabelAndValue
                  label="Service type"
                  text={serviceTypeDisplayName}
                  textStyle={{ textTransform: 'capitalize' }}
                />
                <LabelAndValue
                  label="Service provider"
                  text={serviceOrder.service_agency.display_name}
                />

                <LabelAndValue
                  label="Service status"
                  icon={
                    isStatusEditable && (
                      <IconButton
                        onClick={() => setIsUpdatedStatusModalOpen(true)}
                        sx={{ p: 0, pl: 1 }}
                        data-cy="service_results__status__edit__icon"
                      >
                        <EditIcon size={20} />
                      </IconButton>
                    )
                  }
                >
                  <StatusChip {...statusChipProps} />
                </LabelAndValue>
                <LabelAndValue label="Service #" text={serviceOrder.service_number} />
                {serviceOrder.cancelled_at && (
                  <LabelAndValue
                    label="Date canceled"
                    text={dateFormatter({ date: serviceOrder.cancelled_at })}
                  />
                )}
                {serviceOrder.cancelled_by?.full_name && (
                  <LabelAndValue label="Canceled by" text={serviceOrder.cancelled_by?.full_name} />
                )}
                <UpdateServiceOrderStatus
                  open={isUpdatedStatusModalOpen}
                  handleOpen={setIsUpdatedStatusModalOpen}
                  initialValue={serviceOrder.status}
                  handleUpdateStatus={handleUpdateStatus}
                  source="service_results__status_edit_modal"
                />
              </Stack>
            </StyledBox>
            <StyledBox sx={{ flex: 1 }}>
              <Stack spacing={1}>
                <LabelAndValue
                  label="Date ordered"
                  text={dateFormatter({ date: serviceOrder.ordered_at })}
                />
                {serviceOrder.ordered_by?.full_name && (
                  <LabelAndValue label="Ordered by" text={serviceOrder.ordered_by?.full_name} />
                )}
                {serviceOrder.completed_at && (
                  <LabelAndValue
                    label="Date completed"
                    text={dateFormatter({ date: serviceOrder.completed_at })}
                  />
                )}

                <LinkRequestToServicePicker serviceOrder={serviceOrder} isEditable={isEditable} />
              </Stack>
            </StyledBox>
          </Stack>

          {isEditable && isOrderedService(serviceOrder?.status) && (
            <StyledBox>
              <Stack spacing={2}>
                <Typography variant="h3">Completion info</Typography>
                <Stack spacing={1} sx={{ width: '50%', pt: 1 }}>
                  <CustomDatePickerInput
                    label="Date completed"
                    field={serviceCompletedAt}
                    maxDate={new Date()}
                    inputProps={{
                      'data-cy': 'service_results__date_picker__input',
                    }}
                    required
                  />
                </Stack>
              </Stack>
            </StyledBox>
          )}

          {serviceOrder?.comment && (
            <StyledBox>
              <Stack spacing={2}>
                <Typography variant="h3">Special instructions</Typography>
                <Typography dangerouslySetInnerHTML={{ __html: serviceOrder?.comment }} />
              </Stack>
            </StyledBox>
          )}
          <StyledBox>
            <ServiceRelatedDocuments
              drawRequestId={serviceOrder?.draw_request?.id}
              serviceOrderId={serviceOrder?.id}
              isServiceReports
              uploadRefetchCallback={updateQueries}
              source="service_results__related_documents"
              onDocumentClick={(document) => {
                imagePicker.close();
                imagePicker.open([document]);
              }}
              activeDocumentId={activeDocumentId}
              sx={{ width: '100%', p: 0 }}
              isEditable={isEditable}
            />
          </StyledBox>
          <StyledBox>
            <ServiceRelatedDocuments
              drawRequestId={serviceOrder?.draw_request?.id}
              serviceOrderId={serviceOrder?.id}
              uploadRefetchCallback={updateQueries}
              source="service_results__result_documents"
              onDocumentClick={(document) => {
                imagePicker.close();
                imagePicker.open([document]);
              }}
              activeDocumentId={activeDocumentId}
              sx={{ width: '100%', p: 0 }}
              isEditable={isEditable}
            />
          </StyledBox>
        </Stack>
      </Box>
      {Boolean(serviceOrder?.result_documents?.length) && (
        <ServiceReport
          openFile={openFile}
          imagePicker={imagePicker}
          imageContainer={imageContainer}
        />
      )}
    </WrapperWithFixedHeight>
  );
};

const ServiceReport = ({ openFile, imagePicker, imageContainer }) => {
  return (
    <>
      <Box sx={{ position: 'stick', zIndex: 99 }} flex={1}>
        <CollapsedCard expandOnMount onOpen={openFile} fullHeight title="Document preview">
          {imagePicker.pdf && (
            <Stack sx={{ height: '67vh' }}>
              <MemoizedPDF file={imagePicker.pdf[0]} />
            </Stack>
          )}
          <Box sx={{ height: imagePicker.gallery && '67vh' }} ref={imageContainer} />
        </CollapsedCard>
      </Box>
      {imagePicker.gallery && (
        <MemoizedGallery container={imageContainer.current} files={imagePicker.gallery} />
      )}
    </>
  );
};

const PDFViewer = ({ file }) => {
  return <PDFViewerNew pdfFile={file} withoutPortal />;
};

const ImageViewer = ({ container, files }) => {
  return <Gallery container={container} startIndex={0} files={files} />;
};

const MemoizedPDF = React.memo(
  PDFViewer,
  (prevProps, nextProps) => prevProps?.file?.id === nextProps?.file?.id,
);

const MemoizedGallery = React.memo(
  ImageViewer,
  (prevProps, nextProps) => prevProps?.files?.[0]?.id === nextProps?.files?.[0]?.id,
);
export default ServiceResults;

const WrapperWithFixedHeight = ({ children }) => {
  return (
    <Stack
      spacing={2}
      direction={{ lg: 'row', xs: 'column' }}
      sx={{
        maxHeight: { lg: 'calc(100vh - 272px)', xs: 'unset' }, // 64+72+88+24+24
        width: '100%',
        flex: 1,
        justifyContent: 'center',
        overflow: { lg: 'scroll', xs: 'unset' },
      }}
    >
      {children}
    </Stack>
  );
};
