import React, { FC, useContext, useRef } from 'react';
import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import { useIsMutating } from 'react-query';
import {
  ButtonWithTooltip,
  CollapsedCard,
  CustomDatePickerInput,
  Filter,
  Gallery,
  GeneralRowTable,
  InspectionRelatedDocuments,
  LabelAndValue,
  LinkRequestToServicePicker,
  LoadingSkeleton,
  MessagePanel,
  MessagePanelButton,
  MilestoneList,
  MilestoneListWithPatch,
  PDFViewerNew,
  ProjectNameLabel,
  RightDrawer,
  ServiceMessage,
  StatusChip,
  StyledBox,
  SuccessModal,
} from '@components';
import { HookState, MessagePanelTabsEnum, TableKeyEnum } from '@interfaces';
import { ControllerInterface, useInspectionEnterResult } from './controller';
import { dateFormatter, isAutomatedInspection } from '@utils';
import { ArrowBackIcon } from '@svgAsComponents';
import { colors } from '@theme';
import { SettingsContext, useLaunchDarklyFlags } from '@context';
import { ProductionInspectionTable, ProductionInspectionTableV2 } from '../../sections';

const InspectionResults: FC = () => {
  const controller = useInspectionEnterResult();
  const {
    project,
    inspection,
    updateRightDrawer,
    rightMenu,
    isApprovalPopupOpen,
    setIsApprovalPopupOpen,
    goBack,
  } = controller;
  const isMutating = useIsMutating();

  switch (controller.state) {
    case HookState.FETCHING: {
      return (
        <StyledBox>
          <LoadingSkeleton type="overviewBlock" />
        </StyledBox>
      );
    }
    case HookState.ERROR: {
      return (
        <StyledBox>
          <ServiceMessage text="inspection" />
        </StyledBox>
      );
    }

    case HookState.SUCCESS: {
      return (
        <>
          <Stack direction="column" sx={{ height: '100%' }}>
            <Stack sx={{ p: 2 }} direction="row" alignItems="center" justifyContent="space-between">
              <Stack direction="row" alignItems="center">
                <IconButton onClick={controller.goBack} data-cy="inspection_results__back__icon">
                  <ArrowBackIcon />
                </IconButton>
                <Typography sx={{ ml: 2 }} variant="h2">
                  {controller.isEditable ? 'Enter results' : 'Inspection results'}
                </Typography>
              </Stack>

              <Stack direction="row" spacing={2} justifyContent="flex-end">
                <ProjectNameLabel project={project} />
                <MessagePanelButton
                  commentsPreview={inspection?.comments_preview}
                  handleRightDrawerOpenerClick={updateRightDrawer({})}
                  source="inspection_results"
                />
              </Stack>
              <RightDrawer {...rightMenu}>
                <MessagePanel
                  projectId={project?.id}
                  requestId={inspection?.draw_request?.id}
                  inspectionId={inspection?.id}
                  source={'inspection_results__comments'}
                  tab={MessagePanelTabsEnum.SERVICES}
                  showTabs={false}
                />
              </RightDrawer>
            </Stack>
            <Box
              sx={{
                backgroundColor: colors.background.gray,
                flex: 1,
                p: 3,
              }}
            >
              {controller.isEditable ? (
                <InspectionEnterResults controller={controller} />
              ) : (
                <InspectionInfo controller={controller} />
              )}
            </Box>
            <Stack spacing={2} direction="row" padding={3} justifyContent="flex-end">
              <Button
                onClick={controller.goBack}
                variant="new"
                color="secondary"
                data-cy="inspection_results__back__button"
              >
                Back
              </Button>
              {controller.isEditable && (
                <ButtonWithTooltip
                  loading={Boolean(isMutating)}
                  onClick={controller.confirmCallBack}
                  disabled={!controller.inspectionScheduledAt.isValid}
                  tooltipText="Inspection date is required"
                  dataTestName="inspection_results__confirm__button"
                >
                  Confirm
                </ButtonWithTooltip>
              )}
            </Stack>
          </Stack>
          {isApprovalPopupOpen && (
            <SuccessModal
              text="Inspection has been completed."
              open
              onClose={() => {
                setIsApprovalPopupOpen(false);
                goBack();
              }}
            />
          )}
        </>
      );
    }
    default:
      return null;
  }
};

const InspectionEnterResults: FC<{ controller: ControllerInterface }> = ({ controller }) => {
  const { isPHBProject } = useContext(SettingsContext);
  const {
    inspectionScheduledAt,
    inspectionCompletedAt,
    project,
    inspection,
    inspectionRefetch,
    imageContainer,
    imagePicker,
    openFile,
    initColumns,
    milestones,
    inspectionPhotos,
    rightMenu,
    rightDrawerParams,
    updateRightDrawer,
    totals,
    filterValue,
    handleFiltersChange,
    filterOptions,
    isMilestoneMutatingOrFetching,
    isInspectionLinkedToDR,
    activeDocumentId,
    statusChipProps,
  } = controller;
  const flags = useLaunchDarklyFlags();

  const containerRef = useRef();
  return (
    <WrapperWithFixedHeight>
      <Box
        sx={{
          overflow: 'scroll',
          maxWidth: { lg: inspection?.reports?.length ? '50%' : '80%', xl: '50%' },
          flex: 1,
        }}
        ref={containerRef}
      >
        <Stack spacing={2}>
          {!flags?.['ENG_5946_services'] && (
            <CollapsedCard isExpanded title="Inspection date">
              <Stack px={2}>
                <CustomDatePickerInput
                  label="Inspection date"
                  field={inspectionScheduledAt}
                  maxDate={new Date()}
                  inputProps={{
                    'data-cy': 'inspection_results__date_picker__input',
                  }}
                  required
                />
              </Stack>
            </CollapsedCard>
          )}
          {!flags?.['ENG_5946_services'] && (
            <CollapsedCard isExpanded title="General">
              <Stack spacing={1} px={2}>
                <LinkRequestToServicePicker serviceOrder={inspection} />
                <LabelAndValue
                  label="Inspection"
                  text={dateFormatter({ date: inspection?.scheduled_at })}
                />
                <LabelAndValue label="Address" text={project?.address?.address_1 || '-'} />
                <LabelAndValue
                  label="Agency"
                  text={inspection?.inspection_agency?.display_name || '-'}
                />
                {isAutomatedInspection(inspection?.inspection_agency?.service) && (
                  <LabelAndValue label="External Id" text={inspection?.provider_order_id || '-'} />
                )}
              </Stack>
            </CollapsedCard>
          )}

          {flags?.['ENG_5946_services'] && (
            <Stack spacing={1} direction="row" justifyContent="space-between">
              <StyledBox sx={{ flex: 1 }}>
                <Stack spacing={1}>
                  <LabelAndValue label="Service type" text="Inspection" />
                  <LabelAndValue
                    label="Service provider"
                    text={inspection?.inspection_agency?.display_name || '-'}
                  />

                  <LabelAndValue label="Service status">
                    <StatusChip {...statusChipProps} />
                  </LabelAndValue>
                  <LabelAndValue label="Service #" text={inspection?.service_number} />
                </Stack>
              </StyledBox>
              <StyledBox sx={{ flex: 1 }}>
                <Stack spacing={1}>
                  <LabelAndValue
                    label="Date ordered"
                    text={dateFormatter({ date: inspection.ordered_at })}
                  />
                  {inspection.completed_at && (
                    <LabelAndValue
                      label="Date completed"
                      text={dateFormatter({ date: inspection.completed_at })}
                    />
                  )}
                  <LinkRequestToServicePicker serviceOrder={inspection} />
                </Stack>
              </StyledBox>
            </Stack>
          )}

          {flags?.['ENG_5946_services'] && (
            <StyledBox>
              <Stack spacing={2}>
                <Typography variant="h3">Completion info</Typography>
                <Stack spacing={1} sx={{ width: '50%', pt: 1 }}>
                  <CustomDatePickerInput
                    label="Date completed"
                    field={inspectionCompletedAt}
                    maxDate={new Date()}
                    inputProps={{
                      'data-cy': 'inspection_results__date_picker__input',
                    }}
                    required
                  />
                </Stack>
              </Stack>
            </StyledBox>
          )}

          <CollapsedCard
            blockExpand
            header={
              <InspectionRelatedDocuments
                drawRequestId={inspection?.draw_request?.id}
                inspectionId={inspection?.id}
                isInspectionReports
                uploadRefetchCallback={inspectionRefetch}
                source="inspection_results"
                onDocumentClick={(document) => {
                  imagePicker.close();
                  imagePicker.open([document]);
                }}
                activeDocumentId={activeDocumentId}
                sx={{ width: '100%' }}
              />
            }
          />
          <CollapsedCard
            isExpanded
            header={
              <Stack flex={1} spacing={1} direction="row" alignItems="end">
                <Typography variant="h3">Inspection allowance </Typography>
                <Typography variant="label">(Optional)</Typography>
              </Stack>
            }
          >
            <GeneralRowTable
              canAddPhotos
              photos={inspectionPhotos}
              refetch={inspectionRefetch}
              rightMenu={rightMenu}
              rightDrawerParams={rightDrawerParams}
              updateRightDrawer={updateRightDrawer}
              comments_preview={inspection.comments_preview}
            />
            {isPHBProject ? (
              <>
                {flags?.['ENG-8622-new-phb-view'] ? (
                  <ProductionInspectionTableV2
                    showRequestedAmount={isInspectionLinkedToDR}
                    tableKey={TableKeyEnum.INSPECTION_ENTER_RESULTS}
                    containerRef={containerRef}
                  />
                ) : (
                  <ProductionInspectionTable
                    showRequestedAmount={isInspectionLinkedToDR}
                    tableKey={TableKeyEnum.INSPECTION_ENTER_RESULTS}
                    containerRef={containerRef}
                  />
                )}
              </>
            ) : (
              <MilestoneListWithPatch
                lockedColumns
                withColumnIndication
                refetch={inspectionRefetch}
                tableKey={TableKeyEnum.INSPECTION_ENTER_RESULTS}
                requestId={inspection?.draw_request?.id}
                key={inspection?.id}
                initColumns={initColumns}
                milestones={milestones?.map((item) => ({
                  ...item,
                  canAddPhotos: true,
                }))}
                totals={totals}
                headerLeftPart={[
                  {
                    Component: (
                      <Filter
                        filterLabel="Show"
                        onChangeCallback={handleFiltersChange}
                        options={filterOptions}
                        filterValue={filterValue}
                        disabled={isMilestoneMutatingOrFetching}
                      />
                    ),
                  },
                ]}
                source="inspections__result__line_items_table"
                containerRef={containerRef}
              />
            )}
          </CollapsedCard>
        </Stack>
      </Box>
      {Boolean(inspection?.reports?.length) && (
        <InspectionReport
          openFile={openFile}
          imagePicker={imagePicker}
          imageContainer={imageContainer}
        />
      )}
    </WrapperWithFixedHeight>
  );
};

const InspectionInfo: FC<{ controller: ControllerInterface }> = ({ controller }) => {
  const { isPHBProject } = useContext(SettingsContext);
  const {
    project,
    inspection,
    imageContainer,
    imagePicker,
    openFile,
    initColumns,
    milestones,
    drawRequestNumber,
    inspectionPhotos,
    inspectionName,
    rightMenu,
    rightDrawerParams,
    updateRightDrawer,
    totals,
    filterValue,
    handleFiltersChange,
    filterOptions,
    isMilestoneMutatingOrFetching,
    isInspectionLinkedToDR,
    inspectionRefetch,
    activeDocumentId,
    statusChipProps,
  } = controller;

  const containerRef = useRef();
  const flags = useLaunchDarklyFlags();

  return (
    <WrapperWithFixedHeight>
      <Box
        sx={{
          overflow: 'scroll',
          maxWidth: { lg: inspection?.reports?.length ? '50%' : '80%', xl: '50%' },
          flex: 1,
        }}
        ref={containerRef}
      >
        <Stack spacing={2}>
          {!flags?.['ENG_5946_services'] && (
            <CollapsedCard isExpanded title="General">
              <Stack spacing={2} mt={3} px={2}>
                {drawRequestNumber && (
                  <LabelAndValue label="Draw request" text={`#${drawRequestNumber}`} />
                )}
                <LabelAndValue
                  label="Inspection"
                  text={dateFormatter({ date: inspection?.scheduled_at })}
                />
                <LabelAndValue label="Address" text={project?.address?.address_1 || '-'} />
                <LabelAndValue label="Agency" text={inspectionName || '-'} />
                {isAutomatedInspection(inspection?.inspection_agency?.service) && (
                  <LabelAndValue label="External Id" text={inspection?.provider_order_id || '-'} />
                )}
              </Stack>
            </CollapsedCard>
          )}
          {flags?.['ENG_5946_services'] && (
            <Stack spacing={1} direction="row" justifyContent="space-between">
              <StyledBox sx={{ flex: 1 }}>
                <Stack spacing={1}>
                  <LabelAndValue label="Service type" text="Inspection" />
                  <LabelAndValue
                    label="Service provider"
                    text={inspection?.inspection_agency?.display_name || '-'}
                  />
                  {isAutomatedInspection(inspection?.inspection_agency?.service) && (
                    <LabelAndValue
                      label="External Id"
                      text={inspection?.provider_order_id || '-'}
                    />
                  )}
                  <LabelAndValue label="Service status">
                    <StatusChip {...statusChipProps} />
                  </LabelAndValue>
                  <LabelAndValue label="Service #" text={inspection?.service_number} />
                </Stack>
              </StyledBox>
              <StyledBox sx={{ flex: 1 }}>
                <Stack spacing={1}>
                  <LabelAndValue
                    label="Date ordered"
                    text={dateFormatter({ date: inspection.ordered_at })}
                  />
                  {inspection.completed_at && (
                    <LabelAndValue
                      label="Date completed"
                      text={dateFormatter({ date: inspection.completed_at })}
                    />
                  )}
                  <LinkRequestToServicePicker serviceOrder={inspection} />
                </Stack>
              </StyledBox>
            </Stack>
          )}
          <CollapsedCard
            blockExpand
            header={
              <InspectionRelatedDocuments
                drawRequestId={inspection?.draw_request?.id}
                inspectionId={inspection?.id}
                isInspectionReports
                uploadRefetchCallback={inspectionRefetch}
                source="inspection_results"
                onDocumentClick={(document) => {
                  imagePicker.close();
                  imagePicker.open([document]);
                }}
                isEditable={false}
                activeDocumentId={activeDocumentId}
                sx={{ width: '100%' }}
              />
            }
          />
          <CollapsedCard
            isExpanded
            header={
              <Stack flex={1} spacing={1} direction="row" alignItems="end">
                <Typography variant="h3">Inspection allowance </Typography>
              </Stack>
            }
          >
            <GeneralRowTable
              canAddPhotos={false}
              photos={inspectionPhotos}
              rightMenu={rightMenu}
              rightDrawerParams={rightDrawerParams}
              updateRightDrawer={updateRightDrawer}
              comments_preview={inspection.comments_preview}
            />
            {Boolean(milestones?.length) && (
              <>
                {isPHBProject ? (
                  <>
                    {flags?.['ENG-8622-new-phb-view'] ? (
                      <ProductionInspectionTableV2
                        showRequestedAmount={isInspectionLinkedToDR}
                        tableKey={TableKeyEnum.INSPECTION_RESULTS}
                        containerRef={containerRef}
                      />
                    ) : (
                      <ProductionInspectionTable
                        showRequestedAmount={isInspectionLinkedToDR}
                        tableKey={TableKeyEnum.INSPECTION_RESULTS}
                        containerRef={containerRef}
                      />
                    )}
                  </>
                ) : (
                  <MilestoneList
                    lockedColumns
                    withoutStickHeader={true}
                    withColumnIndication
                    tableKey={TableKeyEnum.INSPECTION_RESULTS}
                    key={inspection?.id}
                    initColumns={initColumns}
                    milestones={milestones}
                    totals={totals}
                    headerLeftPart={[
                      {
                        Component: (
                          <Filter
                            filterLabel="Show"
                            onChangeCallback={handleFiltersChange}
                            options={filterOptions}
                            filterValue={filterValue}
                            disabled={isMilestoneMutatingOrFetching}
                          />
                        ),
                      },
                    ]}
                    source="inspections__result__line_items_table"
                    containerRef={containerRef}
                  />
                )}
              </>
            )}
          </CollapsedCard>
        </Stack>
      </Box>
      {Boolean(inspection?.reports?.length) && (
        <InspectionReport
          openFile={openFile}
          imagePicker={imagePicker}
          imageContainer={imageContainer}
        />
      )}
    </WrapperWithFixedHeight>
  );
};

const InspectionReport = ({ openFile, imagePicker, imageContainer }) => {
  return (
    <>
      <Box sx={{ position: 'stick', zIndex: 99 }} flex={1}>
        <CollapsedCard expandOnMount onOpen={openFile} fullHeight title="Inspection report">
          {imagePicker.pdf && (
            <Stack sx={{ height: '67vh' }}>
              <MemoizedPDF file={imagePicker.pdf[0]} />
            </Stack>
          )}
          <Box sx={{ height: imagePicker.gallery && '67vh' }} ref={imageContainer} />
        </CollapsedCard>
      </Box>
      {imagePicker.gallery && (
        <MemoizedGallery container={imageContainer.current} files={imagePicker.gallery} />
      )}
    </>
  );
};

const PDFViewer = ({ file }) => {
  return <PDFViewerNew pdfFile={file} withoutPortal />;
};

const ImageViewer = ({ container, files }) => {
  return <Gallery container={container} startIndex={0} files={files} />;
};

const MemoizedPDF = React.memo(
  PDFViewer,
  (prevProps, nextProps) => prevProps?.file?.id === nextProps?.file?.id,
);

const MemoizedGallery = React.memo(
  ImageViewer,
  (prevProps, nextProps) => prevProps?.files?.[0]?.id === nextProps?.files?.[0]?.id,
);
export default InspectionResults;

const WrapperWithFixedHeight = ({ children }) => {
  return (
    <Stack
      spacing={2}
      direction={{ lg: 'row', xs: 'column' }}
      sx={{
        maxHeight: { lg: 'calc(100vh - 272px)', xs: 'unset' }, // 64+72+88+24+24
        width: '100%',
        flex: 1,
        justifyContent: 'center',
        overflow: { lg: 'scroll', xs: 'unset' },
      }}
    >
      {children}
    </Stack>
  );
};
