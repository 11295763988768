import React, { FC } from 'react';
import { Box, Skeleton, Stack, Typography } from '@mui/material';
import { PhotoCard, PhotoCardWithPopper, TablePaginationNew } from '@components';
import { HookState, IDocument, IPhoto, IProofpoint } from '@interfaces';
import { colors } from '@theme';
import { usePhotoList } from './controller';
import { PhotoListProps } from '../../interface';
import { useLaunchDarklyFlags } from '@context';

const PhotoList: FC<PhotoListProps> = ({
  onPhotoClick,
  source,
  context,
  projectId,
  milestoneId,
  drawRequestId,
  inspectionId,
  headerText,
  setTotalDocCount,
}) => {
  const {
    state,
    photos,
    documentsCount,
    tablePagination: { page, rowsPerPage, rowsPerPageOptions, onPageChange, onRowsPerPageChange },
  } = usePhotoList({
    context,
    projectId,
    milestoneId,
    drawRequestId,
    inspectionId,
    setTotalDocCount,
  });

  if (state === HookState.FETCHING) return <LoadingSkeleton />;
  if (state === HookState.SUCCESS && !photos?.length) return null;

  return (
    <Stack>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h4">{headerText}</Typography>

        <TablePaginationNew
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={rowsPerPageOptions}
          itemsCount={documentsCount}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          source={source}
        />
      </Stack>

      <Stack direction="row" flexWrap="wrap" alignItems="flex-start">
        {photos?.map((photo, index) => (
          <MemoizedPhoto
            key={photo.id}
            photo={photo}
            onClick={() => onPhotoClick({ photosArray: photos, photo, index })}
            source={source}
            index={index}
          />
        ))}
      </Stack>
    </Stack>
  );
};

const Photo: FC<{
  photo: IProofpoint | IDocument;
  onClick: () => void;
  source: string;
  index: number;
}> = ({ photo, onClick, source }) => {
  const flags = useLaunchDarklyFlags();

  return (
    <Box
      sx={{ width: '125px', mr: 1 }}
      key={photo.id}
      onClick={onClick}
      data-cy={`${source}__image`}
    >
      {flags?.['ENG_8957_photo_details_popup_in_right_panel'] ? (
        <PhotoCardWithPopper
          photo={
            {
              ...photo,
              milestones:
                photo['line_item_name']?.map((milestone) => ({
                  name: milestone,
                })) || [],
            } as IPhoto
          }
          size="medium"
          dataTestName={`${source}__image__button`}
        />
      ) : (
        <PhotoCard photo={photo} backgroundColor={colors.neutral.white} />
      )}
    </Box>
  );
};

const MemoizedPhoto = React.memo(
  Photo,
  (prevProps, nextProps) => prevProps?.photo?.id === nextProps?.photo?.id,
);

const LoadingSkeleton = () => (
  <Stack
    alignItems="flex-start"
    justifyContent="center"
    direction="row"
    spacing={1}
    sx={{ width: '100%' }}
  >
    <Skeleton height={140} width={125} />
    <Skeleton height={140} width={125} />
    <Skeleton height={140} width={125} />
    <Skeleton height={140} width={125} />
  </Stack>
);

export default PhotoList;
