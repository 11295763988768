import React, { FC, memo, useCallback, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { CommonRowType, MilestoneImages, MilestoneListColumnType } from './common';
import { FilesUploader, UploaderWithForm } from '@components';
import { TableContext } from '../controller';
import { useFilesUploader } from '@hooks';
import { ColumnV2Width, DocumentContentTypeEnum, TransloaditTemplateEnum } from '@interfaces';
import { getFilteredByRepresentationPhotos, getLinkedObjects } from '@utils';
import { useLaunchDarklyFlags } from '@context';

const InspectionPhotos: FC<{ row: CommonRowType }> = ({ row }) => {
  const flags = useLaunchDarklyFlags();
  const {
    isFilesUploaderOpened,
    transloaditSignature,
    uploadMedia,
    filesUploaderClose,
    restrictions,
  } = useFilesUploader();
  const { inspectionId, projectId } = useParams();
  // if row has an id -> it is milestone id
  const isMilestone = Boolean(row.id);
  const { source } = useContext(TableContext);

  const images = getFilteredByRepresentationPhotos(row.proofpoints);
  const uploadClick = useCallback(
    () =>
      uploadMedia({
        fields: {
          inspection_id: inspectionId,
          project_id: projectId,
          object_id: row.id || inspectionId,
          content_type:
            isMilestone && !flags?.['eng_8699_consistent_document_uploader']
              ? DocumentContentTypeEnum.MILESTONE
              : DocumentContentTypeEnum.INSPECTION,
        },
        templateType: TransloaditTemplateEnum.PROOFPOINTS,
      }),
    [inspectionId, projectId, row.id, isMilestone, flags],
  );

  const uploader = () => {
    if (flags?.['eng_8699_consistent_document_uploader']) {
      return (
        <UploaderWithForm
          isOpen={isFilesUploaderOpened}
          documentTypes={[]}
          closeUploader={filesUploaderClose}
          transloaditSignature={transloaditSignature}
          restrictions={restrictions}
          source={`${source}__body__inspection_photos`}
          projectId={projectId}
          inspectionId={inspectionId}
          milestoneId={row?.id}
        />
      );
    }

    return (
      <FilesUploader
        open={isFilesUploaderOpened}
        onClose={filesUploaderClose}
        transloaditSignature={transloaditSignature}
        metaDataDefault={{
          ...(isMilestone && { milestone_id: row.id }),
          linked_objects: getLinkedObjects({
            projectId,
            inspectionId,
            milestoneId: row?.id,
          }),
          content_type: isMilestone
            ? DocumentContentTypeEnum.MILESTONE
            : DocumentContentTypeEnum.INSPECTION,
        }}
        restrictions={restrictions}
        source={`${source}__body__inspection_photos`}
      />
    );
  };

  return (
    <MilestoneImages
      images={images}
      canAddPhotos={row.canAddPhotos}
      disabled={row.disabled}
      uploadClick={uploadClick}
      isFilesUploaderOpened={isFilesUploaderOpened}
      transloaditSignature={transloaditSignature}
      uploader={uploader}
      generalRowDataSource={row.generalRowDataSource}
    />
  );
};

const Memoized = memo(InspectionPhotos);

const inspectionPhotos: MilestoneListColumnType = {
  name: 'proofpoints',
  columnText: 'Inspection photos',
  noMargin: true,
  noBorder: true,
  renderCell: ({ row }) => <Memoized row={row} />,
  minWidth: () => ColumnV2Width.WIDE_ICON,
  maxWidth: () => ColumnV2Width.WIDE_ICON,
};

export default inspectionPhotos;
