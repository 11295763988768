import React from 'react';
import { ComponentProps } from './interface';
import { colors } from '@theme';

const Icon = ({ size = 24, color = colors.icons.green }: ComponentProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12ZM8 9.33333C8 8.59695 8.59696 8 9.33336 8C10.0697 8 10.6667 8.59695 10.6667 9.33333C10.6667 10.0697 10.0697 10.6667 9.33336 10.6667C8.59696 10.6667 8 10.0697 8 9.33333ZM10.1415 15.4748C9.69519 15.9211 8.97156 15.9211 8.52524 15.4748C8.07892 15.0285 8.07892 14.3049 8.52524 13.8586L13.8587 8.52522C14.305 8.0789 15.0286 8.0789 15.4749 8.52522C15.9213 8.97153 15.9213 9.69515 15.4749 10.1415L10.1415 15.4748ZM13.3333 14.6667C13.3333 13.9303 13.9303 13.3333 14.6666 13.3333C15.403 13.3333 16 13.9303 16 14.6667C16 15.403 15.403 16 14.6666 16C13.9303 16 13.3333 15.403 13.3333 14.6667Z"
      fill={color}
    />
  </svg>
);

export default Icon;
