import { useCallback, useContext, useMemo, useState } from 'react';
import { useMutation, useQueries, useQuery, useQueryClient } from 'react-query';

import find from 'lodash/find';
import map from 'lodash/map';
import {
  autofillCanBeShown,
  canDeleteRequest,
  checkIsCreator,
  checkIsCustomerSuccess,
  checkIsOwner,
  checkIsReallocateComplete,
  checkIsReallocateCompleteByLender,
  getHookState,
  getItemLocalHighlight,
  getRequestRetainageRate,
  getTeamRole,
  getTooltipText,
  isCreatedProject,
  isDrawRequest,
  isReallocationEnabled,
  isRequestDraftOrInReview,
  isRequestHistorical,
  isRequestInReview,
  isRestricted,
  parsePathErrorDual,
} from '@utils';
import { AuthContext, PermissionsContext, SettingsContext, useLaunchDarklyFlags } from '@context';
import {
  BulkDrawRequestListItemUpdateParam,
  ErrorDual,
  ILineItemModal,
  IMilestone,
  IProject,
  LineItemModalTypeEnums,
  LineItemPostResponse,
  MutationKeyEnum,
  PermissionNamesEnums,
  QueryNamesEnums,
  RequestPayload,
  TableKeyEnum,
  EventType,
} from '@interfaces';
import {
  deleteDrawRequest,
  getDrawRequest,
  getDrawRequestMilestones,
  getProject,
  postDrawRequestReport,
  postItemToRequest,
  updateDrawRequestMilestones,
} from '@globalService';

import { excludeCommentsQueryFields, LineItemFilterValues } from '@constants';

import { useNavigate } from 'react-router-dom';
import {
  useImagePicker,
  useLineItemsFilter,
  useRequestTotals,
  useSafeSnackbar,
  useReviewRequestInvalidation,
  useContinueDraftButton,
  useRightMenu,
  useCommentsAndDocumentsPreview,
} from '@hooks';
import { ControllerInterface, UpdateQueriesParams } from './interface';

export const useDrawRequest = (projectId: string, drawRequestId: string): ControllerInterface => {
  const handleRequestReviewInvalidation = useReviewRequestInvalidation();

  const [showPrintPlate, setShowPrint] = useState<boolean>(false);
  const [confirmModalType, setConfirmModalType] = useState<string>('');
  const queryClient = useQueryClient();
  const { isCurrentProjectArchived, isPHBProject, isCurrentProjectActive } =
    useContext(SettingsContext);
  const { permissions } = useContext(PermissionsContext);
  const navigate = useNavigate();
  const {
    filterValue,
    handleFilterClick,
    defaultOptions,
    isMilestoneMutatingOrFetching,
    filterKey,
  } = useLineItemsFilter({
    defaultState: LineItemFilterValues.ALL.filterValue,
    tableKey: TableKeyEnum.REQUEST_LINE_ITEMS,
  });

  const { user } = useContext(AuthContext);
  const teamRole = getTeamRole(user);
  const { enqueueSnackbar } = useSafeSnackbar();

  const { updateCommentsPreviewInfo } = useCommentsAndDocumentsPreview({
    projectId,
    drawRequestId,
  });
  const { handleRightDrawerOpenerClick, ...rightMenu } = useRightMenu({
    onClose: updateCommentsPreviewInfo,
  });
  const updateRightDrawer = () => () => {
    handleRightDrawerOpenerClick({ title: 'Comments' });
  };

  const flags = useLaunchDarklyFlags();

  const optionsList = useMemo(() => defaultOptions, [defaultOptions]);

  const totalKey = useMemo(
    () => find(defaultOptions, { filterValue })?.totalKey || 'all',
    [filterValue],
  );
  const { refetchAndReplaceTotals } = useRequestTotals({
    projectId,
    drawRequestId,
    totalKey,
    filterKey,
  });

  const [lineItemModal, setLineItemModal] = useState<ILineItemModal>({
    open: false,
    type: LineItemModalTypeEnums.ADD,
    lineItem: null,
  });

  const project = useQuery<IProject, Error>(
    [QueryNamesEnums.GET_PROJECT, { projectId }],
    getProject.bind(this, projectId),
  );

  const [drawRequestData, milestonesList] = useQueries([
    {
      queryKey: [
        QueryNamesEnums.GET_DRAW_REQUEST,
        {
          projectId,
          drawRequestId,
          query: `{*,totals{${totalKey}}}`,
        },
      ],
      queryFn: getDrawRequest.bind(this, {
        projectId,
        drawRequestId,
        query: `{*,totals{${totalKey}}}`,
      }),
      enabled: Boolean(drawRequestId),
    },
    {
      queryKey: [
        QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES,
        { projectId, drawRequestId, filterKey },
      ],
      queryFn: getDrawRequestMilestones.bind(this, { projectId, drawRequestId, filterKey }),
      enabled: Boolean(drawRequestId && !isPHBProject),
    },
  ]);

  const isApproveReallocateComplete = useMemo(
    () => checkIsReallocateCompleteByLender(drawRequestData.data),
    [drawRequestData.data],
  );
  const isRequestReallocateComplete = useMemo(
    () => checkIsReallocateComplete(drawRequestData.data),
    [drawRequestData.data],
  );

  const retainageRate = useMemo(
    () => getRequestRetainageRate(drawRequestData?.data),
    [drawRequestData],
  );

  const activeToEdit = useMemo(
    () =>
      (drawRequestData.data?.waits_current_user_approval && !checkIsOwner(teamRole)) ||
      (checkIsCustomerSuccess(teamRole) && isRequestInReview(drawRequestData.data?.status)),
    [drawRequestData.data, teamRole],
  );

  const isReallocationAllowed = useMemo(
    () => isReallocationEnabled(drawRequestData.data, project.data),
    [drawRequestData.data, project.data],
  );

  const initColumns = useMemo(() => {
    if (!drawRequestData?.data?.type) return [];
    return [
      ...(flags?.[`ENG_7895_table_v3__${TableKeyEnum.SUBMISSION}`] ? ['externalId'] : []),
      'nameV2',
      'costType',
      'originalConstructionBudget',
      'prefundingCost',
      'originalEstimate',
      ...(flags?.[`ENG_7895_table_v3__${TableKeyEnum.DRAW_REQUEST}`]
        ? ['revisedConstructionBudgetInReview']
        : ['revisedConstructionBudget']),
      'previousChanges',
      'previousChangesRate',
      ...(flags?.[`ENG_7895_table_v3__${TableKeyEnum.DRAW_REQUEST}`]
        ? ['revisedEstimateInReview']
        : ['revisedEstimate']),

      ...(isReallocationAllowed
        ? [
            'requestedAdjustments',
            'requestedAdjustmentsRate',
            'approvedAdjustments',
            'adjustmentsRate',
            'requestedRevisedEstimate',
          ]
        : []),
      ...(isDrawRequest(drawRequestData?.data)
        ? ['requestedAmount', 'requestedAmountRelative']
        : []),
      'inspectorAllowance',
      'inspectorAllowanceRate',
      'inspectorAllowanceIncremental',
      'inspectorAllowanceRateIncremental',
      'approvedAmountCumulative',
      'lenderAllowanceRate',
      ...(isDrawRequest(drawRequestData?.data) ? ['approvedAmount', 'approvedAmountRelative'] : []),
      'previousApprovedAmountCumulative',
      'previousLenderAllowanceRate',
      ...(flags?.[`ENG_7895_table_v3__${TableKeyEnum.DRAW_REQUEST}`]
        ? ['balanceToFinishInReview']
        : ['balanceToFinish']),
      'balanceToFinishRate',
      ...(retainageRate
        ? [
            'retainageBalanceTodateApproved',
            'retainageApprovedHoldback',
            'retainageReleaseRequested',
            'retainageReleaseApproved',
          ]
        : []),
      'varianceToLenderAllowance',
      'varianceToLenderAllowanceRate',
      ...(isDrawRequest(drawRequestData?.data) ? ['approveCredit'] : []),
      'documentsPhotosUploaderMenu',
      ...(flags?.['ENG_8397_docs_photos_circle_indication']
        ? ['documentsPhotosGalleryMenuV2']
        : ['documentsPhotosGalleryMenu']),
      'comments',
    ];
  }, [drawRequestData?.data, flags, retainageRate, activeToEdit, isReallocationAllowed]);

  const updateQueries = async (params: UpdateQueriesParams) => {
    if (!params?.skipRefetchTotals)
      refetchAndReplaceTotals({ isMilestonesUpdate: params?.isMilestonesUpdate });
    try {
      Promise.all([
        ...(params?.withRequest
          ? [
              queryClient.invalidateQueries([
                QueryNamesEnums.GET_DRAW_REQUEST,
                { projectId, drawRequestId },
              ]),
              queryClient.invalidateQueries([
                QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES,
                { projectId, drawRequestId },
              ]),
              queryClient.invalidateQueries([
                QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES_COLUMNS,
                { projectId, requestId: drawRequestId },
              ]),
            ]
          : []),
        queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_PHOTOS, { projectId }]),
        queryClient.invalidateQueries([
          QueryNamesEnums.GET_DRAW_REQUEST_FOR_APPROVAL,
          { projectId },
        ]),
        queryClient.invalidateQueries({
          predicate: (query) =>
            (query.queryKey[0] === QueryNamesEnums.GET_DRAW_REQUEST &&
              (query.queryKey[1] as { projectId?: string })?.projectId) == projectId,
        }),
        queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_PROGRESS, { projectId }]),
        queryClient.invalidateQueries([
          QueryNamesEnums.GET_PROJECT_DRAW_REQUEST_LIST,
          { projectId },
        ]),
        queryClient.invalidateQueries([
          QueryNamesEnums.GET_PROJECT_MILESTONES,
          { projectId, query: excludeCommentsQueryFields },
        ]),
      ]);
    } catch (error) {
      enqueueSnackbar('Update Error', { variant: 'error' });
    }
  };

  const milestones = useMemo(() => {
    if (!drawRequestData.data || !milestonesList.data?.results) return [];

    return milestonesList.data.results?.map((item) => ({
      ...item,
      localIsUserCreator: checkIsCreator(drawRequestData.data, teamRole),
      localNew: item.milestone_is_new_for_current_draw,
      localHighlight: getItemLocalHighlight(item),
      canAddPhotos: isRequestDraftOrInReview(drawRequestData.data?.status),
      isRequestHistorical:
        isRequestHistorical(drawRequestData.data?.source) && isCreatedProject(project.data?.status),
      activeToEdit,
      disabled: {
        value: isCurrentProjectArchived,
        reason: getTooltipText({ isCurrentProjectArchived }),
      },
      // TODO: remove these fields after export to csv will be implemented at BE ENG-6131
      original_construction_budget: item.prefunding_cost + item.original_estimate,
      revised_construction_budget: isRequestInReview(drawRequestData.data?.status)
        ? item.prefunding_cost + item.revised_estimate - item.requested_adjustments
        : item.prefunding_cost + item.revised_estimate,
    }));
  }, [
    drawRequestData.data,
    filterValue,
    teamRole,
    project.data,
    activeToEdit,
    milestonesList.data,
    isCurrentProjectArchived,
  ]);

  // bulk milestones update on autofill
  const bulkMilestoneMutation = useMutation<
    Response,
    ErrorDual,
    BulkDrawRequestListItemUpdateParam
  >(updateDrawRequestMilestones, {
    mutationKey: MutationKeyEnum.MILESTONE_PATCH_BULK,
    onSuccess: () => {
      updateQueries({ withRequest: true, skipRefetchTotals: false });
    },
    onError: (error) => {
      enqueueSnackbar(parsePathErrorDual(error), { variant: 'error' });
    },
  });

  const handleAutofillLenderAllowance = (autofillValue) => {
    const json = {
      autofill_key: autofillValue,
    };
    bulkMilestoneMutation.mutate({
      projectId,
      drawRequestId,
      json,
    });
  };

  const totals = useMemo(
    () => ({
      ...drawRequestData.data?.totals?.[find(optionsList, { filterValue })?.totalKey],
      isFiltered: filterValue !== LineItemFilterValues.ALL.filterValue,
    }),
    [filterValue, drawRequestData.data],
  );

  const showAutofillButton = useMemo(
    () => autofillCanBeShown(drawRequestData.data, teamRole),
    [drawRequestData?.data, teamRole],
  );

  const canViewReport = useMemo(
    () =>
      isDrawRequest(drawRequestData.data) &&
      !isRestricted(PermissionNamesEnums.PROJECTS_REPORT_VIEW, permissions),
    [permissions, drawRequestData.data],
  );

  const addLineListItemMutation = useMutation<LineItemPostResponse, Error, RequestPayload>(
    postItemToRequest,
    {
      mutationKey: MutationKeyEnum.DRAW_REQUEST_ADD_ITEM,
      onSuccess: () => {
        updateQueries({ withRequest: true, skipRefetchTotals: false });
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  const addLineList = useCallback(() => {
    setLineItemModal({ open: true, type: LineItemModalTypeEnums.ADD, lineItem: null });
  }, []);

  const { openDraft, continueDraftButtonLabel } = useContinueDraftButton({
    projectId,
    drawRequestId,
    drawRequest: drawRequestData.data,
  });

  const showAddNewLine = useMemo(
    () =>
      isReallocationAllowed &&
      (drawRequestData.data?.waits_current_user_approval || checkIsCustomerSuccess(teamRole)) &&
      isRequestInReview(drawRequestData.data?.status) &&
      !checkIsOwner(teamRole) &&
      !isRestricted(PermissionNamesEnums.DRAWREQUESTS_LINE_ITEM_CREATE, permissions),
    [
      permissions,
      drawRequestData.data?.status,
      teamRole,
      drawRequestData.data?.waits_current_user_approval,
      isReallocationAllowed,
    ],
  );

  const deleteDrawRequestMutation = useMutation<Response, Error, RequestPayload>(
    deleteDrawRequest,
    {
      mutationKey: MutationKeyEnum.MILESTONE_DELETE,
      onSuccess: async (data, vars) => {
        enqueueSnackbar('Request deleted', { variant: 'success' });
        navigate(`/projects/${projectId}/overview`);
        if (flags?.['ENG_7910_updates_based_on_draw_events']) {
          handleRequestReviewInvalidation({
            projectId,
            drawRequestId: vars?.drawRequest,
            event_type: EventType.DRAW_REQUEST_DELETED,
          });
        } else {
          updateQueries({ withRequest: false, skipRefetchTotals: true });
        }
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
        setConfirmModalType('');
      },
    },
  );

  const deleteRequest = useCallback(
    () =>
      deleteDrawRequestMutation.mutateAsync({
        project: projectId,
        drawRequest: drawRequestId,
      }),
    [deleteDrawRequestMutation, projectId, drawRequestId],
  );

  const reportCallback = useCallback(async () => {
    try {
      await postDrawRequestReport({ project: projectId, drawRequest: drawRequestId });
      enqueueSnackbar('Report generation has started. It will be available in a few minutes.', {
        variant: 'info',
      });
    } catch (e) {
      enqueueSnackbar('Generate Error', { variant: 'error' });
    }
  }, [drawRequestId, projectId]);

  const { pdf, open, close } = useImagePicker();

  const openPdfReport = useCallback(() => {
    open([drawRequestData.data.report]);
  }, [open, drawRequestData.data]);

  const openEditMilestoneModal = useCallback(
    (lineItem: IMilestone) => {
      setLineItemModal({ open: true, type: LineItemModalTypeEnums.EDIT, lineItem });
    },
    [setLineItemModal],
  );

  return {
    state: getHookState(drawRequestData),
    updateQueries,
    totals,
    initColumns,
    drawRequest: drawRequestData.data,
    milestones,
    isInReview: isRequestInReview(drawRequestData.data?.status),
    handleAutofillLenderAllowance,
    handleFiltersChange: handleFilterClick,
    filterOptions: map(optionsList, 'filterValue'),
    showAutofillButton,
    isApproveReallocateComplete,
    isRequestReallocateComplete,
    showPrintPlate,
    setShowPrint,
    canViewReport,
    addLineList,
    openDraft,
    showAddNewLine,
    creatingNewLine: addLineListItemMutation.isLoading,
    canDeleteRequest: canDeleteRequest(drawRequestData.data),
    deleteRequest,
    pdf,
    close,
    openPdfReport,
    reportCallback,
    isDeleting: deleteDrawRequestMutation.isLoading,
    isAutofillLoading: bulkMilestoneMutation.isLoading,
    confirmModalType,
    setConfirmModalType,
    lineItemModal,
    setLineItemModal,
    openEditMilestoneModal,
    filterValue,
    isMilestoneMutatingOrFetching,
    isCurrentProjectActive,
    projectName: project.data?.name,
    openPdfViewer: (file) => open([file]),
    continueDraftButtonLabel,
    updateRightDrawer,
    rightMenu,
  };
};
