import React, { FC } from 'react';
import { Stack, Typography } from '@mui/material';
import { FundingSourceStatusEnum, FundingSourceTypeEnum, PaymentConfiguration } from '@interfaces';
import { StringFieldModel } from '@models';
import { colors } from '@theme';
import {
  currencyFormatter,
  isSequentialPaymentConfiguration,
  percentFormatter,
  updateFSValues,
} from '@utils';
import {
  EditFundingSourceModal,
  EditFundingSourceProportionModal,
  FundingSourceCard,
  LabelAndValue,
} from '@components';

interface FundingSourcesContainerProps {
  prefunding_cost: StringFieldModel;
  construction_holdback: StringFieldModel;
  constructionHoldbackFraction: StringFieldModel;
  borrower_equity: StringFieldModel;
  borrowerEquityFraction: StringFieldModel;
  configurationType: PaymentConfiguration;
  setConfigurationType: (value: PaymentConfiguration) => void;
  constructionBudget: StringFieldModel;
  source: string;
  isEditable?: boolean;
}

const enum PredefinedFSEnums {
  PREFUNDING_COSTS = 'prefunding_costs',
  CONSTRUCTION_HOLDBACK = 'construction_holdback',
  BORROWER_EQUITY = 'borrower_equity',
}

const FundingSourcesContainer: FC<FundingSourcesContainerProps> = ({
  prefunding_cost,
  construction_holdback,
  constructionHoldbackFraction,
  borrower_equity,
  borrowerEquityFraction,
  configurationType,
  setConfigurationType,
  constructionBudget,
  source,
  isEditable = true,
}) => {
  const sources = {
    prefunding_costs: {
      color: colors.text.link,
      backgroundColor: colors.background.lightest,
      type: PredefinedFSEnums.PREFUNDING_COSTS,
      sourceName: 'Prefunding costs',
      sourceType: FundingSourceTypeEnum.BORROWER_EQUITY,
      sourceStatus: FundingSourceStatusEnum.HISTORICAL,
      field: prefunding_cost,
      title: 'prefunding costs',
    },
    construction_holdback: {
      color: colors.status.information.medium,
      backgroundColor: colors.status.information.lighter,
      type: PredefinedFSEnums.CONSTRUCTION_HOLDBACK,
      sourceName: 'Construction holdback',
      sourceType: FundingSourceTypeEnum.LOAN,
      sourceStatus: FundingSourceStatusEnum.ACTIVE,
      field: construction_holdback,
      title: 'construction holdback',
      fractionValue: isSequentialPaymentConfiguration(configurationType)
        ? ''
        : percentFormatter({ value: +constructionHoldbackFraction.value }),
    },
    borrower_equity: {
      color: colors.status.orange.medium,
      backgroundColor: colors.status.orange.lighter,
      type: PredefinedFSEnums.BORROWER_EQUITY,
      sourceName: 'Borrower equity',
      sourceType: FundingSourceTypeEnum.BORROWER_EQUITY,
      sourceStatus: FundingSourceStatusEnum.ACTIVE,
      field: borrower_equity,
      fractionValue: isSequentialPaymentConfiguration(configurationType)
        ? ''
        : percentFormatter({ value: +borrowerEquityFraction.value }),
    },
  };

  const [activeFundingSource, setActiveFundingSource] = React.useState(null);
  const [openProportionModal, setOpenProportionModal] = React.useState(false);

  return (
    <>
      <Stack spacing={3}>
        <Stack>
          <Typography variant="h3">Funding sources</Typography>
          <Typography variant="body1" sx={{ mt: '8px', display: 'inline-block' }}>
            Enter amount for prefunding costs and organise your funding sources (ex. Construction
            holdback, Borrower equity, Additional borrower equity).
          </Typography>
        </Stack>
        <Stack direction="row" spacing={5}>
          <LabelAndValue
            label="Total loan commitment:"
            text={currencyFormatter(
              (+constructionBudget.value || 0) + (+prefunding_cost.value || 0),
            )}
            marginRightAfterLabel={false}
            textDataTestName={`${source}__funding_sources__total_loan_commitment__value`}
          />
          <LabelAndValue
            label="Construction budget:"
            text={currencyFormatter(+constructionBudget.value, '-')}
            marginRightAfterLabel={false}
            textDataTestName={`${source}__funding_sources__construction_budget__value`}
          />
        </Stack>
        <Stack direction="row">
          <Stack mr={1}>
            <FundingSourceCard
              fundingSource={sources.prefunding_costs}
              setActiveFundingSource={setActiveFundingSource}
              source={source}
              isEditable={isEditable}
            />
          </Stack>
          <Stack
            mr={
              Boolean(borrower_equity.floatValue) &&
              !isSequentialPaymentConfiguration(configurationType)
                ? 0
                : 1
            }
          >
            <FundingSourceCard
              fundingSource={sources.borrower_equity}
              setActiveFundingSource={setActiveFundingSource}
              source={source}
              isEditable={isEditable}
            />
          </Stack>
          <Stack>
            <FundingSourceCard
              fundingSource={sources.construction_holdback}
              setActiveFundingSource={setActiveFundingSource}
              setOpenProportionModal={setOpenProportionModal}
              isProportionAllowed
              source={source}
              isEditable={isEditable}
            />
          </Stack>
        </Stack>
      </Stack>

      <EditFundingSourceModal
        open={Boolean(activeFundingSource)}
        onClose={() => setActiveFundingSource(null)}
        handleSave={(value) => {
          if (activeFundingSource.type === PredefinedFSEnums.CONSTRUCTION_HOLDBACK) {
            updateFSValues({
              value,
              amountField: construction_holdback,
              otherAmountField: borrower_equity,
              fieldFraction: constructionHoldbackFraction,
              otherFieldFraction: borrowerEquityFraction,
              constructionBudget,
            });
            constructionBudget.setAsFloat(+value + borrower_equity.floatValue);
          } else if (activeFundingSource.type === PredefinedFSEnums.BORROWER_EQUITY) {
            updateFSValues({
              value,
              amountField: borrower_equity,
              otherAmountField: construction_holdback,
              fieldFraction: borrowerEquityFraction,
              otherFieldFraction: constructionHoldbackFraction,
              constructionBudget,
            });
            constructionBudget.setAsFloat(construction_holdback.floatValue + +value);
          } else {
            activeFundingSource.field.setAsFloat(+value);
          }
          setActiveFundingSource(null);
        }}
        source={source}
        {...activeFundingSource}
      />
      <EditFundingSourceProportionModal
        open={openProportionModal}
        onClose={() => setOpenProportionModal(false)}
        sourceName1="Construction holdback"
        sourceName2="Borrower equity"
        field1={construction_holdback}
        field2={borrower_equity}
        fraction1={constructionHoldbackFraction}
        fraction2={borrowerEquityFraction}
        setConfigurationType={setConfigurationType}
        configurationTtype={configurationType}
        constructionBudget={constructionBudget}
        source={source}
      />
    </>
  );
};

export default FundingSourcesContainer;
